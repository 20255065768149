import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Box, Typography, useTheme } from "@mui/material";

const MiniCalendar = ({ events }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [hoveredEvent, setHoveredEvent] = useState(null);

  const handleEventMouseEnter = (mouseEnterInfo) => {
    setHoveredEvent(mouseEnterInfo);
  };

  const handleEventMouseLeave = () => {
    setHoveredEvent(null);
  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <Box
        onClick={() =>
          navigate(
            eventInfo.event._def.extendedProps.type === "job"
              ? "/jobs/" + eventInfo.event.id
              : "/tasks/" + eventInfo.event.id
          )
        }
        sx={{
          display: "flex",
          width: "100%",
          cursor: "pointer",
          backgroundColor:
            eventInfo.event._def.extendedProps.type === "job"
              ? theme.palette.secondary[600]
              : theme.palette.neutral[600],
        }}
      >
        <Typography
          sx={{
            m: "0rem 0.4rem",
          }}
        >
          {eventInfo.event.start.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Typography>
        <Typography sx={{ overflow: "hidden" }}>
          {eventInfo.event.title}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <FullCalendar
        height={"100%"}
        headerToolbar={false}
        plugins={[dayGridPlugin]}
        weekends={true}
        initialView="dayGridWeek"
        events={events}
        eventContent={renderEventContent}
        themeSystem="standard"
        buttonText={{
          today: "Today",
          month: "Month",
          week: "Week",
          day: "Day",
          list: "List",
        }}
        eventMouseEnter={handleEventMouseEnter}
        eventMouseLeave={handleEventMouseLeave}
      />
      {hoveredEvent && (
        <Box
          sx={{
            position: "absolute",
            backgroundColor: theme.palette.background.paper,
            padding: "0.5rem",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            top: `${hoveredEvent.jsEvent.clientY}px`,
            left: `${hoveredEvent.jsEvent.clientX}px`,
            zIndex: 1000,
          }}
        >
          <Typography>
            {hoveredEvent.event._def.title} (
            {capitalizeFirstLetter(hoveredEvent.event._def.extendedProps.type)})
          </Typography>
          <Typography>
            Due date:{" "}
            {hoveredEvent.event._instance.range.start.toLocaleTimeString([], {
              day: "numeric",
              month: "short",
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "UTC",
            })}
          </Typography>
          <Typography>
            Assigned to:{" "}
            {hoveredEvent.event._def.extendedProps.assignedTo
              .map((assignee) => assignee.name)
              .join(", ")}
          </Typography>
          <Typography>
            {hoveredEvent.event._def.extendedProps.description}
          </Typography>
        </Box>
      )}
      <style type="text/css">{`
          .fc-button-primary {
            color: ${theme.palette.primary.main} !important;
            background-color: ${theme.palette.secondary.main} !important;
            border-color: ${theme.palette.secondary.main} !important;
          }
          .fc-button:disabled {
            opacity: 1 !important;
            background-color: ${theme.palette.secondary[100]} !important;
            border-color: ${theme.palette.secondary[100]} !important;
          }
          .fc-toolbar-title {
            color: ${theme.palette.secondary[200]} !important;
          }
      `}</style>
    </>
  );
};

export default MiniCalendar;
