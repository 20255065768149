import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetOrganisationQuery,
  usePutOrganisationMutation,
  useAdminLoginMutation,
} from "state/api";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@mui/material";
import Header from "components/Header";
import { useSnackbar } from "components/Snackbar";
import Spinner from "components/Spinner";
import { useAuth } from "components/auth";
import NotesPanel from "components/NotesPanel";

const OrganisationAdmin = () => {
  const auth = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [adminLogin] = useAdminLoginMutation();
  const { data, isLoading, refetch } = useGetOrganisationQuery(id);
  const [putOrganisationMutation] = usePutOrganisationMutation();
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    if (auth)
      if (auth.role !== "superadmin") {
        navigate("/dashboard");
      }
  }, [id, auth, navigate]);

  useEffect(() => {
    if (data) {
      setEditedData({ ...data });
    }
  }, [data]);

  const handleSaveChanges = async () => {
    try {
      await putOrganisationMutation({
        id: id,
        updatedData: editedData,
      }).then((res) => {
        showSnackbar("Organisation details updated successfully", "success");
      });

      setIsDataChanged(false);
    } catch (error) {
      showSnackbar("Error updating organisation details", "error");
    }
  };

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
    if (!isEditMode) {
      setIsDataChanged(true);
    }
  };

  const handleEditChange = (field, value) => {
    setIsDataChanged(true);
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleLogin = async () => {
    try {
      const result = await adminLogin({ organisationId: id });

      showSnackbar("Login successful", "success");
      navigate("/dashboard");
    } catch (err) {
      console.error("Login error:", err);
      showSnackbar(
        "Login failed: " + (err.data?.message || "Unknown error"),
        "error"
      );
    }
  };

  const renderDetailItem = (label, field, value) => {
    return (
      <Box>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ListItemText sx={{ width: "30%", my: "auto" }}>{label}</ListItemText>
          <ListItemText sx={{ width: "70%" }}>
            {isEditMode ? (
              <TextField
                fullWidth
                size="small"
                sx={{ width: "100%" }}
                value={value || ""}
                onChange={(e) => handleEditChange(field, e.target.value)}
              />
            ) : (
              value
            )}
          </ListItemText>
        </ListItem>
        <Divider />
      </Box>
    );
  };

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header
          title={data?.name ?? "Organisation"}
          subtitle="Organisation Profile"
        />
        <Box>
          <Button
            variant="outlined"
            sx={{
              marginRight: "1rem",
              color: theme.palette.secondary[300],
              borderColor: theme.palette.secondary[300],
            }}
            onClick={handleLogin}
          >
            LOGIN
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleSaveChanges}
            disabled={!isDataChanged}
          >
            Save Changes
          </Button>
        </Box>
      </Box>

      <Paper
        elevation={3}
        sx={{
          padding: "1rem",
          bgcolor: theme.palette.background.alt,
          marginTop: "1rem",
        }}
      >
        <Box
          display="flex"
          sx={{ padding: "1rem" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">Edit Organisation Details</Typography>

          <Button
            variant="outlined"
            sx={{
              color: theme.palette.secondary[300],
              borderColor: theme.palette.secondary[300],
            }}
            onClick={handleEditClick}
          >
            {isEditMode ? <CheckIcon /> : <EditIcon />}
          </Button>
        </Box>
        <Grid container spacing={2}>
          {/* Adjust the grid layout */}
          <Grid item xs={6}>
            {/* Organisation Details */}
            {renderDetailItem("Business Name", "name", editedData.name)}
            {renderDetailItem("Plan", "plan", editedData.plan)}
            {renderDetailItem("Status", "status", editedData.status)}
            {renderDetailItem("Address", "address", editedData.address)}
            {renderDetailItem(
              "External Extension",
              "externalExt",
              editedData.externalExt
            )}
          </Grid>
          <Grid item xs={6}>
            {/* Contact Details */}
            {renderDetailItem("Website", "website", editedData.website)}
            {renderDetailItem(
              "Contact Name",
              "contactName",
              editedData.contactName
            )}
            {renderDetailItem(
              "Contact Phone",
              "contactPhoneNumber",
              editedData.contactPhoneNumber
            )}
            {renderDetailItem(
              "Contact Email",
              "contactEmail",
              editedData.contactEmail
            )}
            {renderDetailItem(
              "Signup Password",
              "externalSignupPassword",
              editedData.externalSignupPassword
            )}
          </Grid>
        </Grid>
      </Paper>
      <Box mt="1rem">
        <NotesPanel
          data={data}
          editedData={editedData}
          handleEditChange={handleEditChange}
          changesMade={() => setIsDataChanged(true)}
        />
      </Box>
    </Box>
  );
};

export default OrganisationAdmin;
