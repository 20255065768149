import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetUserQuery,
  usePutUserMutation,
  useDeleteUserMutation,
} from "state/api";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Divider,
  Link,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material";
import Header from "components/Header";
import { useSnackbar } from "components/Snackbar";
import Spinner from "components/Spinner";
import { useAuth } from "components/auth";

const Admin = () => {
  const auth = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useGetUserQuery(id);
  const [putUserMutation] = usePutUserMutation();
  const [deleteUserMutation] = useDeleteUserMutation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditingQualifications, setIsEditingQualifications] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newQualification, setNewQualification] = useState("");
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    if (auth)
      if (auth.role !== "superadmin" && auth.user !== id) {
        navigate("/dashboard");
      }
  }, [id, auth, navigate]);

  useEffect(() => {
    if (data) {
      setEditedData({ ...data });
    }
  }, [data]);

  const handleSaveChanges = async () => {
    try {
      await putUserMutation({
        id: id,
        updatedData: editedData,
      }).then((res) => {
        showSnackbar("Admin details updated successfully", "success");
      });

      setIsDataChanged(false);
    } catch (error) {
      showSnackbar("Error updating admin details", "error");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteUserMutation(id).then((res) => {
        navigate("/admins");
      });
    } catch (error) {
      showSnackbar("error", "Error deleting admin");
    }
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
    setAnchorEl(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  // Handler for editing notes
  const handleEditNotes = () => {
    if (isEditingNotes) {
      setIsDataChanged(true);
    }
    setIsEditingNotes(!isEditingNotes);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    if (isEditMode) {
      setIsDataChanged(true);
    }
    setIsEditMode(!isEditMode);
  };

  const handleEditQualifications = () => {
    if (isEditingQualifications) {
      setIsDataChanged(true);
    }
    setIsEditingQualifications(!isEditingQualifications);
  };

  const handleEditChange = (field, value) => {
    if (!isDataChanged) {
      setIsDataChanged(true);
    }
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAddQualification = () => {
    if (newQualification.trim() !== "") {
      setEditedData((prevData) => ({
        ...prevData,
        qualifications: [
          ...(prevData.qualifications || []),
          newQualification.trim(),
        ],
      }));
      setNewQualification("");
    }
  };

  const handleRemoveQualification = (index) => {
    setEditedData((prevData) => {
      const newQualifications = [...prevData.qualifications];
      newQualifications.splice(index, 1);
      return { ...prevData, qualifications: newQualifications };
    });
  };

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this admin? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              closeDeleteDialog();
              handleDelete();
            }}
            color="warning"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title={data?.name} subtitle="Admin Profile" />
        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleMenuOpen}
            style={{ marginRight: "2rem" }}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => openDeleteDialog()}>Delete Admin</MenuItem>
          </Menu>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleSaveChanges}
            disabled={!isDataChanged}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: "10px", width: "100%" }}>
        <Grid item xs={6}>
          <Paper
            elevation={3}
            sx={{ padding: "1rem", bgcolor: theme.palette.background.alt }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Admin Details</Typography>
              <Button
                variant="outlined"
                sx={{
                  color: theme.palette.secondary[300],
                  borderColor: theme.palette.secondary[300],
                }}
                onClick={handleEditClick}
              >
                {isEditMode ? <CheckIcon /> : <EditIcon />}
              </Button>
            </Box>
            <List>
              <ListItem key="name">
                <ListItemText primary="Name" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      value={editedData.name || ""}
                      onChange={(e) => handleEditChange("name", e.target.value)}
                    />
                  ) : (
                    editedData.name ?? data.name
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="email">
                <ListItemText primary="Email" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      value={editedData.email || ""}
                      onChange={(e) =>
                        handleEditChange("email", e.target.value)
                      }
                    />
                  ) : (
                    editedData.email ?? data.email
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="address">
                <ListItemText primary="Address" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      value={editedData.address || ""}
                      onChange={(e) =>
                        handleEditChange("address", e.target.value)
                      }
                    />
                  ) : (
                    editedData.address ?? data.address
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="phoneNumber">
                <ListItemText primary="Phone Number" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      value={editedData.phoneNumber || ""}
                      onChange={(e) =>
                        handleEditChange("phoneNumber", e.target.value)
                      }
                    />
                  ) : (
                    editedData.phoneNumber ?? data.phoneNumber
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="createdAt">
                <ListItemText primary="Created At" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {data.createdAt
                    ? new Intl.DateTimeFormat("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZoneName: "short",
                      }).format(new Date(data.createdAt))
                    : "No date"}
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Notes</Typography>
              <Button
                variant="outlined"
                sx={{
                  color: theme.palette.secondary[300],
                  borderColor: theme.palette.secondary[300],
                }}
                onClick={handleEditNotes}
              >
                {isEditingNotes ? <CheckIcon /> : <EditIcon />}
              </Button>
            </Box>
            {isEditingNotes ? (
              <TextField
                multiline
                rows={5}
                fullWidth
                value={editedData.notes || ""}
                onChange={(e) => handleEditChange("notes", e.target.value)}
                variant="outlined"
              />
            ) : (
              <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                {editedData.notes ?? data.notes}
              </Typography>
            )}
          </Paper>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Qualifications</Typography>
              <Button
                variant="outlined"
                sx={{
                  color: theme.palette.secondary[300],
                  borderColor: theme.palette.secondary[300],
                }}
                onClick={handleEditQualifications}
              >
                {isEditingQualifications ? <CheckIcon /> : <EditIcon />}
              </Button>
            </Box>
            <List>
              {(editedData.qualifications || data.qualifications).map(
                (qualification, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <ListItemText
                        sx={{
                          width: "80%",
                        }}
                      >
                        <Link
                          href={qualification}
                          color={theme.palette.secondary[100]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {qualification}
                        </Link>
                      </ListItemText>
                      {isEditingQualifications && (
                        <IconButton
                          color="error"
                          aria-label="delete"
                          onClick={() => handleRemoveQualification(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                    {index !==
                      (editedData.qualifications || data.qualifications)
                        .length -
                        1 && <Divider />}
                  </React.Fragment>
                )
              )}
              {isEditingQualifications && (
                <ListItem>
                  <ListItemText sx={{ width: "20%" }}>
                    <TextField
                      fullWidth
                      placeholder="Add qualification"
                      value={newQualification}
                      onChange={(e) => setNewQualification(e.target.value)}
                    />
                  </ListItemText>
                  <IconButton
                    color="secondary"
                    aria-label="add"
                    onClick={handleAddQualification}
                  >
                    <AddIcon />
                  </IconButton>
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            elevation={3}
            sx={{ padding: "1rem", bgcolor: theme.palette.background.alt }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Assigned Jobs</Typography>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab label="Live Jobs" />
                <Tab label="Completed Jobs" />
              </Tabs>
            </Box>
            {/* Add content for each tab */}
            {tabValue === 0 && (
              <Box>
                <Divider />
                {data.jobs
                  .filter((job) => job.status === "open") // Filter jobs with status "open"
                  .map((job) => (
                    <Box>
                      <ListItem
                        key={job._id}
                        sx={{
                          display: "flex",
                          transition: "background-color 0.3s",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => navigate(`/jobs/${job._id}`)}
                      >
                        <ListItemText
                          primary={job.name}
                          secondary={job.description}
                          sx={{
                            flex: "4",
                          }}
                        />
                        <ListItemText
                          primary={
                            job.dueDate
                              ? `Due: ${new Intl.DateTimeFormat("en-US", {
                                  weekday: "long",
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }).format(new Date(job.dueDate))}`
                              : "No due date"
                          }
                          sx={{
                            flex: "1",
                          }}
                        />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
              </Box>
            )}
            {tabValue === 1 && (
              <Box>
                <Divider />
                {data.jobs
                  .filter((job) => job.status === "closed") // Filter jobs with status "closed"
                  .map((job) => (
                    <Box>
                      <ListItem
                        key={job._id}
                        sx={{
                          display: "flex",
                          transition: "background-color 0.3s",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => navigate(`/jobs/${job._id}`)}
                      >
                        <ListItemText
                          primary={job.name}
                          secondary={job.description}
                        />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Admin;
