import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { useGetUserQuery, useGetOrganisationQuery } from "state/api";
import { useAuth } from "components/auth";
import { useDispatch } from "react-redux";
import { setThemeModeFromUserData } from "state";
import Cookies from "js-cookie";
import OnlineStatusSnackbar from "components/OnlineStatusSnackbar";

const Layout = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const userId = auth.user;
  const { data } = useGetUserQuery(userId);
  const { data: orgData } = useGetOrganisationQuery(data?.organisation);

  useEffect(() => {
    if (data && data.settings?.theme) {
      Cookies.set("themeMode", data.settings.theme, { expires: 1 });
      dispatch(setThemeModeFromUserData(data.settings.theme));
    } // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (Cookies.get("themeMode")) {
      dispatch(setThemeModeFromUserData(Cookies.get("themeMode")));
    } // eslint-disable-next-line
  }, []);

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <OnlineStatusSnackbar />
      <Sidebar
        user={data || {}}
        organisation={orgData || {}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <Navbar
          user={data || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet user={data || {}} />
      </Box>
    </Box>
  );
};

export default Layout;
