import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  Box,
  Typography,
  useTheme,
  Paper,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useCreateTaskMutation } from "state/api";
import { capitalizeFirstLetter } from "components/usefulFunctions";
import GenerateTaskPopup from "./GenerateTaskPopup";
import { useSnackbar } from "components/Snackbar";

const TaskPanel = ({ data, editedData, refetch, save }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [createTaskMutation] = useCreateTaskMutation();
  const [tabValue, setTabValue] = useState(0);
  const [isGeneratingTasks, setIsGeneratingTasks] = useState(false);
  const [isOpenGenerateDialog, setIsOpenGenerateDialog] = useState(false);
  const [proposedTasks, setProposedTasks] = useState([]);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const handleDialogOpen = () => {
    save();
    setIsGeneratingTasks(true);
    if (
      editedData &&
      editedData.plan.length > 0 &&
      editedData.targetLanguages.length > 0 &&
      editedData.sourceLanguages.length > 0 &&
      !isGeneratingTasks
    ) {
      const planData = editedData.plan;
      const targetLanguages = editedData.targetLanguages;
      const sourceLanguages = editedData.sourceLanguages; // Now an array

      const generatedTasks = [];

      // Loop through each step in the plan
      for (const step of planData) {
        // Loop through each source language
        for (const sourceLanguage of sourceLanguages) {
          // Loop through each target language
          for (const targetLanguage of targetLanguages) {
            // Create a task object based on the step, sourceLanguage, and targetLanguage
            const task = {
              id: `${step.name}-${sourceLanguage._id}-${targetLanguage._id}`,
              name: `${capitalizeFirstLetter(step.name)} - ${
                sourceLanguage.name
              } to ${targetLanguage.name}`,
              jobId: editedData._id,
              startDate: step.startDate,
              dueDate: step.dueDate,
              type: step.name,
              sourceLanguage: sourceLanguage._id,
              targetLanguage: targetLanguage._id,
              status: "pending",
            };

            // Push the task to the generatedTasks array
            generatedTasks.push(task);
          }
        }
      }
      setProposedTasks(generatedTasks);
      setIsOpenGenerateDialog(true);
    }
  };

  const generateTasks = async () => {
    try {
      for (const task of proposedTasks) {
        await createTaskMutation(task).unwrap();
      }

      // Refetch the data after all tasks are created
      await refetch();
      showSnackbar("Tasks generated successfully", "success");
    } catch (err) {
      console.log(err);
    }
    setIsOpenGenerateDialog(false);
    setIsGeneratingTasks(false);
  };

  // Filter tasks
  const openTasks =
    data && data.tasks
      ? data.tasks.filter(
          (row) => row.status === "pending" || row.status === "active"
        )
      : [];

  const closedTasks =
    data && data.tasks
      ? data.tasks.filter(
          (row) => row.status === "completed" || row.status === "cancelled"
        )
      : [];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.3,
    },
    {
      field: "type",
      headerName: "Service Type",
      flex: 0.3,
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 0.2,
      renderCell: (params) => {
        const cost = params.value;
        const currency = params.row.currency;

        return `${cost ?? ""} ${currency ?? ""}`;
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 0.3,
      valueGetter: (params) => {
        const date = params.value;
        if (!date || !Number.isFinite(new Date(date).getTime())) {
          return "N/A";
        }
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(date));
      },
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 0.3,
      valueGetter: (params) => {
        const date = params.value;
        if (!date || !Number.isFinite(new Date(date).getTime())) {
          return "N/A";
        }
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(date));
      },
    },
    {
      field: "vendorIds",
      headerName: "Assigned Agents",
      flex: 0.5,
      valueGetter: (params) => {
        const vendorIds = params.row.vendorIds?.map((user) => user.name);
        return vendorIds.join(", ") || "N/A";
      },
    },
  ];

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "1rem",
        bgcolor: theme.palette.background.alt,
        mb: "1rem",
      }}
    >
      <SnackbarComponent />
      {/* Generate Tasks Dialog */}
      <GenerateTaskPopup
        isOpen={isOpenGenerateDialog}
        handleClose={() => {
          setIsOpenGenerateDialog(false);
          setIsGeneratingTasks(false);
        }}
        generate={generateTasks}
        proposedTasks={proposedTasks}
      />
      <Box
        display="flex"
        sx={{ padding: "1rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Tasks</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4rem",
          }}
        >
          <Button
            variant="outlined"
            disabled={
              !editedData ||
              editedData.plan?.length < 1 ||
              editedData.targetLanguages?.length < 1 ||
              editedData.sourceLanguage === null ||
              editedData.tasks?.length > 0 ||
              isGeneratingTasks
            }
            sx={{
              color: theme.palette.secondary[300],
              borderColor: theme.palette.secondary[300],
            }}
            onClick={() => handleDialogOpen()}
          >
            Generate Tasks
          </Button>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab label="Live Tasks" />
            <Tab label="Completed Tasks" />
          </Tabs>
        </Box>
      </Box>
      {/* Add content for each tab */}
      {tabValue === 0 && (
        <Box
          height="60vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-footerContainer": {
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <Divider />
          <DataGrid
            loading={!editedData || !data}
            getRowId={(row) => row._id}
            rows={openTasks}
            sx={{
              padding: "1rem",
              ".MuiDataGrid-virtualScroller": {
                borderTop: "2px solid rgba(224, 224, 224, 1)",
                overflowX: "hidden",
              },
            }}
            columns={columns}
            rowCount={openTasks.length}
            rowsPerPageOptions={[10, 20, 100]}
            pagination
            onRowClick={(e) => {
              navigate("/tasks/" + e.id);
            }}
          />
        </Box>
      )}
      {tabValue === 1 && (
        <Box
          height="60vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-footerContainer": {
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <Divider />
          <DataGrid
            loading={!editedData || !data}
            getRowId={(row) => row._id}
            rows={closedTasks}
            sx={{
              padding: "1rem",
              ".MuiDataGrid-virtualScroller": {
                borderTop: "2px solid rgba(224, 224, 224, 1)",
                overflowX: "hidden",
              },
            }}
            columns={columns}
            rowCount={closedTasks.length}
            rowsPerPageOptions={[10, 20, 100]}
            pagination
            onRowClick={(e) => {
              navigate("/tasks/" + e.id);
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default TaskPanel;
