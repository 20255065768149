import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  Divider,
  List,
  Paper,
  Box,
  Typography,
  useTheme,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CurrencySelector from "components/CurrencySelector";
import IncludeTax from "components/IncludeTax";

const QuoteEstimatesPanel = ({ editedData, handleEditChange }) => {
  const theme = useTheme();
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const calculate = () => {
    const value = editedData.quotedValue || 0;
    const fasterValue = value * 1.15;
    const urgentValue = value * 1.3;

    handleEditChange("quotedFasterValue", fasterValue.toFixed(2));
    handleEditChange("quotedUrgentValue", urgentValue.toFixed(2));
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "1rem",
        bgcolor: theme.palette.background.alt,
      }}
    >
      <Box
        display="flex"
        sx={{ padding: "1rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Quote Values</Typography>
        <Box>
          <Button
            variant="outlined"
            sx={{
              mr: "1rem",
              color: theme.palette.secondary[300],
              borderColor: theme.palette.secondary[300],
            }}
            onClick={handleEditClick}
          >
            {isEditMode ? <CheckIcon /> : <EditIcon />}
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: theme.palette.secondary[300],
              borderColor: theme.palette.secondary[300],
            }}
            onClick={() => calculate()}
          >
            Calculate
          </Button>
        </Box>
      </Box>
      <List>
        <Divider />
        <ListItem key="value">
          <ListItemText
            primary={"Advanced Booking Value"}
            sx={{ width: "50%" }}
          />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  size="small"
                  sx={{
                    width: "40%",
                  }}
                  value={editedData.quotedValue || ""}
                  onChange={(e) =>
                    handleEditChange("quotedValue", e.target.value)
                  }
                />
                <Box sx={{ width: "30%" }}>
                  <CurrencySelector
                    selectedCurrency={editedData.quotedCurrency}
                    onSelectCurrency={(currency) =>
                      handleEditChange("quotedCurrency", currency)
                    }
                  />
                </Box>
                <IncludeTax
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                />
              </Stack>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.quotedValue ?? ""}
                  </Typography>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.quotedCurrency ?? ""}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {editedData.plusVat ? "plus VAT" : ""}
                </Typography>
              </Box>
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="value">
          <ListItemText primary={"Faster Value"} sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  size="small"
                  sx={{
                    width: "40%",
                  }}
                  value={editedData.quotedFasterValue || ""}
                  onChange={(e) =>
                    handleEditChange("quotedFasterValue", e.target.value)
                  }
                />
                <Box sx={{ width: "30%" }}>
                  <CurrencySelector
                    selectedCurrency={editedData.quotedCurrency}
                    onSelectCurrency={(currency) =>
                      handleEditChange("quotedCurrency", currency)
                    }
                  />
                </Box>
                <IncludeTax
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                />
              </Stack>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.quotedFasterValue ?? ""}
                  </Typography>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.quotedCurrency ?? ""}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {editedData.plusVat ? "plus VAT" : ""}
                </Typography>
              </Box>
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="value">
          <ListItemText primary={"Urgent Value"} sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  size="small"
                  sx={{
                    width: "40%",
                  }}
                  value={editedData.quotedUrgentValue || ""}
                  onChange={(e) =>
                    handleEditChange("quotedUrgentValue", e.target.value)
                  }
                />
                <Box sx={{ width: "30%" }}>
                  <CurrencySelector
                    selectedCurrency={editedData.quotedCurrency}
                    onSelectCurrency={(currency) =>
                      handleEditChange("quotedCurrency", currency)
                    }
                  />
                </Box>
                <IncludeTax
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                />
              </Stack>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.quotedUrgentValue ?? ""}
                  </Typography>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.quotedCurrency ?? ""}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {editedData.plusVat ? "plus VAT" : ""}
                </Typography>
              </Box>
            )}
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
    </Paper>
  );
};

export default QuoteEstimatesPanel;
