import React from "react";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useGetJobCreationDataQuery } from "state/api";
import {
  useTheme,
  Box,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Header from "../../components/Header";
import { useGetQuoteStatisticsQuery } from "state/api";

const Insights = () => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(dayjs().subtract(30, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [isEndDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const { data: quoteStatistics, isLoading: isQuoteStatisticsLoading } =
    useGetQuoteStatisticsQuery({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });

  useEffect(() => {
    console.log(quoteStatistics);
  }, [quoteStatistics]);

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
    setEndDatePickerOpen(true);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
    setEndDatePickerOpen(false);
  };

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header
          title="Insights"
          subtitle="Use the options below to filter the data."
        />
      </Box>
      <Box
        px="2rem"
        sx={{
          height: "5vh",
          pt: "3rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start date"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <Box sx={{ mx: 2, display: "flex", alignItems: "center" }}>-</Box>
          <DatePicker
            label="End date"
            value={endDate}
            open={isEndDatePickerOpen}
            onOpen={() => setEndDatePickerOpen(true)}
            onClose={() => setEndDatePickerOpen(false)}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
      <Grid container spacing={2} sx={{ mt: "10px", width: "100%" }}>
        <Grid item xs={4}>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              mt: "1rem",
            }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Quote Status Overview</Typography>
            </Box>
            <List>
              {[
                {
                  label: "Total Quotes",
                  value: quoteStatistics?.totalQuotes ?? 0,
                },
                {
                  label: "Pending Quotes",
                  value: quoteStatistics?.pending ?? 0,
                },
                {
                  label: "Accepted Quotes",
                  value: quoteStatistics?.accepted ?? 0,
                },
                {
                  label: "Declined Quotes",
                  value: quoteStatistics?.declined ?? 0,
                },
                {
                  label: "Cancelled Quotes",
                  value: quoteStatistics?.cancelled ?? 0,
                },
              ].map((stat, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    secondaryAction={
                      <Typography variant="body1" sx={{ textAlign: "right" }}>
                        {stat.value}
                      </Typography>
                    }
                  >
                    <ListItemText primary={stat.label} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              mt: "1rem",
            }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Quote Statistics</Typography>
            </Box>
            <List>
              {[
                {
                  label: <b>Advanced Quotes</b>,
                },
                {
                  label: "Total Advanced Quotes",
                  value: quoteStatistics?.quoteStatistics?.advanced.count ?? 0,
                },
                {
                  label: "Average Advanced Quote Value",
                  value: `£ ${
                    quoteStatistics?.quoteStatistics?.advanced
                      ?.averageQuotedValue ?? 0
                  }`,
                },
                {
                  label: "Total Advanced Quoted Value",
                  value: `£ ${
                    quoteStatistics?.quoteStatistics?.advanced
                      ?.totalQuotedValue ?? 0
                  }`,
                },
                {
                  label: <b>Faster Quotes</b>,
                },
                {
                  label: "Total Faster Quotes",
                  value: quoteStatistics?.quoteStatistics?.faster?.count ?? 0,
                },
                {
                  label: "Average Faster Quote Value",
                  value: `£ ${
                    quoteStatistics?.quoteStatistics?.faster
                      ?.averageQuotedValue ?? 0
                  }`,
                },
                {
                  label: "Total Faster Quoted Value",
                  value: `£ ${
                    quoteStatistics?.quoteStatistics?.faster
                      ?.totalQuotedValue ?? 0
                  }`,
                },
                {
                  label: <b>Urgent Quotes</b>,
                },
                {
                  label: "Total Urgent Quotes",
                  value: quoteStatistics?.quoteStatistics?.urgent?.count ?? 0,
                },
                {
                  label: "Average Urgent Quote Value",
                  value: `£ ${
                    quoteStatistics?.quoteStatistics?.urgent
                      ?.averageQuotedValue ?? 0
                  }`,
                },
                {
                  label: "Total Urgent Quoted Value",
                  value: `£ ${
                    quoteStatistics?.quoteStatistics?.urgent
                      ?.totalQuotedValue ?? 0
                  }`,
                },
              ].map((stat, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    secondaryAction={
                      <Typography variant="body1" sx={{ textAlign: "right" }}>
                        {stat.value}
                      </Typography>
                    }
                  >
                    <ListItemText primary={stat.label} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Insights;
