import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Autocomplete,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { useGetLanguagesQuery } from "state/api";
import EditableContainer from "../EditableContainer";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

const LanguagesPanel = ({ data, editedData, handleEditChange }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { data: languagesData } = useGetLanguagesQuery();
  const [newLanguage, setNewLanguage] = useState({
    sourceLanguages: [],
    targetLanguage: null,
  });

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleAddLanguage = () => {
    if (newLanguage.sourceLanguages.length > 0 && newLanguage.targetLanguage) {
      const updatedLanguages = [...(editedData.languages || []), newLanguage];
      handleEditChange("languages", updatedLanguages);
      setNewLanguage({
        sourceLanguages: [],
        targetLanguage: null,
      });
    }
  };

  const handleRemoveLanguage = (index) => {
    const newLanguages = [...(editedData.languages || [])];
    newLanguages.splice(index, 1);
    handleEditChange("languages", newLanguages);
  };

  const handleEditLanguage = (index) => {
    const editedLanguages = [...(editedData.languages || [])];

    // Create a shallow copy of the object
    const editedLanguage = { ...editedLanguages[index] };
    editedLanguage.isEditing = true;
    editedLanguages[index] = editedLanguage;
    handleEditChange("languages", editedLanguages);
  };

  const handleSaveEditedLanguage = (index) => {
    const editedLanguages = [...(editedData.languages || [])];
    editedLanguages[index].isEditing = false;
    handleEditChange("languages", editedLanguages);
  };

  return (
    <EditableContainer
      title={`Languages`}
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <List>
        {editedData.languages?.map((language, index) => (
          <div key={`language-${index}`}>
            {language.isEditing ? (
              <ListItem>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Autocomplete
                    multiple
                    sx={{ width: "40%" }}
                    options={languagesData}
                    size="small"
                    getOptionLabel={(language) => language.name}
                    value={language.sourceLanguages || []}
                    onChange={(e, newValue) => {
                      const updatedLanguage = {
                        ...language,
                        sourceLanguages: newValue,
                      };
                      const updatedLanguages = [...editedData.languages];
                      updatedLanguages[index] = updatedLanguage;
                      handleEditChange("languages", updatedLanguages);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select source languages" />
                    )}
                  />
                  <Autocomplete
                    options={languagesData}
                    size="small"
                    sx={{ width: "40%" }}
                    getOptionLabel={(language) => language.name}
                    value={language.targetLanguage || null}
                    onChange={(e, newValue) => {
                      const updatedLanguage = {
                        ...language,
                        targetLanguage: newValue,
                      };
                      const updatedLanguages = [...editedData.languages];
                      updatedLanguages[index] = updatedLanguage;
                      handleEditChange("languages", updatedLanguages);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select target language" />
                    )}
                  />
                  <IconButton
                    color="secondary"
                    aria-label="save"
                    onClick={() => handleSaveEditedLanguage(index)}
                  >
                    <SaveIcon />
                  </IconButton>
                </Box>
              </ListItem>
            ) : (
              <ListItem>
                <ListItemText sx={{ width: "50%", mr: "2rem" }}>
                  {language.sourceLanguages
                    .map((sourceLanguage) => sourceLanguage.name)
                    .join(", ")}
                </ListItemText>
                <ListItemText sx={{ width: "20%" }}>into</ListItemText>
                <ListItemText sx={{ width: "30%" }}>
                  {language.targetLanguage?.name}
                </ListItemText>
                {isEditMode && (
                  <>
                    <IconButton
                      color="secondary"
                      aria-label="edit"
                      onClick={() => handleEditLanguage(index)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() => handleRemoveLanguage(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </ListItem>
            )}
            <Divider />
          </div>
        ))}
        {isEditMode && (
          <div>
            <ListItem>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Autocomplete
                  multiple
                  sx={{ width: "40%" }}
                  options={languagesData}
                  size="small"
                  getOptionLabel={(language) => language.name}
                  value={newLanguage.sourceLanguages || []}
                  onChange={(e, newValue) => {
                    setNewLanguage({
                      ...newLanguage,
                      sourceLanguages: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select source languages" />
                  )}
                />
                <Autocomplete
                  options={languagesData}
                  size="small"
                  sx={{ width: "40%" }}
                  getOptionLabel={(language) => language.name}
                  value={newLanguage.targetLanguage || null}
                  onChange={(e, newValue) => {
                    setNewLanguage({
                      ...newLanguage,
                      targetLanguage: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select target language" />
                  )}
                />
                <IconButton
                  color="secondary"
                  aria-label="add"
                  onClick={handleAddLanguage}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </ListItem>
          </div>
        )}
      </List>
    </EditableContainer>
  );
};

export default LanguagesPanel;
