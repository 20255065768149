import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetClientQuery,
  usePutClientMutation,
  useDeleteClientMutation,
  useCreateJobMutation,
} from "state/api";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Divider,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Stack,
  FormControl,
  Select,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material";
import Header from "components/Header";
import UnsavedChanges from "components/UnsavedChanges";
import { useSnackbar } from "components/Snackbar";
import Spinner from "components/Spinner";

const Client = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetClientQuery(id);
  const [createJobMutation] = useCreateJobMutation();
  const [putClientMutation] = usePutClientMutation();
  const [deleteClientMutation] = useDeleteClientMutation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isContactEditMode, setIsContactEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [newContactName, setNewContactName] = useState("");
  const [newContactPhoneNumber, setNewContactPhoneNumber] = useState("");
  const [newContactEmail, setNewContactEmail] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedContact, setEditedContact] = useState();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    if (data) {
      setEditedData({ ...data });
    }
  }, [data]);

  useEffect(() => {
    refetch(); // eslint-disable-next-line
  }, [id]);

  const handleSaveChanges = async () => {
    try {
      await putClientMutation({
        id: id,
        updatedData: editedData,
      }).then((res) => {
        showSnackbar("Client details updated successfully", "success");
      });

      setIsDataChanged(false);
      // Handle success or any other actions after successful update
    } catch (error) {
      showSnackbar("Error updating client details", "error");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteClientMutation(id).then((res) => {
        navigate("/clients");
      });
      // Handle success or any other actions after successful delete
    } catch (error) {
      showSnackbar("Error deleting client", "error");
    }
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
    setAnchorEl(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditNotes = () => {
    if (isEditingNotes) {
      setIsDataChanged(true);
    }
    setIsEditingNotes(!isEditingNotes);
  };

  const handleAddNote = () => {
    if (newNote.trim() !== "") {
      const timestamp = new Date().toISOString();
      const updatedNotes = [
        ...(editedData.notes || []),
        { note: newNote, timestamp },
      ];
      handleEditChange("notes", updatedNotes);
      setNewNote("");
    }
  };

  const handleEditContact = (index) => {
    setEditingIndex(index);
    setEditedContact(editedData.contacts[index]);
  };

  const handleSaveEditedContact = (index) => {
    const updatedContacts = [...editedData.contacts];
    updatedContacts[index] = editedContact;
    handleEditChange("contacts", updatedContacts);
    setEditingIndex(-1);
  };

  const handleDeleteNote = (index) => {
    const updatedNotes = [...editedData.notes];
    updatedNotes.splice(index, 1);
    handleEditChange("notes", updatedNotes);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Function to handle the addition of a new contact
  const handleAddContact = () => {
    if (
      newContactName.trim() === "" &&
      newContactPhoneNumber.trim() === "" &&
      newContactEmail.trim() === ""
    ) {
      return;
    }

    // Create a new contact object
    const newContact = {
      name: newContactName,
      phoneNumber: newContactPhoneNumber,
      email: newContactEmail,
    };

    // Add the new contact to the editedData.contacts array
    const updatedContacts = [...(editedData.contacts || []), newContact];
    handleEditChange("contacts", updatedContacts);

    // Clear the input fields
    setNewContactName("");
    setNewContactPhoneNumber("");
    setNewContactEmail("");
  };

  const handleRemoveContact = (index) => {
    setEditedData((prevData) => {
      const newContacts = [...prevData.contacts];
      newContacts.splice(index, 1);
      return { ...prevData, contacts: newContacts };
    });
  };

  const handleEditClick = () => {
    if (isEditMode) {
      setIsDataChanged(true);
    }
    setIsEditMode(!isEditMode);
  };

  const handleIsContactEditMode = () => {
    if (isContactEditMode) {
      setIsDataChanged(true);
    }
    setIsContactEditMode(!isContactEditMode);
  };

  const handleEditChange = (field, value) => {
    if (!isDataChanged) {
      setIsDataChanged(true);
    }
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <UnsavedChanges isDataChanged={isDataChanged} />
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this client? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              closeDeleteDialog();
              handleDelete();
            }}
            color="warning"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title={data?.name} subtitle="Client Profile" />
        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleMenuOpen}
            style={{ marginRight: "2rem" }}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={async () => {
                handleMenuClose();
                await createJobMutation({
                  clientId: id,
                }).then((res) => {
                  navigate(`/jobs/${res.data.jobId}`);
                });
              }}
            >
              Create New Job
            </MenuItem>
            <MenuItem onClick={() => openDeleteDialog()}>
              Delete Client
            </MenuItem>
          </Menu>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleSaveChanges}
            disabled={!isDataChanged}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: "10px", width: "100%" }}>
        <Grid item xs={6}>
          <Paper
            elevation={3}
            sx={{ padding: "1rem", bgcolor: theme.palette.background.alt }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Client Details</Typography>
              <Button
                variant="outlined"
                sx={{
                  color: theme.palette.secondary[300],
                  borderColor: theme.palette.secondary[300],
                }}
                onClick={handleEditClick}
              >
                {isEditMode ? <CheckIcon /> : <EditIcon />}
              </Button>
            </Box>
            <List>
              {/*
              <ListItem key="name">
                <ListItemText primary="Primary Contact" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      value={editedData.name || ""}
                      onChange={(e) => handleEditChange("name", e.target.value)}
                    />
                  ) : (
                    editedData.name ?? data.name
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              */}
              <ListItem key="businessName">
                <ListItemText primary="Business Name" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      value={editedData.businessName || ""}
                      onChange={(e) =>
                        handleEditChange("businessName", e.target.value)
                      }
                    />
                  ) : (
                    editedData.businessName ?? data.businessName
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              {/*
              <ListItem key="email">
                <ListItemText primary="Email" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      value={editedData.email || ""}
                      onChange={(e) =>
                        handleEditChange("email", e.target.value)
                      }
                    />
                  ) : (
                    editedData.email ?? data.email
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              */}
              <ListItem key="address">
                <ListItemText primary="Address" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      value={editedData.address || ""}
                      onChange={(e) =>
                        handleEditChange("address", e.target.value)
                      }
                    />
                  ) : (
                    editedData.address ?? data.address
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="phoneNumber">
                <ListItemText primary="Phone Number" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      value={editedData.phoneNumber || ""}
                      onChange={(e) =>
                        handleEditChange("phoneNumber", e.target.value)
                      }
                    />
                  ) : (
                    editedData.phoneNumber ?? data.phoneNumber
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              {/*
              <ListItem key="referenceNumber">
                <ListItemText
                  primary="Reference Number"
                  sx={{ width: "50%" }}
                />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      value={editedData.referenceNumber || ""}
                      onChange={(e) =>
                        handleEditChange("referenceNumber", e.target.value)
                      }
                    />
                  ) : (
                    editedData.referenceNumber ?? data.referenceNumber
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              */}
              <ListItem key="promotion">
                <ListItemText primary="Promotion" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <FormControl size="small" fullWidth>
                      <Select
                        value={editedData.promotion || ""}
                        onChange={(e) =>
                          handleEditChange("promotion", e.target.value)
                        }
                      >
                        <MenuItem value="BNI">BNI</MenuItem>
                        <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                        <MenuItem value="CCUK">CCUK</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    editedData.promotion ?? data.promotion
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="createdAt">
                <ListItemText primary="Created At" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {new Intl.DateTimeFormat("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZoneName: "short",
                  }).format(new Date(data.createdAt))}
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Box display="flex" flexDirection="column" sx={{ padding: "1rem" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h4">Notes</Typography>
                <Button
                  variant="outlined"
                  sx={{
                    color: theme.palette.secondary[300],
                    borderColor: theme.palette.secondary[300],
                  }}
                  onClick={handleEditNotes}
                >
                  {isEditingNotes ? <CheckIcon /> : <EditIcon />}
                </Button>
              </Box>
              <List>
                <Divider />
                {(editedData.notes || data.notes).map((note, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <ListItemText>
                        <Stack>
                          <Typography variant="caption">
                            {new Intl.DateTimeFormat("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                            }).format(new Date(note.timestamp))}
                          </Typography>
                          {note.note}
                        </Stack>
                      </ListItemText>
                      {isEditingNotes && (
                        <IconButton
                          color="error"
                          aria-label="delete"
                          onClick={() => handleDeleteNote(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                    {index !== (editedData.notes || data.notes).length - 1 && (
                      <Divider />
                    )}
                  </React.Fragment>
                ))}
                {isEditingNotes && (
                  <ListItem>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        placeholder="Add note"
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                      />
                      <IconButton
                        color="secondary"
                        aria-label="add"
                        onClick={handleAddNote}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                )}
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            elevation={3}
            sx={{ padding: "1rem", bgcolor: theme.palette.background.alt }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Current Jobs</Typography>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab label="Live Jobs" />
                <Tab label="Completed Jobs" />
              </Tabs>
            </Box>
            {/* Add content for each tab */}
            {tabValue === 0 && (
              <Box>
                <Divider />
                {data.jobs
                  .filter((job) => job.status === "open") // Filter jobs with status "open"
                  .map((job) => (
                    <Box>
                      <ListItem
                        key={job._id}
                        sx={{
                          display: "flex",
                          transition: "background-color 0.3s",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => navigate(`/jobs/${job._id}`)}
                      >
                        <ListItemText
                          primary={job.name}
                          secondary={job.description}
                          sx={{
                            flex: "4",
                          }}
                        />
                        <ListItemText
                          primary={
                            job.dueDate
                              ? `Due: ${new Intl.DateTimeFormat("en-US", {
                                  weekday: "long",
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }).format(new Date(job.dueDate))}`
                              : "No due date"
                          }
                          sx={{
                            flex: "1",
                          }}
                        />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
              </Box>
            )}
            {tabValue === 1 && (
              <Box>
                <Divider />
                {data.jobs
                  .filter((job) => job.status === "closed") // Filter jobs with status "closed"
                  .map((job) => (
                    <Box>
                      <ListItem
                        key={job._id}
                        sx={{
                          transition: "background-color 0.3s", // Add smooth transition
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light, // Change background color on hover
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => navigate(`/jobs/${job._id}`)}
                      >
                        <ListItemText
                          primary={job.name}
                          secondary={job.description}
                        />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
              </Box>
            )}
          </Paper>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Box display="flex" flexDirection="column" sx={{ padding: "1rem" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h4">Contacts</Typography>
                <Button
                  variant="outlined"
                  sx={{
                    color: theme.palette.secondary[300],
                    borderColor: theme.palette.secondary[300],
                  }}
                  onClick={handleIsContactEditMode}
                >
                  {isContactEditMode ? <CheckIcon /> : <EditIcon />}
                </Button>
              </Box>
              <Divider />
              <List>
                {editedData.contacts &&
                  (editedData?.contacts || data?.contacts).map(
                    (contact, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          {editingIndex === index ? (
                            // Editable Fields
                            <>
                              <ListItemText sx={{ width: "20%" }}>
                                <TextField
                                  size="small"
                                  sx={{ pr: "1rem" }}
                                  fullWidth
                                  placeholder="Add contact name"
                                  value={editedContact.name}
                                  onChange={(e) =>
                                    setEditedContact({
                                      ...editedContact,
                                      name: e.target.value,
                                    })
                                  }
                                />
                              </ListItemText>
                              <ListItemText sx={{ width: "20%" }}>
                                <TextField
                                  size="small"
                                  sx={{ pr: "1rem" }}
                                  fullWidth
                                  placeholder="Add phone number"
                                  value={editedContact.phoneNumber}
                                  onChange={(e) =>
                                    setEditedContact({
                                      ...editedContact,
                                      phoneNumber: e.target.value,
                                    })
                                  }
                                />
                              </ListItemText>
                              <ListItemText sx={{ width: "20%" }}>
                                <TextField
                                  size="small"
                                  sx={{ pr: "1rem" }}
                                  fullWidth
                                  placeholder="Add email"
                                  value={editedContact.email}
                                  onChange={(e) =>
                                    setEditedContact({
                                      ...editedContact,
                                      email: e.target.value,
                                    })
                                  }
                                />
                              </ListItemText>
                              <IconButton
                                color="secondary"
                                onClick={() => handleSaveEditedContact(index)}
                              >
                                <SaveIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => setEditingIndex(-1)}
                              >
                                {/* Cancel Icon */}
                              </IconButton>
                            </>
                          ) : (
                            // Display Mode
                            <>
                              <ListItemText
                                primary={contact.name}
                                secondary={`Phone: ${contact.phoneNumber}, Email: ${contact.email}`}
                              />
                              {isContactEditMode && (
                                <>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => handleEditContact(index)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    onClick={() => handleRemoveContact(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </>
                              )}
                            </>
                          )}
                        </ListItem>
                        {index !== editedData.contacts.length - 1 && (
                          <Divider />
                        )}
                      </React.Fragment>
                    )
                  )}
                {isContactEditMode && (
                  <ListItem>
                    <ListItemText sx={{ width: "20%" }}>
                      <TextField
                        size="small"
                        sx={{ pr: "1rem" }}
                        fullWidth
                        placeholder="Add contact name"
                        value={newContactName}
                        onChange={(e) => setNewContactName(e.target.value)}
                      />
                    </ListItemText>
                    <ListItemText sx={{ width: "20%" }}>
                      <TextField
                        size="small"
                        sx={{ pr: "1rem" }}
                        fullWidth
                        placeholder="Add phone number"
                        value={newContactPhoneNumber}
                        onChange={(e) =>
                          setNewContactPhoneNumber(e.target.value)
                        }
                      />
                    </ListItemText>
                    <ListItemText sx={{ width: "20%" }}>
                      <TextField
                        size="small"
                        sx={{ pr: "1rem" }}
                        fullWidth
                        placeholder="Add email"
                        value={newContactEmail}
                        onChange={(e) => setNewContactEmail(e.target.value)}
                      />
                    </ListItemText>
                    <IconButton
                      color="secondary"
                      aria-label="add"
                      onClick={handleAddContact}
                    >
                      <AddIcon />
                    </IconButton>
                  </ListItem>
                )}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Client;
