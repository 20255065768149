import React, { useState } from "react";
import {
  useTheme,
  ListItem,
  ListItemText,
  TextField,
  Divider,
  List,
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import EditableContainer from "../EditableContainer";
import CurrencySelector from "../CurrencySelector";
import IncludeTax from "../IncludeTax";
import { capitalizeFirstLetter } from "../usefulFunctions";

const FinanceDetailsPanel = ({ editedData, handleEditChange, changesMade }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const theme = useTheme();

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  const handleRateChange = (field, value) => {
    const updatedRate = {
      ...(editedData.agreedRate || {}),
      [field]: value,
    };
    handleEditChange("agreedRate", updatedRate);
  };

  return (
    <EditableContainer
      title="Finance Details"
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <List>
        <ListItem key="financialStatus">
          <ListItemText primary="Invoice Status" sx={{ width: "40%" }} />
          <ListItemText sx={{ width: "60%" }}>
            {isEditMode ? (
              <FormControl size="small" fullWidth>
                <Select
                  value={editedData.financialStatus || ""}
                  onChange={(e) =>
                    handleEditChange("financialStatus", e.target.value)
                  }
                >
                  <MenuItem value="awaiting">Awaiting</MenuItem>
                  <MenuItem value="received">Received</MenuItem>
                  <MenuItem value="disputed">Disputed</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="overdue">Overdue</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
            ) : (
              capitalizeFirstLetter(editedData.financialStatus ?? "")
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="rate">
          <ListItemText primary={"Agreed Rate"} sx={{ width: "40%" }} />
          {isEditMode ? (
            <>
              <ListItemText sx={{ width: "20%" }}>
                <FormControl sx={{ width: "90%" }}>
                  <InputLabel htmlFor="rates-category">Category</InputLabel>
                  <Select
                    label="Category"
                    value={editedData.agreedRate?.category || ""}
                    inputProps={{
                      name: "ratesCategory",
                      id: "rates-category",
                    }}
                    onChange={(e) =>
                      handleRateChange("category", e.target.value)
                    }
                  >
                    <MenuItem value="translation">Translation</MenuItem>
                    <MenuItem value="proofread">Proofread</MenuItem>
                    <MenuItem value="formatting">Formatting</MenuItem>
                    <MenuItem value="transcription">Transcription</MenuItem>
                    <MenuItem value="interpreting">Interpreting</MenuItem>
                    <MenuItem value="editing">Editing</MenuItem>
                  </Select>
                </FormControl>
              </ListItemText>
              <ListItemText sx={{ width: "20%" }}>
                <TextField
                  sx={{ width: "90%" }}
                  fullWidth
                  label="Cost"
                  value={editedData.agreedRate?.cost || ""}
                  onChange={(e) => handleRateChange("cost", e.target.value)}
                />
              </ListItemText>
              <ListItemText sx={{ width: "20%" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel htmlFor="rates-unit">Unit</InputLabel>
                  <Select
                    label="Unit"
                    value={editedData.agreedRate?.unit || ""}
                    inputProps={{
                      name: "ratesUnit",
                      id: "rates-unit",
                    }}
                    onChange={(e) => handleRateChange("unit", e.target.value)}
                  >
                    <MenuItem value="word">per word</MenuItem>
                    <MenuItem value="hourly">Hourly</MenuItem>
                    <MenuItem value="half day">Half day</MenuItem>
                    <MenuItem value="full day">Full day</MenuItem>
                    <MenuItem value="minimum charge">Minimum charge</MenuItem>
                  </Select>
                </FormControl>
              </ListItemText>
            </>
          ) : (
            <Box
              key={`rate-box`}
              sx={{ width: "60%", display: "flex", flexDirection: "row" }}
            >
              <ListItemText sx={{ width: "50%" }}>
                {capitalizeFirstLetter(editedData.agreedRate?.category)}
              </ListItemText>
              <ListItemText sx={{ width: "25%" }}>
                {editedData.agreedRate?.cost
                  ? `£${editedData.agreedRate?.cost}`
                  : ""}
              </ListItemText>
              <ListItemText sx={{ width: "25%" }}>
                {capitalizeFirstLetter(editedData.agreedRate?.unit)}
              </ListItemText>
            </Box>
          )}
        </ListItem>
        <Divider />
        <ListItem key="agreedAmount">
          <ListItemText primary={"Agreed Amount"} sx={{ width: "40%" }} />
          <ListItemText sx={{ width: "60%" }}>
            {isEditMode ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  size="small"
                  sx={{
                    width: "40%",
                  }}
                  value={editedData.agreedAmount || ""}
                  onChange={(e) =>
                    handleEditChange("agreedAmount", e.target.value)
                  }
                />
                <Box
                  sx={{
                    width: "30%",
                  }}
                >
                  <CurrencySelector
                    selectedCurrency={editedData.currency}
                    onSelectCurrency={(currency) =>
                      handleEditChange("currency", currency)
                    }
                  />
                </Box>
                <IncludeTax
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                />
              </Stack>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.agreedAmount ?? ""}
                  </Typography>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.currency ?? ""}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {editedData.vat ? "VAT " + editedData.vat : ""}
                </Typography>
              </Box>
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="cost">
          <ListItemText primary={"Invoiced Amount"} sx={{ width: "40%" }} />
          <ListItemText sx={{ width: "60%" }}>
            {isEditMode ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  size="small"
                  sx={{
                    width: "40%",
                  }}
                  value={editedData.cost || ""}
                  onChange={(e) => handleEditChange("cost", e.target.value)}
                />
                <Box
                  sx={{
                    width: "30%",
                  }}
                >
                  <CurrencySelector
                    selectedCurrency={editedData.currency}
                    onSelectCurrency={(currency) =>
                      handleEditChange("currency", currency)
                    }
                  />
                </Box>
                <IncludeTax
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                />
              </Stack>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.cost ?? ""}
                  </Typography>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.currency ?? ""}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {editedData.plusVat ? "plus VAT" : ""}
                </Typography>
              </Box>
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="invoiceNumber">
          <ListItemText primary="Invoice Number" sx={{ width: "40%" }} />
          <ListItemText sx={{ width: "60%" }}>
            {isEditMode ? (
              <TextField
                size="small"
                sx={{ width: "100%" }}
                value={editedData.invoiceNumber || ""}
                onChange={(e) =>
                  handleEditChange("invoiceNumber", e.target.value)
                }
              />
            ) : (
              editedData.invoiceNumber
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="invoiceDate">
          <ListItemText primary="Invoice Date" sx={{ width: "40%" }} />
          <ListItemText sx={{ width: "60%" }}>
            {isEditMode ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <DatePicker
                    value={
                      editedData.invoiceDate
                        ? dayjs(editedData.invoiceDate)
                        : null
                    }
                    onChange={(newDate) =>
                      handleEditChange(
                        "invoiceDate",
                        newDate ? newDate.toISOString() : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TimePicker
                    size="small"
                    value={
                      editedData.invoiceDate
                        ? dayjs(editedData.invoiceDate)
                        : null
                    }
                    onChange={(newTime) =>
                      handleEditChange(
                        "invoiceDate",
                        newTime
                          ? dayjs(editedData.invoiceDate)
                              .set("hour", newTime.hour())
                              .set("minute", newTime.minute())
                              .toISOString()
                          : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            ) : (
              editedData.invoiceDate &&
              new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZoneName: "short",
              }).format(new Date(editedData.invoiceDate))
            )}
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
    </EditableContainer>
  );
};

export default FinanceDetailsPanel;
