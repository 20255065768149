import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetTasksQuery, useCreateTaskMutation } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import ActionsMenu from "components/ActionsMenu";
import Spinner from "components/Spinner";

const Tasks = () => {
  const theme = useTheme();

  // Values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [createTaskMutation] = useCreateTaskMutation();
  const { data, isLoading, refetch } = useGetTasksQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
  });

  useEffect(() => {
    refetch(); // eslint-disable-next-line
  }, []);

  const actions = [
    {
      label: "Create New Task",
      onClick: async () => {
        try {
          await createTaskMutation().then((res) => {
            if (res.data.taskId) {
              navigate("/tasks/" + res.data.taskId);
            }
          });
        } catch (error) {
          console.error("Error creating Task:", error);
        }
      },
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.6,
    },
    {
      field: "jobId",
      headerName: "Job",
      flex: 0.5,
      valueGetter: (params) => params.row.jobId?.name ?? "N/A",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 0.3,
      valueGetter: (params) => {
        const date = params.value;
        if (!date || !Number.isFinite(new Date(date).getTime())) {
          return "N/A";
        }
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(date));
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "vendorIds",
      headerName: "Assigned Translators",
      flex: 0.5,
      valueGetter: (params) => {
        const vendorIds = params.row.vendorIds?.map((vendor) => vendor.name);
        return vendorIds.join(", ") || "N/A";
      },
    },
  ];

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title="Tasks" subtitle="List of tasks" />
        <ActionsMenu actions={actions} />
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.tasks) || []}
          sx={{
            padding: "1rem",
            backgroundColor: theme.palette.background.alt,
            ".MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              borderTop: "2px solid rgba(224, 224, 224, 1)",
              overflowX: "hidden",
            },
          }}
          columns={columns}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
          onRowClick={(e) => {
            navigate("/tasks/" + e.id);
          }}
        />
      </Box>
    </Box>
  );
};

export default Tasks;
