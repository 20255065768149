import React, { useState } from "react";
import {
  useTheme,
  List,
  ListItem,
  ListItemText,
  Link,
  TextField,
  IconButton,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditableContainer from "components/EditableContainer";

const FilesPanel = ({ data, editedData, handleEditChange, changesMade }) => {
  const theme = useTheme();
  const [isEditMode, setIsEditMode] = useState(false);
  const [newFile, setNewFile] = useState("");

  const handleAddFile = () => {
    if (newFile.trim() !== "") {
      handleEditChange("files", [...(editedData.files || []), newFile.trim()]);
      setNewFile("");
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...(editedData.files || data.files)];
    newFiles.splice(index, 1);
    handleEditChange("files", newFiles);
  };

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  return (
    <EditableContainer
      title="Files"
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <List>
        <Divider />
        {(editedData.files || data.files).map((file, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText
                sx={{
                  width: "80%",
                }}
              >
                <Link
                  href={file}
                  color={theme.palette.secondary[100]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file}
                </Link>
              </ListItemText>
              {isEditMode && (
                <IconButton
                  color="error"
                  aria-label="delete"
                  onClick={() => handleRemoveFile(index)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </ListItem>
            {index !== (editedData.files || data.files).length - 1 && (
              <Divider />
            )}
          </React.Fragment>
        ))}
        {isEditMode && (
          <ListItem>
            <ListItemText sx={{ width: "20%" }}>
              <TextField
                fullWidth
                placeholder="Paste file URL"
                value={newFile}
                onChange={(e) => setNewFile(e.target.value)}
              />
            </ListItemText>
            <IconButton
              color="secondary"
              aria-label="add"
              onClick={handleAddFile}
            >
              <AddIcon />
            </IconButton>
          </ListItem>
        )}
      </List>
    </EditableContainer>
  );
};

export default FilesPanel;
