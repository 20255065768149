import React from "react";
import { useTheme, Box, CircularProgress } from "@mui/material";

const Spinner = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: "12%" }}
      height="100%"
    >
      <CircularProgress
        size={100}
        sx={{ color: theme.palette.secondary[700] }}
      />
    </Box>
  );
};

export default Spinner;
