import React, { useState, useEffect } from "react";
import { useGetClientsQuery } from "state/api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";

const AssignClientDialog = ({ open, onClose, handleEditChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data: clientsData, refetch } = useGetClientsQuery({
    search: searchTerm,
  });
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    if (clientsData?.clients) {
      setClients(clientsData.clients);
    }
  }, [clientsData]);

  // Fetch clients when the search term changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetch();
    }, 300); // Delay for debounce

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, refetch]);

  const handleClientChange = (event, newValue) => {
    setSelectedClient(newValue);
  };

  const handleSaveClient = () => {
    handleEditChange("clientId", selectedClient);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="assign-client-dialog-title"
      aria-describedby="assign-client-dialog-description"
    >
      <DialogTitle id="assign-client-dialog-title">Assign Client</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="assign-client-dialog-description"
          sx={{
            mb: "2rem",
          }}
        >
          To assign this job to a client, start typing to search and then select
          from the list.
        </DialogContentText>
        <Autocomplete
          options={clients}
          getOptionLabel={(client) => client.businessName}
          value={selectedClient}
          onChange={handleClientChange}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search and select a client" />
          )}
          freeSolo // if you want to allow arbitrary input
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="warning">
          Cancel
        </Button>
        <Button onClick={handleSaveClient} color="success">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignClientDialog;
