import React, { useState } from "react";
import {
  useTheme,
  Box,
  Switch,
  FormControlLabel,
  Button,
  Popover,
  Grid,
  Divider,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const StatusSelector = ({
  statuses,
  statusVisibility,
  toggleStatusVisibility,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "column-editor-popover" : undefined;

  return (
    <Box style={{ marginRight: "2rem" }}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Button
          aria-controls="filter-menu"
          aria-haspopup="true"
          onClick={handleClick}
          color="secondary"
          variant="outlined"
        >
          STATUS
        </Button>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2}>
          <Grid container direction="column">
            {statuses.map((status, index) => (
              <Grid item key={status.field}>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={statusVisibility[status.field]}
                      onChange={() => toggleStatusVisibility(status.field)}
                    />
                  }
                  label={status.headerName}
                />
                <Divider />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
};

export default StatusSelector;
