import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useGetLanguagesQuery } from "state/api";
import Header from "components/Header";
import ActionsMenu from "components/ActionsMenu";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import EditLanguageDialog from "components/dialogs/EditLanguageDialog";
import { useSnackbar } from "components/Snackbar";
import Spinner from "components/Spinner";

const Languages = () => {
  const theme = useTheme();
  const { data, isLoading, refetch } = useGetLanguagesQuery();
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [isCreate, setIsCreate] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [columnsVisibility, setColumnsVisibility] = useState({
    name: true,
    code: true,
    toEnglish: true,
    fromEnglish: true,
  });

  useEffect(() => {
    refetch(); // eslint-disable-next-line
  }, []);

  // Create a filter function for searching
  const filterRows = (rows, searchText) => {
    return rows.filter((row) => {
      return (
        row.name.toLowerCase().includes(searchText.toLowerCase()) ||
        row.code.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  };

  const actions = [
    {
      label: "Create New Language",
      onClick: () => {
        setIsCreate(true);
        setIsLanguageModalOpen(true);
      },
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 0.5,
    },
    {
      field: "toEnglish",
      headerName: "To English",
      flex: 2,
      renderCell: (params) =>
        params.row.code !== "en" ? (
          <div>
            Expansion Percent: {params.value?.expansionPercent}, &nbsp; Advanced
            Booking: {params.value?.advancedBooking}
          </div>
        ) : null,
    },
    {
      field: "fromEnglish",
      headerName: "From English",
      flex: 2,
      renderCell: (params) =>
        params.row.code !== "en" ? (
          <div>
            Expansion Percent: {params.value?.expansionPercent}, &nbsp; Advanced
            Booking: {params.value?.advancedBooking}
          </div>
        ) : null,
    },
  ];

  const toggleColumnVisibility = (field) => {
    setColumnsVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const getColumns = () => {
    return columns.map((column) => ({
      ...column,
      hide: !columnsVisibility[column.field],
    }));
  };

  // Filter rows based on search input
  const filteredRows = filterRows(data || [], search);

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <EditLanguageDialog
        data={selectedLanguage}
        refetch={refetch}
        isCreate={isCreate}
        open={isLanguageModalOpen}
        onClose={() => {
          setIsCreate(false);
          setSelectedLanguage({});
          setIsLanguageModalOpen(false);
        }}
        showSnackbar={showSnackbar}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title="Languages" subtitle="List of languages" />
        <ActionsMenu actions={actions} />
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={filteredRows}
          selectionModel={[]}
          sx={{
            padding: "1rem",
            backgroundColor: theme.palette.background.alt,
            ".MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              borderTop: "2px solid rgba(224, 224, 224, 1)",
              overflowX: "hidden",
            },
          }}
          columns={getColumns()}
          rowCount={(data && data.total) || 0}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch, columns, columnsVisibility, toggleColumnVisibility },
          }}
          onRowClick={(e) => {
            setIsLanguageModalOpen(true);
            setSelectedLanguage(e.row);
          }}
        />
      </Box>
    </Box>
  );
};

export default Languages;
