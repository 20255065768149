import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  Divider,
  List,
  Paper,
  Box,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import { capitalizeFirstLetter } from "../usefulFunctions";

const JobDetailsPanel = ({ data }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "1rem",
        bgcolor: theme.palette.background.alt,
      }}
    >
      <Box
        display="flex"
        sx={{ padding: "1rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Job Overview</Typography>
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.secondary[300],
            borderColor: theme.palette.secondary[300],
          }}
          onClick={() => navigate(`/jobs/${data.job?._id}`)}
        >
          VIEW JOB
        </Button>
      </Box>
      <List>
        <Divider />
        <ListItem key="name">
          <ListItemText primary="Job ID" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>{data.job?.name}</ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="status">
          <ListItemText primary="Job Status" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {capitalizeFirstLetter(data.job?.status)}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="urgency">
          <ListItemText primary="Urgency" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {capitalizeFirstLetter(data.job?.urgency)}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="dueDate">
          <ListItemText primary="Job Due Date" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {data.job?.dueDate &&
              new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZoneName: "short",
              }).format(new Date(data.job?.dueDate))}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="clientName">
          <ListItemText primary="Client" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {data.job?.clientId?.businessName}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="type">
          <ListItemText primary="Type" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {capitalizeFirstLetter(data.job?.type)}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="wordCount">
          <ListItemText primary="Word Count" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {data.job?.wordCount}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="details">
          <ListItemText primary="Details" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {data.details?.map((detail, index) => (
              <span key={detail}>
                {Object.values(detail)}
                {index < data.details.length - 1 ? ", " : ""}
              </span>
            ))}
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
    </Paper>
  );
};

export default JobDetailsPanel;
