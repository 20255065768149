import React from "react";
import { useTheme, Paper, Typography, Divider } from "@mui/material";
import { capitalizeFirstLetter } from "components/usefulFunctions";

const HelpSidebar = ({ editedData, data }) => {
  const theme = useTheme();

  const sidebarStyles = {
    position: "sticky",
    top: 0,
    right: 0,
    height: "100vh",
    overflowY: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    width: "100%",
    backgroundColor: theme.palette.background.alt,
    padding: "2rem",
    marginTop: "2rem",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  };

  // Define a function to conditionally style the text for each task
  const getTaskStyle = (taskName) => {
    let d = editedData;
    const isCompleted =
      (taskName === `- Complete job details` &&
        d.name &&
        d.cost &&
        d.currency &&
        d.urgency &&
        d.wordCount &&
        d.type) ||
      (taskName === "- Add due date" && d.dueDate) ||
      (taskName === "- Add client" && d.clientId) ||
      (taskName === "- Assign managing agent" && d.userIds?.length > 0) ||
      (taskName === "- Add source language(s)" &&
        d.sourceLanguages?.length > 0) ||
      (taskName === "- Add target language(s)" &&
        d.targetLanguages?.length > 0) ||
      (taskName === "- Generate quote" && d.cost > 0) ||
      (taskName === "- Add files" && d.files?.length > 0) ||
      (taskName === "- Create plan" && d.plan?.length > 0) ||
      (taskName === "- Generate tasks" && d.tasks?.length > 0) ||
      (taskName === "- Assign agents to all tasks" &&
        d.tasks?.length > 0 &&
        d.tasks?.every((task) => task.vendorIds.length > 0)) ||
      (taskName === "- Add details to tasks inc. cost" &&
        d.tasks?.length > 0 &&
        d.tasks?.every((task) => task.cost)) ||
      false;

    return {
      textDecoration: isCompleted ? "line-through" : "none",
      color: isCompleted ? theme.palette.text.disabled : "inherit",
    };
  };

  const tasks = [
    `- Complete job details`,
    "- Add due date",
    "- Add client",
    "- Assign managing agent",
    "- Add source language(s)",
    "- Add target language(s)",
    "- Generate quote",
    "- Add files",
    "- Create plan",
    "- Generate tasks",
    "- Assign agents to all tasks",
    "- Add details to tasks inc. cost",
  ];

  // Check if all tasks are completed
  const allTasksCompleted = tasks.every((task) => {
    const isCompleted = getTaskStyle(task).textDecoration === "line-through";
    return isCompleted;
  });

  return (
    <Paper elevation={4} style={sidebarStyles}>
      <Typography
        sx={{
          textAlign: "center",
        }}
        variant="h4"
      >
        Job Overview
      </Typography>
      <Divider sx={{ my: "1rem" }} />
      <Typography sx={{ my: "1rem" }} variant="h5">
        Details
      </Typography>
      <Typography sx={{ my: "1rem" }} variant="h6">
        Job ID: {editedData.name}
      </Typography>
      <Typography sx={{ mt: "1rem" }} variant="h6">
        Type:
      </Typography>
      <Typography sx={{ mb: "1rem" }} variant="h6">
        {capitalizeFirstLetter(editedData.type)}
      </Typography>
      <Typography sx={{ mt: "1rem" }} variant="h6">
        Client:
      </Typography>
      <Typography sx={{ mb: "1rem" }} variant="h6">
        {editedData.clientId?.businessName ?? ""}
      </Typography>
      <Typography sx={{ mt: "1rem" }} variant="h6">
        Source Language:
      </Typography>
      <Typography sx={{ mb: "1rem" }} variant="h6">
        {editedData.sourceLanguages
          ?.map((language) => language.name)
          .join(", ")}
      </Typography>
      <Typography sx={{ mt: "1rem" }} variant="h6">
        Target Languages:
      </Typography>
      <Typography sx={{ mb: "1rem" }} variant="h6">
        {editedData.targetLanguages
          ?.map((language) => language.name)
          .join(", ")}
      </Typography>
      <Divider sx={{ my: "1rem" }} />
      <Typography sx={{ my: "1rem" }} variant="h5">
        Tips
      </Typography>
      {tasks.map((task, index) => (
        <Typography
          key={index}
          variant="body1"
          sx={getTaskStyle(task)}
          marginBottom={"1rem"}
        >
          {task}
        </Typography>
      ))}
      {allTasksCompleted && (
        <Typography
          variant="body1"
          sx={{ textDecoration: "none", color: "inherit" }}
        >
          All steps complete!
        </Typography>
      )}
      <Divider sx={{ my: "1rem" }} />
    </Paper>
  );
};

export default HelpSidebar;
