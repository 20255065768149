import React, { useState } from "react";
import {
  Box,
  Typography,
  CssBaseline,
  Container,
  Paper,
  ListItem,
  List,
  TextField,
  Grid,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import DeleteIcon from "@mui/icons-material/Delete";
import Spinner from "../Spinner";

const addOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

const NdaForm = ({
  organisation,
  logoUrl,
  formData,
  handleChange,
  handleSubmit,
  isSubmitted,
  isSubmitting,
  signatureRef,
  errors,
}) => {
  const [isCanvasSigned, setIsCanvasSigned] = useState(false);
  const currentDate = new Date();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
  };

  const formattedDate = `${currentDate.toLocaleDateString(
    "en-US",
    options
  )}, ${addOrdinalSuffix(currentDate.getDate())}`;

  const clearSignature = () => {
    setIsCanvasSigned(false);
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  if (!organisation) {
    return <Spinner />;
  }

  return (
    <Container
      sx={{ mb: "3rem" }}
      component="main"
      className="vendor-signup-container"
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          crossorigin="anonymous"
          src={logoUrl}
          alt="organisation logo"
          style={{ width: "30%" }}
        />
        <Typography variant="h4" sx={{ my: "1rem" }}>
          {organisation.name} Confidentiality Agreement
        </Typography>
        <form
          width="100%"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          noValidate
        >
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <List>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                  Confidentiality Agreement Between (1) "The Translator"
                </Typography>
              </ListItem>
              <Grid>
                <ListItem sx={{ mt: "1rem" }}>
                  <TextField
                    label="Full Name"
                    type="text"
                    fullWidth
                    value={formData.ndaName1}
                    onChange={(e) => {
                      errors.ndaName1 = false;
                      handleChange(e, "ndaName1");
                    }}
                    error={errors.ndaName1}
                  />
                </ListItem>
                <ListItem sx={{ mt: "1rem" }}>
                  <Typography variant="body1">
                    Made on {formattedDate}
                  </Typography>
                </ListItem>
              </Grid>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">and</Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (2) {organisation.name} (“{organisation.name}”), a company
                  operating as {organisation.name} from offices at{" "}
                  {organisation.address}.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  The Translator acknowledges that during the Assignment they
                  shall have access to and use of Confidential Information.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  IT IS HEREBY AGREED AS FOLLOWS
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (A) The Translator will keep confidential and not disclose to
                  any third party whatsoever all documentation and other
                  information (“the Confidential Information”) disclosed to the
                  Translator by {organisation.name} or by {organisation.name}’s
                  clients pursuant to an agreement to undertake work on behalf
                  of {organisation.name}, whether such disclosure occurs prior
                  to, during or after the Translator is assigned such work by{" "}
                  {organisation.name}.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (B) The Translator will not disclose the Confidential
                  Information to anyone other than to such associates of the
                  Translator who require access to the information for the
                  purpose of carrying out the agreement between the Translator
                  and {organisation.name} relating to the Confidential
                  Information. The Translator shall ensure that all associates
                  of the Translator are at all times aware of the obligations of
                  confidentiality relating to the Confidential Information and
                  agree to abide by the provisions of the same.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (C) The Translator agrees that they will not (other than in
                  the proper course of their duties under this agreement),
                  either during the Assignment or at any time thereafter (unless
                  authorised to do so by the Company in writing) directly or
                  indirectly (i) use for their own benefit or the benefit of any
                  thirdparty or (ii) disclose or permit the disclosure of, any
                  Confidential Information.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (D) The Translator agrees that they will at all times use
                  their best endeavours to protect the secrecy of the
                  Confidential Information and prevent the unlawful disclosure
                  or publication of it.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (E) The restrictions on the Translator’s use of Confidential
                  Information shall not apply to: Confidential Information which
                  comes into the public domain other than as a consequence of
                  their unlawful or uncontractual disclosure; any protected
                  disclosure made in accordance with s.43A of the Employment
                  Rights Act 1996; any information which is required to be
                  disclosed by the Worker by order of a court of competent
                  jurisdiction or an appropriate regulatory authority or
                  otherwise required by law.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (F) The Translator shall, upon {organisation.name}'s demand,
                  return forthwith all documents and materials in physical,
                  virtual or electronic form containing the Confidential
                  Information, or if requested, shall destroy all documents and
                  materials containing the Confidential Information.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (G) The Translator will not upload to any sites, social or
                  information media or any other electronic service or facility
                  any copies of the translation material, either in its original
                  or in translated form. However, individual phrases may be
                  checked in isolation separately with machine translation
                  services for the purpose of fulfilling the assignment.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (H) Where the translator is instructed, the translation must
                  be security encoded before being e-mailed.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (I) IPR The Translator hereby assigns to the Company with full
                  title guarantee all the right, title and interest in and to
                  the IPR in the Work Product (including without limitation all
                  future IPR where capable of present assignment under
                  applicable law
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (J) The Translator warrants that: the Work Product will be
                  their original work and has not been copied wholly or
                  substantially from any other work or material or any other
                  source [unless agreed beforehand with the client]; and
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (K) the copyright of all documents produced by the Translator
                  during the course of this contract or from information so
                  acquired shall belong to the Company and
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (L) the Translator confirms that as the author of the Work
                  Product, grants to the Company under this agreement, absolute,
                  irrevocable and unconditional waivers in relation to the moral
                  rights and
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (M) in each case arising under Chapter 4 of the Copyright,
                  Designs and Patents Act 1988 and, so far as is legally
                  possible, any broadly equivalent rights that the Translator
                  may have in any territory of the world in relation to the Work
                  Product produced under this agreement.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (N) The Company has the right to intercept, monitor and review
                  all telephone calls, emails and attachments or other
                  electronic files and documents which are or have been within
                  its telephone, email and computer systems.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (O) Data Protection {organisation.name} agrees to disclose the
                  Confidential Information to the Translator and to authorize
                  the Translator to use the same for the sole purpose of
                  carrying out the agreement to undertake work relating to the
                  Confidential Information, and The Translator agrees that
                  personal data (including sensitive data) relating to the
                  translator which has been or is in the future obtained by the
                  Company may be held and processed by any Group Company (and
                  where necessary its agents or appointed third parties) either
                  by computer or manually for any purpose relating to the
                  administration, management, and operation of the Translator's
                  employment, or in relation to the Company's legal obligations
                  or business needs.
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  (P) The Translator agrees to provide the Company with his/her
                  updated address, telephone, and mobile numbers and
                </Typography>
              </ListItem>
              <ListItem sx={{ mt: "1rem" }}>
                <Typography variant="body1">
                  The translator agrees to abide by the terms of this agreement,
                  even after the work has been completed.
                </Typography>
              </ListItem>
              <Grid container>
                {(errors.sign || errors.printName) && (
                  <Typography
                    variant="body2"
                    color="#df0000"
                    textAlign="center"
                    mb="1rem"
                  >
                    Sign and print your name below
                  </Typography>
                )}
                <Grid item xs={6}>
                  <div
                    style={{
                      border: errors.signature ? "1px solid red" : "1px solid",
                      margin: "1rem",
                      position: "relative",
                    }}
                  >
                    {!isCanvasSigned && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontStyle: "italic",
                          }}
                        >
                          Sign here
                        </Typography>
                      </div>
                    )}
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 150,
                      }}
                      ref={signatureRef}
                      onBegin={() => {
                        errors.signature = false;
                        setIsCanvasSigned(true);
                      }}
                    />
                    <IconButton
                      color="error"
                      onClick={clearSignature}
                      sx={{ mx: "1rem" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <ListItem sx={{ mt: "1rem" }}>
                    <TextField
                      label="Full Name"
                      type="text"
                      fullWidth
                      value={formData.ndaName2}
                      onChange={(e) => {
                        errors.ndaName2 = false;
                        handleChange(e, "ndaName2");
                      }}
                      error={errors.ndaName2}
                    />
                  </ListItem>
                  <ListItem sx={{ mt: "1rem", textAlign: "center" }}>
                    <Typography variant="body1">
                      Signed: {formattedDate}
                    </Typography>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider sx={{ my: 3 }} />
              </Grid>
              <Box
                sx={{ width: "100%", alignContent: "center", display: "flex" }}
              >
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isSubmitted || isSubmitting}
                  variant="contained"
                  sx={{ mt: 3, mb: 2, width: "30%", margin: "auto" }}
                >
                  Register
                </Button>
              </Box>
            </List>
          </Paper>
        </form>
      </Box>
    </Container>
  );
};

export default NdaForm;
