import React, { useState, useEffect } from "react";
import {
  useTheme,
  Box,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Header from "components/Header";
import Spinner from "components/Spinner";
import {
  useGetAllNotificationsQuery,
  usePatchMarkAllNotificationsAsReadMutation,
  usePatchMarkOneNotificationAsReadMutation,
} from "state/api";
import { useAuth } from "components/auth";
import { useSnackbar } from "components/Snackbar";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const userId = auth.user;
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const {
    data: notificationData,
    isLoading,
    refetch,
  } = useGetAllNotificationsQuery({
    id: userId,
    page: currentPage,
    limit: 10,
  });
  const [markNotificationAsRead] = usePatchMarkOneNotificationAsReadMutation();
  const [markAllNotificationsAsRead] =
    usePatchMarkAllNotificationsAsReadMutation();
  const theme = useTheme();

  useEffect(() => {
    if (userId) {
      refetch();
    }

    if (notificationData) {
      setTotalPages(notificationData.totalpage);
    } // eslint-disable-next-line
  }, [currentPage, notificationData, userId]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      const response = await markNotificationAsRead({
        id: notificationId,
      });

      if (response.data) {
        await refetch();
        showSnackbar("Notification marked as read", "success");
      } else {
        showSnackbar("Failed to mark notification as read", "error");
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
      showSnackbar("An error occurred", "error");
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px="2rem"
          >
            <Header title="Notifications" />
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ ml: "2rem" }}
              onClick={async () => {
                await markAllNotificationsAsRead({ id: userId });
                refetch();
              }}
            >
              Mark All As Read
            </Button>
          </Box>
          <Grid container spacing={2} sx={{ mt: "5px", width: "100%" }}>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  padding: "1rem",
                  bgcolor: theme.palette.background.alt,
                }}
              >
                <Box
                  sx={{
                    height: "80vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <List>
                    {notificationData?.notifications.map((notification) => (
                      <>
                        <ListItem
                          sx={{
                            "&:hover": {
                              bgcolor: theme.palette.primary.light,
                            },
                          }}
                        >
                          <Box
                            onClick={() =>
                              navigate("/jobs/" + notification.reference.id)
                            }
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              width: "90%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <ListItemText
                              primary={notification.title}
                              secondary={new Date(
                                notification.createdAt
                              ).toLocaleString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              })}
                              secondaryTypographyProps={{
                                variant: "body2",
                                color: "textSecondary",
                              }}
                            />
                            <ListItemText
                              primary={notification.text}
                              primaryTypographyProps={{
                                variant: "body1",
                                color: "textPrimary",
                              }}
                            />
                          </Box>
                          <ListItemSecondaryAction>
                            <Button
                              onClick={() => handleMarkAsRead(notification._id)}
                              color="secondary"
                              disabled={notification.read}
                            >
                              Read
                            </Button>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconButton
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      <ChevronLeft />
                    </IconButton>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        mx: "1rem",
                      }}
                    >
                      Page {currentPage} of {totalPages}
                    </Typography>
                    <IconButton
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      <ChevronRight />
                    </IconButton>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Notifications;
