import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  RouterProvider,
  Navigate,
  createBrowserRouter,
} from "react-router-dom";
import { themeSettings } from "theme";
import { CheckAuth } from "./components/CheckAuth";
import { AuthProvider } from "./components/auth";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Jobs from "scenes/jobs-list";
import Translators from "scenes/translator-list";
import Translator from "scenes/translator";
import Admins from "scenes/admin-list";
import Admin from "scenes/admin";
import Login from "scenes/login";
import Job from "scenes/jobs";
import Tasks from "scenes/task-list";
import Task from "scenes/task";
import Clients from "scenes/client-list";
import Client from "scenes/client";
import Calendar from "scenes/calendar";
import Quotes from "scenes/quotes-list";
import Quote from "scenes/quote";
import Error from "scenes/error";
import Overview from "scenes/overview";
import Settings from "scenes/settings";
import Languages from "scenes/languages-list";
import VendorSignup from "scenes/vendor-signup";
import PendingVendors from "scenes/pending-vendor-list";
import PendingVendor from "scenes/pending-vendor";
import Notifications from "scenes/notifications";
import OrganisationAdmin from "scenes/app-admin";
import OrganisationAdminList from "scenes/app-admin-list";
import Organisation from "scenes/organisation";
import Insights from "scenes/insights";

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: (
          <CheckAuth>
            <Navigate to="/login" replace />
          </CheckAuth>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <CheckAuth>
            <Dashboard />
          </CheckAuth>
        ),
      },
      {
        path: "/calendar",
        element: (
          <CheckAuth>
            <Calendar />
          </CheckAuth>
        ),
      },
      {
        path: "/quotes",
        element: (
          <CheckAuth>
            <Quotes />
          </CheckAuth>
        ),
      },
      {
        path: "/quotes/:id",
        element: (
          <CheckAuth>
            <Quote />
          </CheckAuth>
        ),
      },
      {
        path: "/jobs",
        element: (
          <CheckAuth>
            <Jobs />
          </CheckAuth>
        ),
      },
      {
        path: "/jobs/:id",
        element: (
          <CheckAuth>
            <Job />
          </CheckAuth>
        ),
      },
      {
        path: "/tasks",
        element: (
          <CheckAuth>
            <Tasks />
          </CheckAuth>
        ),
      },
      {
        path: "/tasks/:id",
        element: (
          <CheckAuth>
            <Task />
          </CheckAuth>
        ),
      },
      {
        path: "/translators",
        element: (
          <CheckAuth>
            <Translators />
          </CheckAuth>
        ),
      },
      {
        path: "/translators/:id",
        element: (
          <CheckAuth>
            <Translator />
          </CheckAuth>
        ),
      },
      {
        path: "/clients",
        element: (
          <CheckAuth>
            <Clients />
          </CheckAuth>
        ),
      },
      {
        path: "/clients/:id",
        element: (
          <CheckAuth>
            <Client />
          </CheckAuth>
        ),
      },
      {
        path: "/overview",
        element: (
          <CheckAuth>
            <Overview />
          </CheckAuth>
        ),
      },
      {
        path: "/insights",
        element: (
          <CheckAuth>
            <Insights />
          </CheckAuth>
        ),
      },
      {
        path: "/admins",
        element: (
          <CheckAuth>
            <Admins />
          </CheckAuth>
        ),
      },
      {
        path: "/admins/:id",
        element: (
          <CheckAuth>
            <Admin />
          </CheckAuth>
        ),
      },
      {
        path: "/languages",
        element: (
          <CheckAuth>
            <Languages />
          </CheckAuth>
        ),
      },
      {
        path: "/settings",
        element: (
          <CheckAuth>
            <Settings />
          </CheckAuth>
        ),
      },
      {
        path: "/notifications",
        element: (
          <CheckAuth>
            <Notifications />
          </CheckAuth>
        ),
      },
      {
        path: "/pending-vendors",
        element: (
          <CheckAuth>
            <PendingVendors />
          </CheckAuth>
        ),
      },
      {
        path: "/pending-vendors/:id",
        element: (
          <CheckAuth>
            <PendingVendor />
          </CheckAuth>
        ),
      },
      {
        path: "/organisation-admins",
        element: (
          <CheckAuth>
            <OrganisationAdminList />
          </CheckAuth>
        ),
      },
      {
        path: "/organisation-admins/:id",
        element: (
          <CheckAuth>
            <OrganisationAdmin />
          </CheckAuth>
        ),
      },
      {
        path: "/organisation",
        element: (
          <CheckAuth>
            <Organisation />
          </CheckAuth>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: (
      <CheckAuth>
        <Login />
      </CheckAuth>
    ),
  },
  {
    path: "/vendor/signup/:orgExtension",
    element: <VendorSignup />,
  },
]);

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
