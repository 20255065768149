import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "components/auth";
import Header from "components/Header";
import dayjs from "dayjs";
import MiniCalendar from "components/MiniCalendar";
import {
  Box,
  Grid,
  Paper,
  Typography,
  List,
  Divider,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material";
import {
  useGetJobsQuery,
  useGetJobCreationDataQuery,
  useGetFiveMostRecentJobsQuery,
  useGetWeeklyJobsQuery,
  useGetInvoicedJobValuesQuery,
} from "state/api";
import JobCreationChart from "components/JobCreationChart";

const Dashboard = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const userId = auth.user;
  const theme = useTheme();
  const [yMax, setYMax] = useState(0);
  const [weeklyJobs, setWeeklyJobs] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [userJobData, setUserJobData] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(28, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [totalInvoiced, setTotalInvoiced] = useState(0);
  const { data, isLoading, refetch } = useGetJobsQuery({
    page: 0,
    pageSize: 20,
    sort: JSON.stringify({}),
    search: "",
  });

  const {
    data: invoicedJobValue,
    isLoading: isLoadingInvoicedJobValue,
    refetch: refetchInvoicedJobValue,
  } = useGetInvoicedJobValuesQuery();

  const {
    data: jobWeeklyData,
    isLoading: isLoadingJobWeeklyData,
    refetch: refetchJobWeeklyData,
  } = useGetWeeklyJobsQuery();

  const {
    data: jobCreationData,
    isLoading: isLoadingJobCreationData,
    refetch: refetchJobCreationData,
  } = useGetJobCreationDataQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  const {
    data: fiveMostRecentJobs,
    isLoading: isLoadingFiveMostRecentJobs,
    refetch: refetchFiveMostRecentJobs,
  } = useGetFiveMostRecentJobsQuery();

  useEffect(() => {
    refetch();
    refetchJobCreationData();
    refetchJobWeeklyData();
    refetchFiveMostRecentJobs(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && data.jobs.length > 0) {
      const userData = data.jobs.filter(
        (job) =>
          job.userIds.some((user) => user._id === userId) &&
          job.status === "open"
      );
      setUserJobData(userData);
    }
  }, [data, userId]);

  useEffect(() => {
    if (invoicedJobValue && !isLoadingInvoicedJobValue) {
      setTotalInvoiced(invoicedJobValue.totalCost);
    }
  }, [invoicedJobValue]);

  useEffect(() => {
    if (jobWeeklyData && !isLoadingJobWeeklyData) {
      const combinedData = [];
      Object.keys(jobWeeklyData.jobs).forEach((jobKey) => {
        const job = jobWeeklyData.jobs[jobKey];
        combinedData.push({
          title: job.name,
          start: job.dueDate,
          id: job._id,
          description: job.description,
          assignedTo: job.userIds,
          type: "job",
        });
      });
      Object.keys(jobWeeklyData.tasks).forEach((taskKey) => {
        const task = jobWeeklyData.tasks[taskKey];
        combinedData.push({
          title: task.name,
          start: task.dueDate,
          id: task._id,
          description: task.description,
          assignedTo: task.vendorIds,
          type: "task",
        });
      });
      setWeeklyJobs(combinedData);
    } // eslint-disable-next-line
  }, [jobWeeklyData]);

  useEffect(() => {
    if (jobCreationData && jobCreationData.data.length > 0) {
      // Prepare job and quote data series
      const jobSeries = {
        id: "Jobs",
        data: jobCreationData.data.map((week) => ({
          x: week.x,
          y: week.jobs,
        })),
      };

      const quoteSeries = {
        id: "Quotes",
        data: jobCreationData.data.map((week) => ({
          x: week.x,
          y: week.quotes,
        })),
      };

      const formattedData = [jobSeries, quoteSeries];

      // Set state for chart data
      setSeriesData(formattedData);

      // Calculate max Y value for yScale max in the chart
      const maxYValue = Math.max(
        ...jobCreationData.data.flatMap((week) => [week.jobs, week.quotes])
      );
      setYMax(maxYValue);
    }
  }, [jobCreationData]);

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header
          title="Home"
          subtitle={
            (new Date().getHours() < 12
              ? "Good morning"
              : new Date().getHours() < 18
              ? "Good afternoon"
              : "Good evening") + ", welcome to your dashboard."
          }
        />
      </Box>
      <Grid container spacing={2} sx={{ mt: "10px", width: "100%" }}>
        <Grid item xs={4}>
          <Paper
            elevation={3}
            sx={{ padding: "1rem", bgcolor: theme.palette.background.alt }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">
                Total Live Jobs: {data && !isLoading ? data.liveTotal : "..."}
              </Typography>
            </Box>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              mt: "1rem",
            }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Most Recently Created Jobs</Typography>
            </Box>
            <Box>
              <List>
                <Divider />
                {fiveMostRecentJobs &&
                  !isLoadingFiveMostRecentJobs &&
                  fiveMostRecentJobs.map((job) => (
                    <Box>
                      <ListItem
                        key={job._id}
                        sx={{
                          display: "flex",
                          transition: "background-color 0.3s",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => navigate(`/jobs/${job._id}`)}
                      >
                        <ListItemText
                          primary={
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="h6">{job.name}</Typography>
                              <Typography variant="caption">
                                Created:{" "}
                                {new Intl.DateTimeFormat("en-UK", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }).format(new Date(job.createdAt))}
                              </Typography>
                            </Box>
                          }
                          secondary={job.description}
                          sx={{
                            flex: "4",
                          }}
                        />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
              </List>
            </Box>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              mt: "1rem",
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
            }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Total Invoiced Value:</Typography>
              <Typography variant="h5">
                £
                {totalInvoiced !== null
                  ? totalInvoiced.toLocaleString()
                  : "N/A"}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
            }}
          >
            <Box sx={{ height: "8rem", mb: "4.5rem" }}>
              <Box
                display="flex"
                sx={{ padding: "1rem" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h4">Weekly Calendar</Typography>
                <Button
                  variant="outlined"
                  sx={{
                    color: theme.palette.secondary[300],
                    borderColor: theme.palette.secondary[300],
                  }}
                  onClick={() => navigate("/calendar")}
                >
                  GO TO CALENDAR
                </Button>
              </Box>
              <MiniCalendar events={weeklyJobs} />
            </Box>
          </Paper>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper
                elevation={3}
                sx={{
                  padding: "1rem",
                  bgcolor: theme.palette.background.alt,
                  mt: "1rem",
                }}
              >
                <Box
                  display="flex"
                  sx={{ padding: "1rem" }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h4">New Jobs Each Week</Typography>
                </Box>
                <Box height="20vh">
                  <JobCreationChart
                    isDashboard={true}
                    seriesData={seriesData}
                    isLoadingJobCreationData={isLoadingJobCreationData}
                    yMax={yMax}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper
                elevation={3}
                sx={{
                  padding: "1rem",
                  bgcolor: theme.palette.background.alt,
                  mt: "1rem",
                }}
              >
                <Box
                  display="flex"
                  sx={{ padding: "1rem" }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h4">Your Jobs Due Soon</Typography>
                </Box>
                <Box>
                  <List>
                    <Divider />
                    {userJobData && userJobData.length > 0 ? (
                      userJobData.map((job) => (
                        <Box>
                          <ListItem
                            key={job._id}
                            sx={{
                              display: "flex",
                              transition: "background-color 0.3s",
                              "&:hover": {
                                backgroundColor: theme.palette.primary.light,
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => navigate(`/jobs/${job._id}`)}
                          >
                            <ListItemText
                              primary={
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography variant="h6">
                                    {job.name}
                                  </Typography>
                                  <Typography variant="caption">
                                    Due Date:{" "}
                                    {job.dueDate
                                      ? new Intl.DateTimeFormat("en-UK", {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                          hour: "numeric",
                                          minute: "numeric",
                                          second: "numeric",
                                        }).format(new Date(job.dueDate))
                                      : "No due date"}
                                  </Typography>
                                </Box>
                              }
                              secondary={job.description}
                              sx={{
                                flex: "4",
                              }}
                            />
                          </ListItem>
                          <Divider />
                        </Box>
                      ))
                    ) : (
                      <Typography variant="h6" sx={{ ml: "1rem", mt: "1rem" }}>
                        You have no jobs due soon.
                      </Typography>
                    )}
                  </List>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
