import React, { useState, useEffect } from "react";
import {
  useTheme,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setMode } from "state";
import Header from "components/Header";
import { useAuth } from "components/auth";
import Spinner from "components/Spinner";
import { useGetUserQuery, usePutUserMutation } from "state/api";
import Cookies from "js-cookie";
import { useSnackbar } from "components/Snackbar";

const Settings = () => {
  const auth = useAuth();
  const userId = auth.user;
  const dispatch = useDispatch();
  const [putUserMutation] = usePutUserMutation();
  const { data, isLoading } = useGetUserQuery(userId);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [editedData, setEditedData] = useState({});
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    if (data) {
      setEditedData(data);
    }
  }, [data]);

  const handleSaveChanges = async () => {
    try {
      await putUserMutation({
        id: userId,
        updatedData: editedData,
      }).then((res) => {
        showSnackbar("Settings updated successfully", "success");
        setIsDataChanged(false);
      });
    } catch (err) {
      showSnackbar("Something went wrong", "error");
    }
  };

  const handleEditChange = (field, value) => {
    if (field === "theme") {
      // Dispatch the setMode action to update theme mode in Redux
      Cookies.set("themeMode", value, { expires: 1 });
      dispatch(setMode(value));
    }

    setEditedData({
      ...editedData,
      settings: {
        ...editedData.settings,
        [field]: value,
      },
    });
    setIsDataChanged(true);
  };

  if (isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px="2rem"
          >
            <Header title={data?.name} subtitle="Settings page" />
            <Box>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{ ml: "2rem" }}
                onClick={handleSaveChanges}
                disabled={!isDataChanged}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Grid container spacing={2} sx={{ mt: "10px", width: "100%" }}>
            <Grid item xs={6}>
              <Paper
                elevation={3}
                sx={{
                  padding: "1rem",
                  bgcolor: theme.palette.background.alt,
                }}
              >
                <Box sx={{ height: "30rem" }}>
                  <Box
                    display="flex"
                    sx={{ padding: "1rem" }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h4">Theme Settings</Typography>
                  </Box>
                  <ListItem
                    sx={{
                      justifyContent: "space-between",
                      padding: "1rem",
                    }}
                  >
                    <ListItemText
                      sx={{ width: "50%" }}
                      primary="Light theme preference"
                    />
                    <ListItemText sx={{ width: "50%" }}>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          value={editedData?.settings?.theme || "light"}
                          onChange={(e) =>
                            handleEditChange("theme", e.target.value)
                          }
                        >
                          <MenuItem value="light">Light</MenuItem>
                          <MenuItem value="dark">Dark</MenuItem>
                        </Select>
                      </FormControl>
                    </ListItemText>
                  </ListItem>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
