import React from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Box, Typography, useTheme, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const QuotesPanel = ({ data, editedData }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const columns = [
    {
      field: "quoteNumber",
      headerName: "Quote ID",
      flex: 0.2,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.2,
    },
    {
      field: "acceptedSpeed",
      headerName: "Accepted Speed",
      flex: 0.2,
    },
    {
      field: "quotedValue",
      headerName: "Quoted Value",
      flex: 0.3,
      renderCell: (params) => {
        let cost = "N/A";
        if (!params.row.acceptedSpeed) {
          cost = params.row.quotedValue ?? "N/A";
        } else if (params.row.acceptedSpeed === "advanced") {
          cost = params.row.quotedValue ?? "N/A";
        } else if (params.row.acceptedSpeed === "faster") {
          cost = params.row.quotedFasterValue ?? "N/A";
        } else if (params.row.acceptedSpeed === "urgent") {
          cost = params.row.quotedUrgentValue ?? "N/A";
        }
        const currency = params.row.quotedCurrency ?? "";
        return `${cost} ${currency}`;
      },
    },
    {
      field: "quoteDate",
      headerName: "Quote Date",
      flex: 0.3,
      valueGetter: (params) => {
        const date = params.row.quoteDate;
        if (!date || !Number.isFinite(new Date(date).getTime())) {
          return "N/A";
        }
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(date));
      },
    },
  ];

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "1rem",
        bgcolor: theme.palette.background.alt,
        mb: "1rem",
      }}
    >
      <Box
        display="flex"
        sx={{ padding: "1rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Quotes</Typography>
      </Box>
      <Box
        height="40vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <Divider />
        <DataGrid
          loading={!editedData || !data}
          getRowId={(row) => row._id}
          rows={editedData?.quotes || []}
          sx={{
            padding: "1rem",
            ".MuiDataGrid-virtualScroller": {
              borderTop: "2px solid rgba(224, 224, 224, 1)",
              overflowX: "hidden",
            },
          }}
          columns={columns}
          rowCount={editedData?.quotes?.length || 0}
          rowsPerPageOptions={[10, 20, 100]}
          pagination
          onRowClick={(e) => {
            navigate("/quotes/" + e.id);
          }}
        />
      </Box>
    </Paper>
  );
};

export default QuotesPanel;
