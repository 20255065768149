import React, { useState } from "react";
import {
  Divider,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  IconButton,
  Typography,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditableContainer from "../EditableContainer";
import { capitalizeFirstLetter } from "../usefulFunctions";
import { useGetLanguagesQuery } from "state/api";

const RatesPanel = ({ data, editedData, handleEditChange, changesMade }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { data: languagesData } = useGetLanguagesQuery();

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  const handleLanguageChange = (index, field, value) => {
    const updatedRates = [...(editedData.rates || [])];
    updatedRates[index] = {
      ...updatedRates[index],
      [field]: value,
    };
    handleEditChange("rates", updatedRates);
  };

  const handleRateChange = (index, field, value) => {
    const updatedRate = [...(editedData.rates || [])];
    updatedRate[index] = {
      ...updatedRate[index],
      [field]: value,
    };
    handleEditChange("rates", updatedRate);
  };

  const handleRemoveRate = (index) => {
    const updatedRate = [...(editedData.rates || [])];
    updatedRate.splice(index, 1);
    handleEditChange("rates", updatedRate);
  };

  const rateToDisplay = editedData.rates || data.rates;

  return (
    <EditableContainer
      title="Rates"
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <Divider />
      {isEditMode ? (
        // Edit Mode: Display and edit rates
        <>
          {editedData &&
            editedData.rates &&
            editedData.rates.map((rate, index) => (
              <Box key={`rate-box-${index}`}>
                <ListItem key={`rate-${index}`}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "calc(100% - 48px)",
                        mr: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <Autocomplete
                          options={languagesData}
                          getOptionLabel={(option) => option.name}
                          value={rate.sourceLanguage || null}
                          onChange={(e, newValue) =>
                            handleLanguageChange(
                              index,
                              "sourceLanguage",
                              newValue
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select source language"
                            />
                          )}
                          sx={{ width: "50%", mr: 2 }}
                        />
                        <Autocomplete
                          options={languagesData}
                          getOptionLabel={(option) => option.name}
                          value={rate.targetLanguage || null}
                          onChange={(e, newValue) =>
                            handleLanguageChange(
                              index,
                              "targetLanguage",
                              newValue
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select target language"
                            />
                          )}
                          sx={{ width: "50%" }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          mt: 1,
                        }}
                      >
                        <ListItemText sx={{ width: "30%" }}>
                          <FormControl sx={{ width: "80%" }}>
                            <InputLabel htmlFor="rates-category">
                              Category
                            </InputLabel>
                            <Select
                              label="Category"
                              value={rate.category || ""}
                              inputProps={{
                                name: "ratesCategory",
                                id: "rates-category",
                              }}
                              onChange={(e) =>
                                handleRateChange(
                                  index,
                                  "category",
                                  e.target.value
                                )
                              }
                            >
                              <MenuItem value="translation">
                                Translation
                              </MenuItem>
                              <MenuItem value="proofread">Proofread</MenuItem>
                              <MenuItem value="formatting">Formatting</MenuItem>
                              <MenuItem value="transcription">
                                Transcription
                              </MenuItem>
                              <MenuItem value="interpreting">
                                Interpreting
                              </MenuItem>
                              <MenuItem value="editing">Editing</MenuItem>
                            </Select>
                          </FormControl>
                        </ListItemText>
                        <ListItemText sx={{ width: "5%" }}>
                          <TextField
                            fullWidth
                            label="Cost"
                            value={rate.cost || ""}
                            onChange={(e) =>
                              handleRateChange(index, "cost", e.target.value)
                            }
                          />
                        </ListItemText>
                        <ListItemText sx={{ width: "20%", ml: "1rem" }}>
                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel htmlFor="rates-unit">Unit</InputLabel>
                            <Select
                              label="Unit"
                              value={rate.unit || ""}
                              inputProps={{
                                name: "ratesUnit",
                                id: "rates-unit",
                              }}
                              onChange={(e) =>
                                handleRateChange(index, "unit", e.target.value)
                              }
                            >
                              <MenuItem value="word">per word</MenuItem>
                              <MenuItem value="hourly">Hourly</MenuItem>
                              <MenuItem value="half day">Half day</MenuItem>
                              <MenuItem value="full day">Full day</MenuItem>
                              <MenuItem value="minimum charge">
                                Minimum charge
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </ListItemText>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flexShrink: 0,
                        alignSelf: "stretch",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemText sx={{ width: "1%", ml: "1rem" }}>
                        <IconButton
                          onClick={() => handleRemoveRate(index)}
                          color="error"
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemText>
                    </Box>
                  </Box>
                </ListItem>
                <Divider />
              </Box>
            ))}
          <ListItem sx={{ justifyContent: "center" }}>
            <Stack>
              {editedData.rates?.length < 1 && (
                <Typography variant="caption">
                  Add your first rate using the button below
                </Typography>
              )}
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "0.5rem" }}
              >
                <IconButton
                  aria-label="add"
                  onClick={() =>
                    handleEditChange("rates", [
                      ...(editedData.rates || []),
                      { category: "", cost: 0, unit: "" },
                    ])
                  }
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Stack>
          </ListItem>
        </>
      ) : (
        // Display Mode: Display rates as text
        <>
          {rateToDisplay && rateToDisplay.length > 0 ? (
            rateToDisplay.map((rate, index) => (
              <Box key={`rate-box-${index}`} sx={{ mb: 2 }}>
                <ListItem>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <ListItemText sx={{ width: "50%" }}>
                        {rate.sourceLanguage?.name}
                      </ListItemText>
                      <ListItemText sx={{ width: "25%" }}>
                        {rate.sourceLanguage?.name && rate.targetLanguage?.name
                          ? "into"
                          : ""}
                      </ListItemText>
                      <ListItemText sx={{ width: "25%" }}>
                        {rate.targetLanguage?.name}
                      </ListItemText>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <ListItemText sx={{ width: "50%" }}>
                        {capitalizeFirstLetter(rate.category)}
                      </ListItemText>
                      <ListItemText sx={{ width: "25%" }}>
                        {rate.cost ? `£${rate.cost}` : ""}
                      </ListItemText>
                      <ListItemText sx={{ width: "25%" }}>
                        {capitalizeFirstLetter(rate.unit)}
                      </ListItemText>
                    </Box>
                  </Box>
                </ListItem>
                <Divider />
              </Box>
            ))
          ) : (
            <ListItem>
              <ListItemText>No rates added</ListItemText>
            </ListItem>
          )}
        </>
      )}
    </EditableContainer>
  );
};

export default RatesPanel;
