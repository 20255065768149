import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getToken = () => localStorage.getItem("jwt");

const herokuUrls = process.env.REACT_APP_BASE_URL.split(",");

const baseQuery = fetchBaseQuery({
  baseUrl:
    process.env.REACT_APP_NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_BASE_URL.split(",")[0],
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("jwt");
    if (token) {
      headers.set("x-access-token", token);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    window.location.href = "/login";
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Jobs",
    "Job",
    "Translators",
    "Translator",
    "Tasks",
    "Admins",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => ({
        url: `api/general/user/${id}`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["User"],
    }),
    getUsers: build.query({
      query: () => ({
        url: "api/general/users",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      providedTags: ["Users"],
    }),
    putUser: build.mutation({
      query: ({ id, updatedData }) => ({
        url: `api/general/user/${id}`,
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
        },
        body: updatedData,
      }),
    }),
    deleteUser: build.mutation({
      query: (id) => ({
        url: `api/general/user/${id}`,
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
        },
      }),
    }),
    getJobs: build.query({
      query: ({ page, pageSize, sort, search, quote, status, filter }) => ({
        url: "api/client/jobs",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { page, pageSize, sort, search, quote, status, filter },
      }),
      providesTags: ["Jobs"],
    }),
    getJob: build.query({
      query: (id) => ({
        url: `api/client/jobs/${id}`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Job"],
    }),
    getRecentJobs: build.query({
      query: () => ({
        url: "api/management/jobs/recent",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getWeeklyJobs: build.query({
      query: () => ({
        url: "api/management/jobs/weekly",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getFiveMostRecentJobs: build.query({
      query: () => ({
        url: "api/management/jobs/recentFive",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getJobCreationData: build.query({
      query: ({ startDate, endDate }) => ({
        url: "api/management/jobs/createData",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { startDate, endDate },
      }),
    }),
    createJob: build.mutation({
      query: ({ clientId } = {}) => {
        const body = clientId ? { clientId } : {};
        return {
          url: "api/client/jobs/create",
          method: "POST",
          withCredentials: true,
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        };
      },
      providesTags: ["Job"],
    }),

    getTranslators: build.query({
      query: ({ page, pageSize, sort, search, pending, filter }) => ({
        url: "api/client/translators",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { page, pageSize, sort, search, pending, filter },
      }),
      providesTags: ["Translators"],
    }),
    getTranslator: build.query({
      query: (id) => ({
        url: `api/client/translators/${id}`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Translator"],
    }),
    getTasks: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "api/client/tasks",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Tasks"],
    }),
    getTask: build.query({
      query: (id) => ({
        url: `api/client/tasks/${id}`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Task"],
    }),
    deleteTask: build.mutation({
      query: (id) => ({
        url: `api/client/tasks/${id}`,
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    putTask: build.mutation({
      query: ({ id, updatedData }) => ({
        url: `api/client/tasks/${id}`,
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: updatedData,
      }),
    }),
    createTask: build.mutation({
      query: (newTaskData) => ({
        url: "api/client/tasks/create",
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: newTaskData,
      }),
    }),
    getAdmins: build.query({
      query: () => ({
        url: "api/management/admins",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Admins"],
    }),
    createAdmin: build.mutation({
      query: (newAdminData) => ({
        url: "api/management/admins/create",
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: newAdminData,
      }),
    }),
    putTranslator: build.mutation({
      query: ({ id, updatedData }) => ({
        url: `api/client/translators/${id}`,
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: updatedData,
      }),
    }),
    deleteTranslator: build.mutation({
      query: (id) => ({
        url: `api/client/translators/${id}`,
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    createTranslator: build.mutation({
      query: (newTranslatorData) => ({
        url: "api/client/translators/create",
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: newTranslatorData,
      }),
    }),
    putJob: build.mutation({
      query: ({ id, updatedData, userId }) => ({
        url: `api/client/jobs/${id}`,
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: { updatedData, userId },
      }),
    }),
    deleteJob: build.mutation({
      query: (id) => ({
        url: `api/client/jobs/${id}`,
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getClients: build.query({
      query: ({ page, pageSize, sort, search, filter }) => ({
        url: "api/client/clients",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { page, pageSize, sort, search, filter },
      }),
      providesTags: ["Clients"],
    }),
    createClient: build.mutation({
      query: () => ({
        url: "api/client/clients/create",
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getClient: build.query({
      query: (id) => ({
        url: `api/client/clients/${id}`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Client"],
    }),
    putClient: build.mutation({
      query: ({ id, updatedData }) => ({
        url: `api/client/clients/${id}`,
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: updatedData,
      }),
    }),
    deleteClient: build.mutation({
      query: (id) => ({
        url: `api/client/clients/${id}`,
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getLanguages: build.query({
      query: () => ({
        url: "api/general/languages",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      }),
    }),
    getAllNotifications: build.query({
      query: ({ id, page, limit }) => ({
        url: "api/notification/",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { id, page, limit },
      }),
    }),
    patchMarkOneNotificationAsRead: build.mutation({
      query: ({ id }) => ({
        url: "api/notification/",
        method: "PATCH",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: { id },
      }),
    }),
    patchMarkAllNotificationsAsRead: build.mutation({
      query: ({ id }) => ({
        url: "api/notification/all",
        method: "PATCH",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: { id },
      }),
    }),
    postGenerateQuote: build.mutation({
      query: ({ clientId, quoteId, jobId, userId, template }) => ({
        url: "api/management/generate/quote",
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: { clientId, quoteId, jobId, userId, template },
      }),
    }),
    createLanguage: build.mutation({
      query: ({ name, code, toEnglish, fromEnglish }) => ({
        url: "api/management/languages/create",
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: { name, code, toEnglish, fromEnglish },
      }),
    }),
    putLanguage: build.mutation({
      query: ({ id, name, code, toEnglish, fromEnglish }) => ({
        url: `api/management/languages/${id}`,
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: { name, code, toEnglish, fromEnglish },
      }),
    }),
    deleteLanguage: build.mutation({
      query: ({ id }) => ({
        url: `api/management/languages/${id}`,
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      }),
    }),
    postVendorSignup: build.mutation({
      query: (newVendorData) => ({
        url: "api/external/vendorsignup",
        method: "POST",
        withCredentials: true,
        credentials: "include",
        body: newVendorData,
      }),
    }),
    downloadVendorFile: build.mutation({
      query: ({ route }) => ({
        url: `api/management/download-file`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        params: { route },
      }),
    }),
    setAllJobTasksToComplete: build.mutation({
      query: ({ jobId }) => ({
        url: `api/client/completeAllTasks`,
        method: "PUT",
        withCredentials: true,
        credentials: "include",

        headers: {
          Accept: "application/json",
        },
        body: { jobId },
      }),
    }),
    getQuote: build.query({
      query: (id) => ({
        url: `api/client/quotes/${id}`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      }),
    }),
    putQuote: build.mutation({
      query: ({ id, updatedData }) => ({
        url: `api/client/quotes/${id}`,
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: { updatedData },
      }),
    }),
    deleteQuote: build.mutation({
      query: (id) => ({
        url: `api/client/quotes/${id}`,
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      }),
    }),
    createQuote: build.mutation({
      query: (newQuoteData) => ({
        url: "api/client/quotes/create",
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: newQuoteData,
      }),
    }),
    getQuotes: build.query({
      query: ({ page, pageSize, sort, search, filter }) => ({
        url: "api/client/quotes",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        params: { page, pageSize, sort, search, filter },
      }),
    }),
    getPublicFile: build.mutation({
      query: ({ route }) => ({
        url: `api/external/public-file`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        params: { route },
      }),
    }),
    getInvoicedJobValues: build.query({
      query: () => ({
        url: `api/management/invoiced-job-values`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
      }),
    }),
    getOrganisations: build.query({
      query: () => ({
        url: "api/admin/organisations",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getOrganisation: build.query({
      query: (id) => ({
        url: `api/admin/organisation/${id}`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    putOrganisation: build.mutation({
      query: ({ id, updatedData }) => ({
        url: `api/admin/organisation/${id}`,
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        body: updatedData,
      }),
    }),
    createOrganisation: build.mutation({
      query: () => ({
        url: "api/admin/organisation/create",
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    adminLogin: build.mutation({
      query: (body) => ({
        url: "api/admin/login",
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      }),
    }),
    orgSignupPassword: build.mutation({
      query: ({ password, orgExtension }) => ({
        url: `api/external/organisation/${orgExtension}/signup-password`,
        method: "POST",
        withCredentials: true,
        credentials: "include",
        body: { password },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    externalGetOrganisation: build.query({
      query: (orgExtension) => ({
        url: `api/external/organisation/${orgExtension}`,
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getQuoteStatistics: build.query({
      query: ({ startDate, endDate }) => ({
        url: "api/management/quotes-statistics",
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { startDate, endDate },
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  usePutUserMutation,
  useDeleteUserMutation,
  useGetJobsQuery,
  useGetJobQuery,
  useGetRecentJobsQuery,
  useGetWeeklyJobsQuery,
  useGetJobCreationDataQuery,
  useGetFiveMostRecentJobsQuery,
  useCreateJobMutation,
  useGetTranslatorsQuery,
  useGetTranslatorQuery,
  useGetTasksQuery,
  useGetTaskQuery,
  useDeleteTaskMutation,
  usePutTaskMutation,
  useCreateTaskMutation,
  useGetAdminsQuery,
  useCreateAdminMutation,
  usePutTranslatorMutation,
  useDeleteTranslatorMutation,
  useCreateTranslatorMutation,
  usePutJobMutation,
  useDeleteJobMutation,
  useGetClientsQuery,
  useCreateClientMutation,
  useGetClientQuery,
  usePutClientMutation,
  useDeleteClientMutation,
  useGetLanguagesQuery,
  useGetAllNotificationsQuery,
  usePatchMarkOneNotificationAsReadMutation,
  usePatchMarkAllNotificationsAsReadMutation,
  usePostGenerateQuoteMutation,
  useCreateLanguageMutation,
  usePutLanguageMutation,
  useDeleteLanguageMutation,
  usePostVendorSignupMutation,
  useDownloadVendorFileMutation,
  useSetAllJobTasksToCompleteMutation,
  useGetQuoteQuery,
  usePutQuoteMutation,
  useDeleteQuoteMutation,
  useCreateQuoteMutation,
  useGetQuotesQuery,
  useGetPublicFileMutation,
  useGetInvoicedJobValuesQuery,
  useGetOrganisationsQuery,
  useGetOrganisationQuery,
  usePutOrganisationMutation,
  useCreateOrganisationMutation,
  useAdminLoginMutation,
  useOrgSignupPasswordMutation,
  useExternalGetOrganisationQuery,
  useGetQuoteStatisticsQuery,
} = api;
