import React, { useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useGetAdminsQuery, useCreateAdminMutation } from "state/api";
import Header from "components/Header";
import ActionsMenu from "components/ActionsMenu";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Spinner from "components/Spinner";
import { useAuth } from "components/auth";
import { useSnackbar } from "components/Snackbar";

const Admins = () => {
  const auth = useAuth();
  const role = auth.role;
  const navigate = useNavigate();
  const theme = useTheme();
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { data, isLoading } = useGetAdminsQuery();
  const [openDialog, setOpenDialog] = useState(false);
  const [createAdminMutation] = useCreateAdminMutation();
  const [newAdminData, setNewAdminData] = useState({
    name: "",
    email: "",
  });

  const handleDialogConfirm = async () => {
    try {
      await createAdminMutation({
        name: newAdminData.name,
        email: newAdminData.email,
      }).then((res) => {
        if (res.data.adminId) {
          navigate("/admins/" + res.data.adminId);
        }
      });

      setOpenDialog(false);
    } catch (error) {
      console.error("Error creating admin:", error);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const actions = [
    {
      label: "Create New Admin",
      onClick: () => {
        if (role !== "superadmin") {
          showSnackbar("You do not have permission to create admins.", "error");
          return;
        }
        setOpenDialog(true);
      },
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 0.5,
      renderCell: (params) => {
        if (params.value) {
          return params.value.replace(/^(\d{2})(\d{3})(\d{3})/, "$1 $2 $3");
        }
      },
    },
    {
      field: "city",
      headerName: "City",
      flex: 0.4,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.4,
    },
  ];

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title="Admins" subtitle="List of admins" />
        <ActionsMenu actions={actions} />
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="create-dialog-title"
        aria-describedby="create-dialog-description"
      >
        <Box
          sx={{
            margin: "1rem",
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "1.5rem",
              paddingBottom: "2rem",
            }}
          >
            Create New Admin
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="confrim-dialog-description"
              sx={{
                marginBottom: "1rem",
              }}
            >
              Enter the admin's name and email address. Their password will be
              sent to them via email.
            </DialogContentText>
            <TextField
              label="Name"
              sx={{
                marginBottom: "1rem",
              }}
              fullWidth
              value={newAdminData.name}
              onChange={(e) =>
                setNewAdminData({
                  ...newAdminData,
                  name: e.target.value,
                })
              }
            />
            <TextField
              label="Email"
              sx={{
                marginBottom: "1rem",
              }}
              fullWidth
              value={newAdminData.email}
              onChange={(e) =>
                setNewAdminData({
                  ...newAdminData,
                  email: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDialogConfirm();
              }}
              color="success"
            >
              Create
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          sx={{
            padding: "1rem",
            backgroundColor: theme.palette.background.alt,
            ".MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              borderTop: "2px solid rgba(224, 224, 224, 1)",
              overflowX: "hidden",
            },
          }}
          rows={data?.admins || []}
          columns={columns}
          onRowClick={(e) => {
            if (role !== "superadmin" && e.id !== auth.user) {
              showSnackbar(
                "You do not have permission to view admins.",
                "error"
              );
              return;
            }
            navigate("/admins/" + e.id);
          }}
        />
      </Box>
    </Box>
  );
};

export default Admins;
