import React, { useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import { useDownloadVendorFileMutation } from "state/api";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { capitalizeFirstLetter } from "components/usefulFunctions";
import CurrencySelector from "../CurrencySelector";
import EditableContainer from "../EditableContainer";
import IncludeTax from "../IncludeTax";

const QuoteDetailPanel = ({
  data,
  editedData,
  handleEditChange,
  changesMade,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [downloadVendorFile] = useDownloadVendorFileMutation();

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  const handleFileDownload = async (route, filename) => {
    try {
      const {
        data: { signedUrl },
      } = await downloadVendorFile({ route });

      // Use the signed URL to initiate the file download
      const link = document.createElement("a");
      link.href = signedUrl;
      link.target = "_blank";
      link.download = filename;
      link.click();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <EditableContainer
      title={"Quote Details"}
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <List>
        <Divider />
        <ListItem key="id">
          <ListItemText primary={"Quote ID"} sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                size="small"
                sx={{ width: "100%" }}
                value={editedData.quoteNumber || ""}
                onChange={(e) =>
                  handleEditChange("quoteNumber", e.target.value)
                }
              />
            ) : (
              editedData.quoteNumber || ""
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="quoteSent">
          <ListItemText primary="Quote Sent Date" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <DatePicker
                    value={
                      editedData.quoteDate ? dayjs(editedData.quoteDate) : null
                    }
                    onChange={(newDate) =>
                      handleEditChange(
                        "quoteDate",
                        newDate ? newDate.toISOString() : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TimePicker
                    size="small"
                    value={
                      editedData.quoteDate ? dayjs(editedData.quoteDate) : null
                    }
                    onChange={(newTime) =>
                      handleEditChange(
                        "quoteDate",
                        newTime
                          ? dayjs(editedData.quoteDate)
                              .set("hour", newTime.hour())
                              .set("minute", newTime.minute())
                              .toISOString()
                          : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            ) : (
              editedData.quoteDate &&
              new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZoneName: "short",
              }).format(new Date(editedData.quoteDate))
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="status">
          <ListItemText primary="Quote Status" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <FormControl size="small" fullWidth>
                <Select
                  value={editedData.status || ""}
                  onChange={(e) => handleEditChange("status", e.target.value)}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="accepted">Accepted</MenuItem>
                  <MenuItem value="declined">Declined</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
            ) : (
              capitalizeFirstLetter(editedData.status ?? "")
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="acceptedSpeed">
          <ListItemText primary="Accepted Speed" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <FormControl size="small" fullWidth>
                <Select
                  value={editedData.acceptedSpeed || ""}
                  onChange={(e) =>
                    handleEditChange("acceptedSpeed", e.target.value)
                  }
                >
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="advanced">Advanced</MenuItem>
                  <MenuItem value="faster">Faster</MenuItem>
                  <MenuItem value="urgent">Urgent</MenuItem>
                </Select>
              </FormControl>
            ) : (
              capitalizeFirstLetter(editedData.acceptedSpeed ?? "")
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="cancellationReason">
          <ListItemText primary="Cancellation Reason" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <FormControl size="small" fullWidth>
                <Select
                  value={editedData.cancellationReason || ""}
                  onChange={(e) =>
                    handleEditChange("cancellationReason", e.target.value)
                  }
                >
                  <MenuItem value="price">Price</MenuItem>
                  <MenuItem value="speed">Speed</MenuItem>
                  <MenuItem value="service">Service</MenuItem>
                  <MenuItem value="quality">Quality</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            ) : (
              capitalizeFirstLetter(editedData.cancellationReason ?? "")
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="quoteDocument">
          <ListItemText primary="Quote Document" sx={{ width: "50%" }} />
          <ListItemText
            onClick={() =>
              handleFileDownload(
                editedData.quoteDocument?.documentUrl,
                editedData.quoteDocument?.documentName
              )
            }
            sx={{ width: "50%" }}
          >
            <a href="#" target="_blank">
              {editedData.quoteDocument?.documentName ?? ""}
            </a>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="quoteDateUpdated">
          <ListItemText primary="Quote Date Updated" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {editedData.quoteDocument?.dateUpdated &&
              new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZoneName: "short",
              }).format(new Date(editedData.quoteDocument?.dateUpdated))}
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
    </EditableContainer>
  );
};

export default QuoteDetailPanel;
