import React, { useState } from "react";
import {
  useTheme,
  Box,
  Switch,
  FormControlLabel,
  IconButton,
  Popover,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ColumnEditor = ({
  columns,
  columnsVisibility,
  toggleColumnVisibility,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "column-editor-popover" : undefined;

  return (
    <Box>
      <IconButton
        sx={{
          color: theme.palette.secondary[200],
        }}
        aria-describedby={id}
        variant="outline"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2}>
          <Grid container direction="column">
            {columns.map((column, index) => (
              <Grid item key={column.field}>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={columnsVisibility[column.field]}
                      onChange={() => toggleColumnVisibility(column.field)}
                    />
                  }
                  label={column.headerName}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
};

export default ColumnEditor;
