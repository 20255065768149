import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
} from "@mui/material";
import languages from "languages";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

const LanguagesSection = ({ formData, handleChange, errors, languagesRef }) => {
  const [newLanguage, setNewLanguage] = useState({
    sourceLanguages: [],
    targetLanguage: null,
  });

  // Create an array of language options using the "languages" package
  const langscodes = languages.getAllLanguageCode();
  const languageOptions = langscodes.map((langcode) => {
    const languageInfo = languages.getLanguageInfo(langcode);
    return languageInfo.name;
  });

  const handleAddLanguage = () => {
    if (newLanguage.sourceLanguages.length > 0 && newLanguage.targetLanguage) {
      const updatedLanguages = [...(formData.languages || []), newLanguage];
      handleChange(updatedLanguages, "languages");
      setNewLanguage({
        sourceLanguages: [],
        targetLanguage: null,
      });
    }
  };

  const handleRemoveLanguage = (index) => {
    const newLanguages = [...(formData.languages || [])];
    newLanguages.splice(index, 1);
    handleChange(newLanguages, "languages");
  };

  const handleEditLanguage = (index) => {
    const editedLanguages = [...(formData.languages || [])];

    // Create a shallow copy of the object
    const editedLanguage = { ...editedLanguages[index] };
    editedLanguage.isEditing = true;
    editedLanguages[index] = editedLanguage;
    handleChange(editedLanguages, "languages");
  };

  const handleSaveEditedLanguage = (index) => {
    const editedLanguages = [...(formData.languages || [])];
    // Remove the "isEditing" property
    delete editedLanguages[index].isEditing;
    handleChange(editedLanguages, "languages");
  };

  return (
    <>
      <Grid ref={languagesRef} item xs={12} sm={12} sx={{ mt: "3rem" }}>
        <Divider sx={{ my: 2, mb: "3rem" }} />
        <Typography variant="h6" gutterBottom>
          Language Pairs
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please select the language pairs applicable to your services, and use
          the Add button to add additional pairs. You can edit or remove
          language pairs from the list as needed.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <List>
          {formData.languages.length > 1 &&
            formData.languages?.slice(0, -1).map((language, index) => (
              <div key={`language-${index}`}>
                {language.isEditing ? (
                  <ListItem sx={{ px: 0 }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Autocomplete
                        multiple
                        sx={{ width: "40%" }}
                        options={languageOptions}
                        size="large"
                        value={language.sourceLanguages || []}
                        onChange={(e, newValue) => {
                          const updatedLanguage = {
                            ...language,
                            sourceLanguages: newValue,
                          };
                          const updatedLanguages = [...formData.languages];
                          updatedLanguages[index] = updatedLanguage;
                          handleChange(updatedLanguages, "languages");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select source languages"
                          />
                        )}
                      />
                      <Autocomplete
                        options={languageOptions}
                        size="large"
                        sx={{ width: "40%" }}
                        value={language.targetLanguage || null}
                        onChange={(e, newValue) => {
                          const updatedLanguage = {
                            ...language,
                            targetLanguage: newValue,
                          };
                          const updatedLanguages = [...formData.languages];
                          updatedLanguages[index] = updatedLanguage;
                          handleChange(updatedLanguages, "languages");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select target language"
                          />
                        )}
                      />
                      <IconButton
                        color="secondary"
                        aria-label="save"
                        onClick={() => handleSaveEditedLanguage(index)}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                ) : (
                  <ListItem sx={{ px: 0 }}>
                    <ListItemText sx={{ width: "50%", mr: "2rem" }}>
                      {language.sourceLanguages
                        .map((sourceLanguage) => sourceLanguage)
                        .join(", ")}
                    </ListItemText>
                    <ListItemText sx={{ width: "20%" }}>into</ListItemText>
                    <ListItemText sx={{ width: "30%" }}>
                      {language.targetLanguage}
                    </ListItemText>
                    <IconButton
                      color="secondary"
                      aria-label="edit"
                      onClick={() => handleEditLanguage(index)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() => handleRemoveLanguage(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                )}
              </div>
            ))}
          <div>
            <ListItem sx={{ px: 0 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Autocomplete
                  multiple
                  sx={{ width: "48%" }}
                  options={languageOptions}
                  size="large"
                  value={newLanguage.sourceLanguages || []}
                  onChange={(e, newValue) => {
                    setNewLanguage({
                      ...newLanguage,
                      sourceLanguages: newValue,
                    });
                    handleChange(
                      [
                        ...formData.languages.slice(0, -1),
                        {
                          ...formData.languages.slice(-1)[0],
                          sourceLanguages: newValue,
                        },
                      ],
                      "languages"
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select source languages"
                      error={errors.languages}
                    />
                  )}
                />
                <Autocomplete
                  options={languageOptions}
                  size="large"
                  sx={{ width: "48%" }}
                  value={newLanguage.targetLanguage || null}
                  onChange={(e, newValue) => {
                    setNewLanguage({
                      ...newLanguage,
                      targetLanguage: newValue,
                    });
                    // handleChange modifies the last item in the array
                    handleChange(
                      [
                        ...formData.languages.slice(0, -1),
                        {
                          ...formData.languages.slice(-1)[0],
                          targetLanguage: newValue,
                        },
                      ],
                      "languages"
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select target language"
                      error={errors.languages}
                    />
                  )}
                />
              </Box>
            </ListItem>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "1rem",
              }}
            >
              <IconButton
                color="secondary"
                aria-label="add"
                onClick={handleAddLanguage}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </div>
        </List>
      </Grid>
    </>
  );
};

export default LanguagesSection;
