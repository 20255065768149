import React, { useState } from "react";
import {
  Divider,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  IconButton,
  Typography,
  InputLabel,
} from "@mui/material";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditableContainer from "../EditableContainer";
import { capitalizeFirstLetter } from "../usefulFunctions";
import { useGetUsersQuery } from "state/api";

const TimeTrackingPanel = ({
  data,
  editedData,
  handleEditChange,
  changesMade,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { data: users } = useGetUsersQuery();

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  const handleEntryChange = (index, field, value) => {
    const updatedTimeTracking = [...(editedData.timeTracking || [])];
    updatedTimeTracking[index] = {
      ...updatedTimeTracking[index],
      [field]: value,
    };
    handleEditChange("timeTracking", updatedTimeTracking);
  };

  const handleRemoveEntry = (index) => {
    const updatedTimeTracking = [...(editedData.timeTracking || [])];
    updatedTimeTracking.splice(index, 1);
    handleEditChange("timeTracking", updatedTimeTracking);
  };

  const timeTrackingToDisplay = editedData?.timeTracking || data?.timeTracking;

  return (
    <EditableContainer
      title="Internal Time Tracking"
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <Divider />
      {isEditMode ? (
        // Edit Mode: Display and edit timeTracking entries
        <>
          {editedData &&
            editedData.timeTracking &&
            editedData.timeTracking.map((entry, index) => (
              <Box key={`entry-box-${index}`}>
                <ListItem key={`entry-${index}`}>
                  <ListItemText sx={{ width: "50%" }}>
                    <FormControl sx={{ width: "60%" }}>
                      <InputLabel htmlFor="assigned-user">
                        Managing User
                      </InputLabel>
                      <Select
                        label="Managing User"
                        value={entry.userId || ""}
                        inputProps={{
                          name: "assigned-user",
                          id: "assigned-user",
                        }}
                        onChange={(e) => {
                          handleEntryChange(index, "userId", e.target.value);
                        }}
                      >
                        {users &&
                          users.map((user) => (
                            <MenuItem key={user._id} value={user._id}>
                              {user.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </ListItemText>
                  <ListItemText sx={{ width: "50%" }}>
                    {/* Start Date/Time */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <DatePicker
                          size="small"
                          label="Start Date"
                          value={
                            entry.startDate ? dayjs(entry.startDate) : null
                          }
                          onChange={(newDate) =>
                            handleEntryChange(
                              index,
                              "startDate",
                              newDate ? newDate.toISOString() : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <TimePicker
                          size="small"
                          label="Start Time"
                          value={
                            entry.startDate ? dayjs(entry.startDate) : null
                          }
                          onChange={(newTime) =>
                            handleEntryChange(
                              index,
                              "startDate",
                              newTime
                                ? dayjs(entry.startDate)
                                    .set("hour", newTime.hour())
                                    .set("minute", newTime.minute())
                                    .toISOString()
                                : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        mt={2}
                      >
                        <DatePicker
                          size="small"
                          label="End Date"
                          value={entry.endDate ? dayjs(entry.endDate) : null}
                          onChange={(newDate) =>
                            handleEntryChange(
                              index,
                              "endDate",
                              newDate ? newDate.toISOString() : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <TimePicker
                          size="small"
                          label="End Time"
                          value={entry.endDate ? dayjs(entry.endDate) : null}
                          onChange={(newTime) =>
                            handleEntryChange(
                              index,
                              "endDate",
                              newTime
                                ? dayjs(entry.endDate)
                                    .set("hour", newTime.hour())
                                    .set("minute", newTime.minute())
                                    .toISOString()
                                : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </ListItemText>
                  <ListItemText sx={{ ml: "1rem" }}>
                    <IconButton
                      onClick={() => handleRemoveEntry(index)}
                      color="error"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemText>
                </ListItem>
                <Divider />
              </Box>
            ))}
          <ListItem sx={{ justifyContent: "center" }}>
            <Stack>
              {editedData.timeTracking &&
                editedData.timeTracking.length < 1 && (
                  <Typography variant="caption">
                    Add your first entry using the button below
                  </Typography>
                )}
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "0.5rem" }}
              >
                <IconButton
                  aria-label="add"
                  onClick={() =>
                    handleEditChange("timeTracking", [
                      ...(editedData.timeTracking || []),
                      { name: "", endDate: null, startDate: null },
                    ])
                  }
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Stack>
          </ListItem>
        </>
      ) : (
        // Display Mode: Display timeTracking entries as text
        <>
          {timeTrackingToDisplay && timeTrackingToDisplay.length > 0 ? (
            timeTrackingToDisplay.map((entry, index) => (
              <Box key={`entry-box-${index}`}>
                <ListItem key={`entry-${index}`}>
                  <ListItemText sx={{ width: "20%" }}>
                    <Typography variant="body1">
                      {users &&
                        users.find((user) => user._id === entry.userId) &&
                        users.find((user) => user._id === entry.userId).name}
                    </Typography>
                  </ListItemText>
                  <ListItemText sx={{ width: "15%" }}>
                    <Typography variant="body1">Start Date :</Typography>
                    <Typography variant="body1" sx={{ mt: ".5rem" }}>
                      End Date :
                    </Typography>
                  </ListItemText>
                  <ListItemText sx={{ width: "15%" }}>
                    {/* Start Date/Time */}
                    <Typography variant="body1">
                      {entry.startDate
                        ? new Intl.DateTimeFormat("en-UK", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(entry.startDate))
                        : "-"}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: ".5rem" }}>
                      {entry.endDate
                        ? new Intl.DateTimeFormat("en-UK", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(entry.endDate))
                        : "-"}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <Divider />
              </Box>
            ))
          ) : (
            <ListItem>
              <ListItemText>No entries added</ListItemText>
            </ListItem>
          )}
        </>
      )}
    </EditableContainer>
  );
};

export default TimeTrackingPanel;
