import React from "react";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useGetJobCreationDataQuery } from "state/api";
import JobCreationChart from "../../components/JobCreationChart";
import { useTheme, Box, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Header from "../../components/Header";

const Overview = () => {
  const theme = useTheme();
  const [yMax, setYMax] = useState(0);
  const [startDate, setStartDate] = useState(dayjs().subtract(128, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [isEndDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [seriesData, setSeriesData] = useState([]);
  const { data: creationData, isLoading: isLoadingCreationData } =
    useGetJobCreationDataQuery({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });

  useEffect(() => {
    if (creationData && creationData.data.length > 0) {
      // Prepare job and quote data series
      const jobSeries = {
        id: "Jobs",
        data: creationData.data.map((week) => ({
          x: week.x,
          y: week.jobs,
        })),
      };

      const quoteSeries = {
        id: "Quotes",
        data: creationData.data.map((week) => ({
          x: week.x,
          y: week.quotes,
        })),
      };

      const formattedData = [jobSeries, quoteSeries];

      // Set state for chart data
      setSeriesData(formattedData);

      // Calculate max Y value for yScale max in the chart
      const maxYValue = Math.max(
        ...creationData.data.flatMap((week) => [week.jobs, week.quotes])
      );
      setYMax(maxYValue);
    }
  }, [creationData]);

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
    setEndDatePickerOpen(true);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
    setEndDatePickerOpen(false);
  };

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header
          title="Overview"
          subtitle="Use the options below to filter the data."
        />
      </Box>
      <Box
        px="2rem"
        sx={{
          height: "5vh",
          pt: "3rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start date"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <Box sx={{ mx: 2, display: "flex", alignItems: "center" }}>-</Box>
          <DatePicker
            label="End date"
            value={endDate}
            open={isEndDatePickerOpen}
            onOpen={() => setEndDatePickerOpen(true)}
            onClose={() => setEndDatePickerOpen(false)}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{ mt: "2rem", height: "70vh" }}>
        <JobCreationChart
          isDashboard={false}
          seriesData={seriesData}
          isLoadingJobCreationData={isLoadingCreationData}
          yMax={yMax}
          theme={theme}
        />
      </Box>
    </Box>
  );
};

export default Overview;
