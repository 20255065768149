import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useTheme,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Box,
  Typography,
  Button,
  List,
} from "@mui/material";

const ClientDetailsPanel = ({ data, editedData }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "1rem",
        bgcolor: theme.palette.background.alt,
        marginBottom: "1rem",
      }}
    >
      <Box
        display="flex"
        sx={{ padding: "1rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Client Contact Details</Typography>
        <Button
          variant="outlined"
          disabled={!editedData.job?.clientId?._id}
          sx={{
            color: theme.palette.secondary[300],
            borderColor: theme.palette.secondary[300],
          }}
          onClick={() =>
            navigate(
              `/clients/${
                editedData.job?.clientId?._id ?? data.job?.clientId?._id
              }`
            )
          }
        >
          View Client
        </Button>
      </Box>
      <List>
        <ListItem key="businessName">
          <ListItemText primary="Client Business Name" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {editedData?.job?.clientId?.businessName ??
              data.clientId?.businessName ??
              ""}
          </ListItemText>
        </ListItem>
        <Divider />
        {/*
          <ListItem key="referenceNumber">
            <ListItemText
              primary="Client Reference Number"
              sx={{ width: "50%" }}
            />
            <ListItemText sx={{ width: "50%" }}>
              {editedData?.job?.clientId?.referenceNumber ??
                data.clientId?.referenceNumber ??
                ""}
            </ListItemText>
          </ListItem>
          <Divider />
        */}
        <ListItem key="name">
          <ListItemText
            primary="Preferred Contact Name"
            sx={{ width: "50%" }}
          />
          <ListItemText sx={{ width: "50%" }}>
            {editedData?.job?.preferredContact?.name ?? ""}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="email">
          <ListItemText
            primary="Preferred Contact Email"
            sx={{ width: "50%" }}
          />
          <ListItemText sx={{ width: "50%" }}>
            <a
              href={`mailto:${
                editedData?.job?.preferredContact?.email ?? ""
              }?subject=Job%20Reference%20${editedData?.job?.name ?? ""}`}
            >
              {editedData?.job?.preferredContact?.email ?? ""}
            </a>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="phone">
          <ListItemText
            primary="Preferred Contact Phone"
            sx={{ width: "50%" }}
          />
          <ListItemText sx={{ width: "50%" }}>
            {editedData?.job?.preferredContact?.phoneNumber ?? ""}
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
    </Paper>
  );
};

export default ClientDetailsPanel;
