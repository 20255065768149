// JobCreationChart.js
import React from "react";
import { Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import Spinner from "./Spinner";

const getTickValues = (maxValue) => {
  let increment;
  if (maxValue > 25) {
    increment = 5;
  } else if (maxValue > 10) {
    increment = 2;
  } else {
    increment = 1;
  }

  const tickValues = Array.from(
    { length: Math.ceil(maxValue / increment) + 1 },
    (_, i) => i * increment
  );
  return tickValues;
};

const JobCreationChart = ({
  isDashboard,
  seriesData,
  isLoadingJobCreationData,
  yMax,
  theme,
}) => {
  const yAxisTickValues = getTickValues(yMax);
  if (isLoadingJobCreationData) return <Spinner />;

  // Ensure a minimum yMax of 5
  yMax = Math.max(yMax, 5);

  // Calculate date range and determine tick interval
  const dates = seriesData?.flatMap((data) =>
    data.data.map((point) => new Date(point.x))
  );
  const minDate = dates && new Date(Math.min.apply(null, dates));
  const maxDate = dates && new Date(Math.max.apply(null, dates));
  const dayDifference =
    dates && Math.ceil((maxDate - minDate) / (1000 * 60 * 60 * 24));

  let tickValues;
  if (dayDifference <= 30) {
    // For up to a month of data, show ticks every day
    tickValues = "every 1 day";
  } else if (dayDifference <= 180) {
    // For up to six months of data, show ticks every week
    tickValues = "every 1 week";
  } else {
    // For more than six months of data, show ticks every month
    tickValues = "every 1 month";
  }

  return seriesData && seriesData.length > 0 ? (
    <ResponsiveLine
      data={seriesData}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: theme.palette.secondary[200],
            },
          },
          legend: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          ticks: {
            line: {
              stroke: theme.palette.secondary[200],
              strokeWidth: 1,
            },
            text: {
              fill: theme.palette.secondary[200],
            },
          },
        },
        legends: {
          text: {
            fill: theme.palette.secondary[200],
          },
        },
        tooltip: {
          container: {
            color: theme.palette.primary.main,
          },
        },
      }}
      margin={{ top: 20, right: 30, bottom: 45, left: 40 }}
      xScale={{
        type: "time",
        format: "%Y-%m-%dT%H:%M:%S.%LZ",
        precision: "day",
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: "linear",
        min: "0",
        max: yMax > 0 ? yMax : "auto",
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: "%b %d",
        tickValues: isDashboard ? "every 1 week" : tickValues,
        legend: "Time",
        legendOffset: 35,
        legendPosition: "middle",
      }}
      axisLeft={{
        legend: "New Jobs",
        legendOffset: -30,
        legendPosition: "middle",
        tickValues: yAxisTickValues,
      }}
      enableGridX={false}
      enableGridY={isDashboard ? false : true}
      gridYValues={yMax > 0 ? yMax : 5}
      colors={{ scheme: "category10" }}
      legends={[
        {
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 0,
          translateY: 0,
          itemsSpacing: 2,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  ) : (
    <Typography variant="body1" sx={{ ml: "1rem" }}>
      No data available
    </Typography>
  );
};

export default JobCreationChart;
