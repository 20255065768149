import React from "react";
import { Checkbox, FormControlLabel, useTheme } from "@mui/material";

const IncludeTax = ({ editedData, handleEditChange }) => {
  const theme = useTheme();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={editedData.plusVat ?? false}
          sx={{
            "& .MuiSvgIcon-root": {
              color: theme.palette.secondary.main,
            },
          }}
          onChange={(e) => handleEditChange("plusVat", e.target.checked)}
          name="plusVat"
          color="primary"
        />
      }
      label="Plus VAT"
      labelPlacement="top"
      sx={{
        width: "30%",
        "& .MuiFormControlLabel-label": {
          color: "#00000099",
          fontSize: "0.6rem",
        },
        "& .MuiButtonBase-root": {
          paddingTop: "0 !important",
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        },
      }}
    />
  );
};

export default IncludeTax;
