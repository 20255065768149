import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { useGetTranslatorsQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Spinner from "components/Spinner";
import { capitalizeFirstLetter } from "components/usefulFunctions";

const PendingTranslators = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filterModel, setFilterModel] = useState({ items: [] });
  const { data, isLoading, refetch } = useGetTranslatorsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    pending: true,
    filter: JSON.stringify(filterModel.items),
  });
  const [columnsVisibility, setColumnsVisibility] = useState({
    name: true,
    email: true,
    languages: true,
    targetLanguages: true,
    specialty: true,
    qualifications: true,
    country: true,
    trusted: true,
  });

  useEffect(() => {
    refetch(); // eslint-disable-next-line
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.8,
    },
    {
      field: "languages",
      headerName: "Source Languages",
      flex: 1,
      renderCell: (params) => {
        const allLanguages = params.row.languages;
        if (allLanguages) {
          const sourceLanguages = allLanguages
            .map((language) =>
              language.sourceLanguages
                .map((sourceLanguage) => sourceLanguage.name)
                .join(", ")
            )
            .join(", ");

          return <Box>{sourceLanguages}</Box>;
        }
      },
    },
    {
      field: "targetLanguages",
      headerName: "Target Languages",
      flex: 1,
      renderCell: (params) => {
        const allLanguages = [...params.row.languages];
        if (allLanguages) {
          const targetLanguages = allLanguages
            .map((language) => language?.targetLanguage?.name)
            .join(", ");

          return <Box>{targetLanguages}</Box>;
        }
      },
    },
    {
      field: "specialty",
      headerName: "Specialty",
      flex: 0.7,
      renderCell: (params) => {
        const specialties = params.value || [];
        const specialtyList = specialties.join(", ");

        return <Box>{capitalizeFirstLetter(specialtyList)}</Box>;
      },
    },
    {
      field: "qualifications",
      headerName: "Qualifications",
      flex: 0.7,
      renderCell: (params) => {
        const qualifications = params.row.qualifications || [];
        const qualificationList = qualifications
          .map((qualification) => qualification.type)
          .join(", ");

        return <Box>{capitalizeFirstLetter(qualificationList)}</Box>;
      },
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.5,
    },
    {
      field: "trusted",
      headerName: "Trusted",
      flex: 0.2,
      renderCell: (params) => {
        const trusted = params.value || false;
        return <Box>{trusted ? "Yes" : "No"}</Box>;
      },
    },
  ];

  const toggleColumnVisibility = (field) => {
    setColumnsVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const getColumns = () => {
    return columns.map((column) => ({
      ...column,
      hide: !columnsVisibility[column.field],
    }));
  };

  // Function to update filterModel, passed down to DataGridCustomToolbar
  const handleFilterModelChange = (newModel) => {
    setFilterModel(newModel);
  };

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header
          title="Pending Vendors"
          subtitle="List of pending vendors who have registered online"
        />
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data.vendors || []}
          sx={{
            padding: "1rem",
            backgroundColor: theme.palette.background.alt,
            ".MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              borderTop: "2px solid rgba(224, 224, 224, 1)",
              overflowX: "hidden",
            },
          }}
          columns={getColumns()}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setSearch,
              columns,
              columnsVisibility,
              toggleColumnVisibility,
              onFilterModelChange: handleFilterModelChange,
            },
          }}
          onRowClick={(e) => {
            navigate("/pending-vendors/" + e.id);
          }}
        />
      </Box>
    </Box>
  );
};

export default PendingTranslators;
