import { useState, createContext, useContext, useEffect } from "react";
import React from "react";
import { io } from "socket.io-client";
const AuthContext = createContext(null);
const herokuUrls = process.env.REACT_APP_BASE_URL.split(",");
const socket = io(
  process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_URL
    : herokuUrls[0]
);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [notificationsLength, setNotificationsLength] = useState(0);

  const login = (user, role) => {
    setUser(user);
    setRole(role);
    socket.emit("setUserId", user);
    socket.emit("getNotificationsLength", user);
  };

  const logout = () => {
    socket.emit("disconnect");
    setUser(null);
    setRole(null);
  };

  useEffect(() => {
    // Listen for notificationsLength updates from the server
    socket.on("notificationsLength", (length) => {
      setNotificationsLength(length);
    });
    socket.on("newNotification", (notification) => {
      try {
        console.log("New notification received", notification);
        setNotificationsLength((prev) => prev + 1);
      } catch (error) {
        console.error("Error handling new notification:", error);
      }
    });

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off("newNotification");
      socket.off("notificationsLength");
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, role, login, logout, notificationsLength }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
