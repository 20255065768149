import React, { useState, useEffect, useRef } from "react";
import {
  useTheme,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  ListItem,
  ListItemText,
  Divider,
  TextField,
} from "@mui/material";
import {
  useGetOrganisationQuery,
  usePutOrganisationMutation,
  useGetUserQuery,
} from "state/api";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import Header from "components/Header";
import { useAuth } from "components/auth";
import Spinner from "components/Spinner";
import { useSnackbar } from "components/Snackbar";

const Oranisation = () => {
  const auth = useAuth();
  const theme = useTheme();
  const logoInputRef = useRef(null);
  const termsAndConditionsInputRef = useRef(null);
  const privacyPolicyInputRef = useRef(null);
  const { data: userData, isLoading: isLoadingUser } = useGetUserQuery(
    auth?.user
  );
  const [putOrganisationMutation] = usePutOrganisationMutation();
  const [editedData, setEditedData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [logoUrl, setLogoUrl] = useState(null);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState(null);
  const [termsConditionsUrl, setTermsConditionsUrl] = useState(null);

  const organisationId = userData?.organisation;
  const {
    data: organisationDetails,
    error: organisationError,
    isLoading: isLoadingOrganisation,
  } = useGetOrganisationQuery(organisationId, {
    skip: !organisationId,
  });

  useEffect(() => {
    if (organisationDetails) {
      setEditedData(organisationDetails);
    }
  }, [organisationDetails]);

  const handleSaveChanges = async () => {
    const formData = new FormData();

    Object.keys(editedData).forEach((key) => {
      if (
        key !== "logo" &&
        key !== "notes" &&
        key !== "privacyPolicy" &&
        key !== "termsAndConditions"
      ) {
        formData.append(key, editedData[key]);
      }
    });

    if (editedData.notes && editedData.notes.length > 0) {
      formData.append("notes", JSON.stringify(editedData.notes));
    }

    if (editedData.logo && editedData.logo instanceof File) {
      formData.append("logo", editedData.logo);
    }

    if (editedData.privacyPolicy && editedData.privacyPolicy instanceof File) {
      formData.append("privacyPolicy", editedData.privacyPolicy);
    }

    if (
      editedData.termsAndConditions &&
      editedData.termsAndConditions instanceof File
    ) {
      formData.append("termsAndConditions", editedData.termsAndConditions);
    }

    try {
      await putOrganisationMutation({
        id: organisationId,
        updatedData: formData,
      }).then((res) => {
        showSnackbar("Organisation details updated successfully", "success");
      });

      setIsDataChanged(false);
    } catch (error) {
      showSnackbar("Error updating organisation details", "error");
    }
  };

  useEffect(() => {
    if (organisationDetails?.logo) {
      setLogoUrl(
        process.env.REACT_APP_PUBLIC_GCLOUD_URL +
          organisationDetails.logo?.route
      );
    }
  }, [organisationDetails]);

  useEffect(() => {
    if (editedData.privacyPolicy?.route) {
      setPrivacyPolicyUrl(
        process.env.REACT_APP_PUBLIC_GCLOUD_URL + editedData.privacyPolicy.route
      );
    }
  }, [editedData.privacyPolicy]);

  useEffect(() => {
    if (editedData.termsAndConditions?.route) {
      setTermsConditionsUrl(
        process.env.REACT_APP_PUBLIC_GCLOUD_URL +
          editedData.termsAndConditions.route
      );
    }
  }, [editedData.termsAndConditions]);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
    if (!isEditMode) {
      setIsDataChanged(true);
    }
  };

  const handleEditChange = (field, value) => {
    setIsDataChanged(true);
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const renderDetailItem = (label, field, value) => {
    return (
      <Box>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ListItemText sx={{ width: "30%", my: "auto" }}>{label}</ListItemText>
          <ListItemText sx={{ width: "70%" }}>
            {isEditMode ? (
              <TextField
                fullWidth
                size="small"
                sx={{ width: "100%" }}
                value={value || ""}
                onChange={(e) => handleEditChange(field, e.target.value)}
                disabled={field === "plan" || field === "status"}
              />
            ) : (
              value
            )}
          </ListItemText>
        </ListItem>
        <Divider />
      </Box>
    );
  };

  const renderFileItem = (label, field, value) => {
    // Determine the URL based on the field
    const fileUrl =
      field === "privacyPolicy"
        ? privacyPolicyUrl
        : field === "termsAndConditions"
        ? termsConditionsUrl
        : null;

    return (
      <Box>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ListItemText sx={{ width: "30%", my: "auto" }}>{label}</ListItemText>
          <ListItemText sx={{ width: "70%" }}>
            {isEditMode ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {fileUrl && (
                  <a href={fileUrl} target="_blank" rel="noreferrer">
                    View {label}
                  </a>
                )}
                <input
                  type="file"
                  id={field}
                  name={field}
                  ref={
                    field === "privacyPolicy"
                      ? privacyPolicyInputRef
                      : termsAndConditionsInputRef
                  }
                  onChange={(e) => handleFileChange(e, field)}
                  style={{ display: "none" }}
                />
                <Button
                  variant="outlined"
                  component="span"
                  color="secondary"
                  onClick={() => {
                    if (field === "privacyPolicy") {
                      privacyPolicyInputRef.current.click();
                    } else if (field === "termsAndConditions") {
                      termsAndConditionsInputRef.current.click();
                    }
                  }}
                >
                  Upload
                </Button>
              </Box>
            ) : (
              fileUrl && (
                <a href={fileUrl} target="_blank" rel="noreferrer">
                  View {label}
                </a>
              )
            )}
          </ListItemText>
        </ListItem>
        <Divider />
      </Box>
    );
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a URL for the selected file
      const newLogoUrl = URL.createObjectURL(file);
      setLogoUrl(newLogoUrl);

      setEditedData((prevData) => ({
        ...prevData,
        logo: file,
      }));
    }
  };

  const handleFileChange = (event, field) => {
    const file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);

      setEditedData((prevData) => ({
        ...prevData,
        [field]: file,
      }));

      if (field === "privacyPolicy") {
        setPrivacyPolicyUrl(fileUrl);
      } else if (field === "termsAndConditions") {
        setTermsConditionsUrl(fileUrl);
      }
    }
  };

  if (isLoadingUser || isLoadingOrganisation) return <Spinner />;
  if (organisationError) return <div>Error fetching organisation details</div>;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header
          title={editedData?.name ?? "Organisation"}
          subtitle="Organisation Profile"
        />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleSaveChanges}
          disabled={!isDataChanged}
        >
          Save Changes
        </Button>
      </Box>

      <Paper
        elevation={3}
        sx={{
          padding: "1rem",
          bgcolor: theme.palette.background.alt,
          marginTop: "1rem",
        }}
      >
        <Box
          display="flex"
          sx={{ padding: "1rem" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">Edit Organisation Details</Typography>

          <Button
            variant="outlined"
            sx={{
              color: theme.palette.secondary[300],
              borderColor: theme.palette.secondary[300],
            }}
            onClick={handleEditClick}
          >
            {isEditMode ? <CheckIcon /> : <EditIcon />}
          </Button>
        </Box>
        <Grid container spacing={2}>
          {/* Adjust the grid layout */}
          <Grid item xs={6}>
            {/* Organisation Details */}
            {renderDetailItem("Business Name", "name", editedData.name)}
            {renderDetailItem("Plan", "plan", editedData.plan)}
            {renderDetailItem("Status", "status", editedData.status)}
            {renderDetailItem("Address", "address", editedData.address)}
            {renderDetailItem(
              "External Extension",
              "externalExt",
              editedData.externalExt
            )}
            {renderFileItem(
              "Vendor Privacy Policy",
              "privacyPolicy",
              editedData.privacyPolicy
            )}
            {renderFileItem(
              "Vendor Terms & Conditions",
              "termsAndConditions",
              editedData.termsAndConditions
            )}
          </Grid>
          <Grid item xs={6}>
            {/* Contact Details */}
            {renderDetailItem("Website", "website", editedData.website)}
            {renderDetailItem(
              "Contact Name",
              "contactName",
              editedData.contactName
            )}
            {renderDetailItem(
              "Contact Phone",
              "contactPhoneNumber",
              editedData.contactPhoneNumber
            )}
            {renderDetailItem(
              "Contact Email",
              "contactEmail",
              editedData.contactEmail
            )}
            {renderDetailItem(
              "Signup Password",
              "externalSignupPassword",
              editedData.externalSignupPassword
            )}
            <ListItem
              mt={2}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListItemText sx={{ width: "30%", my: "auto" }}>
                Logo
              </ListItemText>
              <ListItemText sx={{ width: "30%" }}>
                {logoUrl && (
                  <img
                    crossorigin="anonymous"
                    src={logoUrl || editedData.logo}
                    alt="logo"
                    style={{ width: "100px", height: "auto" }}
                  />
                )}
              </ListItemText>
              <ListItemText sx={{ width: "40%" }}>
                {isEditMode && (
                  <Box>
                    <label htmlFor="logo">
                      <Button
                        variant="outlined"
                        component="span"
                        color="secondary"
                        onClick={() => logoInputRef.current.click()}
                        sx={{ marginRight: "2rem" }}
                      >
                        Upload Logo
                      </Button>
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="logo"
                      name="logo"
                      ref={logoInputRef}
                      onChange={(e) => handleLogoChange(e)}
                      style={{ display: "none" }}
                    />
                  </Box>
                )}
              </ListItemText>
            </ListItem>
            <Divider />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Oranisation;
