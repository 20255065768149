import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  useTheme,
  Typography,
} from "@mui/material";
import EditableContainer from "../EditableContainer";
import CurrencySelector from "components/CurrencySelector";
import { capitalizeFirstLetter } from "../usefulFunctions";

const DetailsPanel = ({ data, editedData, handleEditChange }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const theme = useTheme();

  const specialisations = [
    "Accounting",
    "Art",
    "Automotive",
    "Aviation",
    "Biology",
    "Chemistry",
    "Computer Software",
    "Computer Hardware",
    "Construction",
    "Cosmetics",
    "Energy",
    "Engineering",
    "Entertainment / Film",
    "Environment",
    "Family Law",
    "Financial",
    "Food & Drink",
    "Games – Video – Casino",
    "Geology",
    "Health",
    "Human rights",
    "Insurance",
    "Journalism",
    "Legal",
    "Life sciences",
    "Marine",
    "Maritime",
    "Marketing / market research",
    "Media",
    "Medical",
    "Metallurgy",
    "Military / Defence",
    "Mining",
    "Music Patents",
    "Pharmaceuticals",
    "Physics",
    "Poetry",
    "Literature",
    "Property / Real Estate",
    "Railways",
    "Shipping",
    "Sports",
    "Tax",
    "Tourism / Travel",
    "Transport",
  ];

  const services = [
    "Access services",
    "Checking/Editing",
    "Copywriting",
    "Dubbing",
    "Interpreting",
    "Interpreting (Ad-hoc)",
    "Interpreting (Court)",
    "Interpreting (Consecutive)",
    "Interpreting (Simultaneous)",
    "Interpreting (Remote Telecom and Video)",
    "Interpreting (Whispering)",
    "Language Coaching",
    "Language Instruction",
    "Localisation (Audio Visual)",
    "Localisation (Software)",
    "Localisation (Graphics)",
    "Localisation (Website)",
    "Post-editing",
    "Proofreading",
    "Subtitling",
    "Summarisation",
    "Transcription",
    "Translation",
    "Voiceover",
  ];

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <EditableContainer
      title={`Vendor Details`}
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <List>
        <ListItem key="name">
          <ListItemText primary="Name" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                fullWidth
                size="small"
                value={editedData.name || ""}
                onChange={(e) => handleEditChange("name", e.target.value)}
              />
            ) : (
              editedData.name ?? data.name
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="email">
          <ListItemText primary="Email" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                fullWidth
                size="small"
                value={editedData.email || ""}
                onChange={(e) => handleEditChange("email", e.target.value)}
              />
            ) : (
              editedData.email ?? data.email
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="phoneNumber">
          <ListItemText primary="Phone Number" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                fullWidth
                size="small"
                value={editedData.phoneNumber || ""}
                onChange={(e) =>
                  handleEditChange("phoneNumber", e.target.value)
                }
              />
            ) : (
              editedData.phoneNumber ?? data.phoneNumber
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="preferredContact">
          <ListItemText primary="Preferred Contact" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <FormControl size="small" fullWidth>
                <Select
                  value={editedData.preferredContact || ""}
                  onChange={(e) =>
                    handleEditChange("preferredContact", e.target.value)
                  }
                >
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="phone">Phone</MenuItem>
                </Select>
              </FormControl>
            ) : (
              capitalizeFirstLetter(editedData.preferredContact ?? "")
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="preferredCurrency">
          <ListItemText primary="Preferred Currency" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <CurrencySelector
                selectedCurrency={editedData.preferredCurrency}
                onSelectCurrency={(currency) =>
                  handleEditChange("preferredCurrency", currency)
                }
              />
            ) : (
              editedData.preferredCurrency ?? data.preferredCurrency
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="address">
          <ListItemText primary="Address" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                fullWidth
                size="small"
                value={editedData.address || ""}
                onChange={(e) => handleEditChange("address", e.target.value)}
              />
            ) : (
              editedData.address ?? data.address
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="country">
          <ListItemText primary="Country" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                fullWidth
                size="small"
                value={editedData.country || ""}
                onChange={(e) => handleEditChange("country", e.target.value)}
              />
            ) : (
              editedData.country ?? data.country
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="company">
          <ListItemText primary="Company" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                fullWidth
                size="small"
                value={editedData.company || ""}
                onChange={(e) => handleEditChange("company", e.target.value)}
              />
            ) : (
              editedData.company ?? data.company
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="specialty">
          <ListItemText primary="Specialisation" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <Autocomplete
                multiple
                options={specialisations.filter((specialisation) => {
                  return (
                    !editedData.specialty?.some(
                      (speciality) => speciality === specialisation
                    ) ||
                    !data.specialty?.some(
                      (speciality) => speciality === specialisation
                    )
                  );
                })}
                size="small"
                value={editedData.specialty || data.specialty || []}
                onChange={(e, newValue) =>
                  handleEditChange("specialty", [...new Set(newValue)])
                }
                renderInput={(params) => <TextField {...params} />}
              />
            ) : editedData.specialty?.length > 0 ||
              data.specialty?.length > 0 ? (
              (editedData.specialty || data.specialty)?.map(
                (specialisation, index) => (
                  <span key={specialisation.id}>
                    {specialisation}
                    {index < (editedData.specialty || data.specialty).length - 1
                      ? ", "
                      : ""}
                  </span>
                )
              )
            ) : (
              ""
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="services">
          <ListItemText primary="Service" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <Autocomplete
                multiple
                options={services.filter((service) => {
                  return (
                    !editedData.services?.some((srv) => srv === service) ||
                    !data.services?.some((srv) => srv === service)
                  );
                })}
                size="small"
                value={editedData.services || data.services || []}
                onChange={(e, newValue) =>
                  handleEditChange("services", [...new Set(newValue)])
                }
                renderInput={(params) => <TextField {...params} />}
              />
            ) : editedData.services?.length > 0 || data.services?.length > 0 ? (
              (editedData.services || data.services)?.map((service, index) => (
                <span key={service.id}>
                  {service}
                  {index < (editedData.services || data.services).length - 1
                    ? ", "
                    : ""}
                </span>
              ))
            ) : (
              ""
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="trusted">
          <ListItemText primary="Trusted" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <FormControl size="small" fullWidth>
                <Select
                  value={editedData.trusted ?? ""}
                  onChange={(e) => handleEditChange("trusted", e.target.value)}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            ) : (
              capitalizeFirstLetter(editedData.trusted ?? data.trusted ?? "")
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="inactive">
          <ListItemText primary="Inactive" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <Checkbox
                checked={editedData.inactive ?? data.inactive ?? false}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.secondary.main,
                  },
                }}
                onChange={(e) => handleEditChange("inactive", e.target.checked)}
                edge="end"
              />
            ) : (
              <Typography>{editedData.inactive ? "Yes" : "No"}</Typography>
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="createdAt">
          <ListItemText primary="Created At" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {new Intl.DateTimeFormat("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZoneName: "short",
            }).format(new Date(data.createdAt))}
          </ListItemText>
        </ListItem>
      </List>
    </EditableContainer>
  );
};

export default DetailsPanel;
