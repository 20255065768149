import React, { useState } from "react";
import {
  useTheme,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Paper,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const TasksPanel = ({ data }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: "1rem", bgcolor: theme.palette.background.alt }}
    >
      <Box
        display="flex"
        sx={{ padding: "1rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Assigned Tasks</Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab label="Live Tasks" />
          <Tab label="Completed Tasks" />
        </Tabs>
      </Box>
      {/* Add content for each tab */}
      {tabValue === 0 && (
        <Box>
          <Divider />
          {data.tasks
            .filter(
              (task) => task.status === "pending" || task.status === "active"
            )
            .map((task) => (
              <Box>
                <ListItem
                  key={task._id}
                  sx={{
                    display: "flex",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.light,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate(`/tasks/${task._id}`)}
                >
                  <ListItemText
                    primary={task.name}
                    secondary={task.jobId.name}
                    sx={{
                      flex: "4",
                    }}
                  />
                  <ListItemText
                    primary={
                      task.dueDate
                        ? `Due: ${new Intl.DateTimeFormat("en-US", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(task.dueDate))}`
                        : "No due date"
                    }
                    sx={{
                      flex: "1",
                    }}
                  />
                </ListItem>
                <Divider />
              </Box>
            ))}
        </Box>
      )}
      {tabValue === 1 && (
        <Box>
          <Divider />
          {data.tasks
            .filter(
              (task) =>
                task.status === "completed" || task.status === "cancelled"
            ) // Filter tasks with status "closed"
            .map((task) => (
              <Box>
                <ListItem
                  key={task._id}
                  sx={{
                    display: "flex",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.light,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate(`/tasks/${task._id}`)}
                >
                  <ListItemText
                    primary={task.name}
                    secondary={task.jobId.name}
                  />
                </ListItem>
                <Divider />
              </Box>
            ))}
        </Box>
      )}
    </Paper>
  );
};

export default TasksPanel;
