import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import languages from "languages";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CurrencySelector from "components/CurrencySelector";
import { capitalizeFirstLetter } from "components/usefulFunctions";

const RatesSection = ({ formData, handleChange, errors, ratesRef }) => {
  const [newRate, setNewRate] = useState({
    category: null,
    cost: null,
    unit: null,
    sourceLanguage: null,
    targetLanguage: null,
  });

  // Create an array of language options using the "languages" package
  const langscodes = languages.getAllLanguageCode();
  const languageOptions = langscodes.map((langcode) => {
    const languageInfo = languages.getLanguageInfo(langcode);
    return languageInfo.name;
  });

  const handleAddRate = () => {
    if (
      newRate.sourceLanguage &&
      newRate.targetLanguage &&
      newRate.category &&
      newRate.cost &&
      newRate.unit
    ) {
      const updatedRates = [...(formData.rates || []), newRate];
      handleChange(updatedRates, "rates");
      setNewRate({
        category: null,
        cost: null,
        unit: null,
        sourceLanguage: null,
        targetLanguage: null,
      });
    }
  };

  const handleRemoveRate = (index) => {
    const newRates = [...(formData.rates || [])];
    newRates.splice(index, 1);
    handleChange(newRates, "rates");
  };

  const handleEditRate = (index) => {
    const editedRates = [...(formData.rates || [])];

    // Create a shallow copy of the object
    const editedRate = { ...editedRates[index] };
    editedRate.isEditing = true;
    editedRates[index] = editedRate;
    handleChange(editedRates, "rates");
  };

  const handleSaveEditedRate = (index) => {
    const editedRates = [...(formData.rates || [])];
    // Remove the "isEditing" property
    delete editedRates[index].isEditing;
    handleChange(editedRates, "rates");
  };

  //note

  return (
    <>
      <Grid ref={ratesRef} item xs={12} sm={12} sx={{ mt: "3rem" }}>
        <Divider sx={{ my: 2, mb: "3rem" }} />
        <Typography variant="h6" gutterBottom>
          Rates
        </Typography>
        <Typography variant="body2" gutterBottom>
          Preferred currency
        </Typography>
        <Box
          sx={{
            my: "1rem",
          }}
        >
          <CurrencySelector
            selectedCurrency={formData.preferredCurrency}
            onSelectCurrency={(currency) =>
              handleChange(currency, "preferredCurrency")
            }
          />
        </Box>
        <Typography variant="body2" gutterBottom>
          Please enter your rates for each language pair and service you offer,
          and use the Add button to add it to the list. You can edit or delete
          any of the rates you have added.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <List>
          {formData.rates.length > 1 &&
            formData.rates?.slice(0, -1).map((rate, index) => (
              <div key={`rate-${index}`}>
                {rate.isEditing ? (
                  <ListItem sx={{ px: 0 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "calc(100% - 48px)",
                          mr: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          {/* Autocomplete for source languages */}
                          <Autocomplete
                            sx={{ width: "40%" }}
                            options={languageOptions}
                            size="large"
                            value={rate.sourceLanguage || ""}
                            onChange={(e, newValue) => {
                              const updatedRate = {
                                ...rate,
                                sourceLanguage: newValue,
                              };
                              const updatedRates = [...formData.rates];
                              updatedRates[index] = updatedRate;
                              handleChange(updatedRates, "rates");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select source languages"
                                error={errors.rates}
                              />
                            )}
                          />
                          {/* Autocomplete for target language */}
                          <Autocomplete
                            options={languageOptions}
                            size="large"
                            sx={{ width: "40%" }}
                            value={rate.targetLanguage || null}
                            onChange={(e, newValue) => {
                              const updatedRate = {
                                ...rate,
                                targetLanguage: newValue,
                              };
                              const updatedRates = [...formData.rates];
                              updatedRates[index] = updatedRate;
                              handleChange(updatedRates, "rates");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select target language"
                                error={errors.rates}
                              />
                            )}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            mt: 1,
                          }}
                        >
                          {/* Category Select */}
                          <ListItemText sx={{ width: "30%" }}>
                            <FormControl sx={{ width: "80%" }}>
                              <InputLabel
                                htmlFor={`rates-category-${index}`}
                                error={errors.rates}
                              >
                                Category
                              </InputLabel>
                              <Select
                                label="Category"
                                value={rate.category || ""}
                                error={errors.rates}
                                inputProps={{
                                  name: `ratesCategory-${index}`,
                                  id: `rates-category-${index}`,
                                }}
                                onChange={(e) => {
                                  const updatedRate = {
                                    ...rate,
                                    category: e.target.value,
                                  };
                                  const updatedRates = [...formData.rates];
                                  updatedRates[index] = updatedRate;
                                  handleChange(updatedRates, "rates");
                                }}
                              >
                                <MenuItem value="translation">
                                  Translation
                                </MenuItem>
                                <MenuItem value="proofread">Proofread</MenuItem>
                                <MenuItem value="formatting">
                                  Formatting
                                </MenuItem>
                                <MenuItem value="transcription">
                                  Transcription
                                </MenuItem>
                                <MenuItem value="interpreting">
                                  Interpreting
                                </MenuItem>
                                <MenuItem value="editing">Editing</MenuItem>
                              </Select>
                            </FormControl>
                          </ListItemText>
                          {/* Cost TextField */}
                          <ListItemText sx={{ width: "5%" }}>
                            <TextField
                              fullWidth
                              label="Cost"
                              value={rate.cost || ""}
                              error={errors.rates}
                              onChange={(e) => {
                                const updatedRate = {
                                  ...rate,
                                  cost: e.target.value,
                                };
                                const updatedRates = [...formData.rates];
                                updatedRates[index] = updatedRate;
                                handleChange(updatedRates, "rates");
                              }}
                            />
                          </ListItemText>
                          {/* Unit Select */}
                          <ListItemText sx={{ width: "20%", ml: "1rem" }}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel
                                htmlFor={`rates-unit-${index}`}
                                error={errors.rates}
                              >
                                Unit
                              </InputLabel>
                              <Select
                                label="Unit"
                                error={errors.rates}
                                value={rate.unit || ""}
                                inputProps={{
                                  name: `ratesUnit-${index}`,
                                  id: `rates-unit-${index}`,
                                }}
                                onChange={(e) => {
                                  const updatedRate = {
                                    ...rate,
                                    unit: e.target.value,
                                  };
                                  const updatedRates = [...formData.rates];
                                  updatedRates[index] = updatedRate;
                                  handleChange(updatedRates, "rates");
                                }}
                              >
                                <MenuItem value="word">per word</MenuItem>
                                <MenuItem value="hourly">Hourly</MenuItem>
                                <MenuItem value="half day">Half day</MenuItem>
                                <MenuItem value="full day">Full day</MenuItem>
                                <MenuItem value="minimum charge">
                                  Minimum charge
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </ListItemText>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          flexShrink: 0,
                          alignSelf: "stretch",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* Save Button */}
                        <IconButton
                          color="secondary"
                          aria-label="save"
                          onClick={() => handleSaveEditedRate(index)}
                        >
                          <SaveIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItem>
                ) : (
                  <ListItem sx={{ px: 0 }}>
                    <ListItemText sx={{ width: "50%", mr: "2rem" }}>
                      {rate.sourceLanguage} into {rate.targetLanguage}
                    </ListItemText>
                    <ListItemText sx={{ width: "20%" }}>
                      {capitalizeFirstLetter(rate.category)}
                    </ListItemText>
                    <ListItemText sx={{ width: "10%" }}>
                      {rate.cost}
                    </ListItemText>
                    <ListItemText sx={{ width: "10%" }}>
                      {rate.unit}
                    </ListItemText>
                    <IconButton
                      color="secondary"
                      aria-label="edit"
                      onClick={() => handleEditRate(index)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() => handleRemoveRate(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                )}
                <Divider
                  sx={{
                    my: 1,
                  }}
                />
              </div>
            ))}
          <div>
            <ListItem sx={{ px: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {/* Autocomplete for source languages */}
                    <Autocomplete
                      sx={{ width: "50%", mr: "2rem" }}
                      options={languageOptions}
                      size="large"
                      value={newRate.sourceLanguage || ""}
                      onChange={(e, newValue) => {
                        setNewRate({
                          ...newRate,
                          sourceLanguage: newValue,
                        });
                        handleChange(
                          [
                            ...formData.rates.slice(0, -1),
                            {
                              sourceLanguage: newValue,
                              targetLanguage: newRate.targetLanguage,
                              category: newRate.category,
                              cost: newRate.cost,
                              unit: newRate.unit,
                            },
                          ],
                          "rates"
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select source language"
                          error={errors.rates}
                        />
                      )}
                    />
                    {/* Autocomplete for target language */}
                    <Autocomplete
                      options={languageOptions}
                      size="large"
                      sx={{ width: "50%" }}
                      value={newRate.targetLanguage || null}
                      onChange={(e, newValue) => {
                        setNewRate({
                          ...newRate,
                          targetLanguage: newValue,
                        });
                        handleChange(
                          [
                            ...formData.rates.slice(0, -1),
                            {
                              sourceLanguage: newRate.sourceLanguage,
                              targetLanguage: newValue,
                              category: newRate.category,
                              cost: newRate.cost,
                              unit: newRate.unit,
                            },
                          ],
                          "rates"
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select target language"
                          error={errors.rates}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      mt: 1,
                    }}
                  >
                    {/* Category Select */}
                    <ListItemText sx={{ width: "30%" }}>
                      <FormControl sx={{ width: "80%" }}>
                        <InputLabel
                          htmlFor="rates-category"
                          error={errors.rates}
                        >
                          Category
                        </InputLabel>
                        <Select
                          label="Category"
                          value={newRate.category || ""}
                          error={errors.rates}
                          inputProps={{
                            name: "ratesCategory",
                            id: "rates-category",
                          }}
                          onChange={(e) => {
                            setNewRate({
                              ...newRate,
                              category: e.target.value,
                            });
                            handleChange(
                              [
                                ...formData.rates.slice(0, -1),
                                {
                                  sourceLanguage: newRate.sourceLanguage,
                                  targetLanguage: newRate.targetLanguage,
                                  category: e.target.value,
                                  cost: newRate.cost,
                                  unit: newRate.unit,
                                },
                              ],
                              "rates"
                            );
                          }}
                        >
                          <MenuItem value="translation">Translation</MenuItem>
                          <MenuItem value="proofread">Proofread</MenuItem>
                          <MenuItem value="formatting">Formatting</MenuItem>
                          <MenuItem value="transcription">
                            Transcription
                          </MenuItem>
                          <MenuItem value="interpreting">Interpreting</MenuItem>
                          <MenuItem value="editing">Editing</MenuItem>
                        </Select>
                      </FormControl>
                    </ListItemText>
                    {/* Cost TextField */}
                    <ListItemText sx={{ width: "5%" }}>
                      <TextField
                        fullWidth
                        error={errors.rates}
                        label="Cost"
                        value={newRate.cost || ""}
                        onChange={(e) => {
                          setNewRate({
                            ...newRate,
                            cost: e.target.value,
                          });
                          handleChange(
                            [
                              ...formData.rates.slice(0, -1),
                              {
                                sourceLanguage: newRate.sourceLanguage,
                                targetLanguage: newRate.targetLanguage,
                                category: newRate.category,
                                cost: e.target.value,
                                unit: newRate.unit,
                              },
                            ],
                            "rates"
                          );
                        }}
                      />
                    </ListItemText>
                    {/* Unit Select */}
                    <ListItemText sx={{ width: "20%", ml: "1rem" }}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel htmlFor="rates-unit" error={errors.rates}>
                          Unit
                        </InputLabel>
                        <Select
                          label="Unit"
                          value={newRate.unit || ""}
                          error={errors.rates}
                          inputProps={{
                            name: "ratesUnit",
                            id: "rates-unit",
                          }}
                          onChange={(e) => {
                            setNewRate({
                              ...newRate,
                              unit: e.target.value,
                            });
                            handleChange(
                              [
                                ...formData.rates.slice(0, -1),
                                {
                                  sourceLanguage: newRate.sourceLanguage,
                                  targetLanguage: newRate.targetLanguage,
                                  category: newRate.category,
                                  cost: newRate.cost,
                                  unit: e.target.value,
                                },
                              ],
                              "rates"
                            );
                          }}
                        >
                          <MenuItem value="per word">per word</MenuItem>
                          <MenuItem value="hourly">Hourly</MenuItem>
                          <MenuItem value="half day">Half day</MenuItem>
                          <MenuItem value="full day">Full day</MenuItem>
                          <MenuItem value="minimum charge">
                            Minimum charge
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </ListItemText>
                  </Box>
                </Box>
              </Box>
            </ListItem>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "1rem",
              }}
            >
              <IconButton
                color="secondary"
                aria-label="add"
                onClick={handleAddRate}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </div>
        </List>
      </Grid>
    </>
  );
};

export default RatesSection;
