import React, { useState, useEffect } from "react";
import { useGetClientsQuery } from "state/api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Chip,
  TextField,
  Autocomplete,
  Stack,
} from "@mui/material";

const AssignClientsDialog = ({
  data,
  editedData,
  open,
  onClose,
  handleEditChange,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    data: clientsData,
    isFetching,
    refetch,
  } = useGetClientsQuery({
    pending: false,
    search: searchTerm,
  });
  const [selectedClients, setSelectedClients] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      refetch();
    }, 300);

    return () => clearTimeout(timerId);
  }, [searchTerm, refetch]);

  useEffect(() => {
    if (clientsData?.clients) {
      setClients(clientsData.clients);

      if (data?.otherClients) {
        const alreadySelectedClientIds = data?.otherClients.map(
          (client) => client._id
        );
        const alreadySelectedClients = clientsData.clients.filter((client) => {
          return alreadySelectedClientIds?.includes(client._id);
        });
        if (!selectedClients) {
          setSelectedClients(alreadySelectedClients);
        }
      }
    }
  }, [data, clientsData]);

  const handleAddClient = (event, client) => {
    if (client) {
      setSelectedClients((prevSelectedClients) => [
        ...prevSelectedClients,
        client,
      ]);
    }
  };

  const handleRemoveClient = (clientToRemove) => {
    setSelectedClients((prevSelectedClients) =>
      prevSelectedClients.filter((client) => client._id !== clientToRemove._id)
    );
  };

  const handleSaveClients = () => {
    handleEditChange("otherClients", selectedClients);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-client-dialog-title"
      aria-describedby="edit-client-dialog-description"
    >
      <DialogTitle id="edit-client-dialog-title">
        Edit Associated Clients
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="edit-client-dialog-description"
          sx={{
            mb: "2rem",
          }}
        >
          To add or remove associated clients from this job, use the below field
        </DialogContentText>
        <Box sx={{ mb: "2rem" }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {selectedClients.map((client) => (
              <Chip
                key={client._id}
                onClick={() => window.open(`/clients/${client._id}`, "_blank")}
                label={client.businessName}
                onDelete={() => handleRemoveClient(client)}
                variant="outlined"
                size="small"
              />
            ))}
          </Stack>
        </Box>
        <Autocomplete
          options={clients.filter(
            (client) =>
              !selectedClients.some((selected) => selected._id === client._id)
          )}
          getOptionLabel={(client) => client.businessName || ""}
          value={null}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          onChange={handleAddClient}
          renderInput={(params) => (
            <TextField {...params} label="Search clients" />
          )}
          isLoading={isFetching}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="warning">
          Cancel
        </Button>
        <Button onClick={handleSaveClients} color="success">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignClientsDialog;
