// DataGridFilterPanel.jsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DataGridFilterPanel = ({
  columns,
  filters,
  setFilters,
  applyFilters,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterRemoved, setFilterRemoved] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddFilter = () => {
    const newFilter = {
      id: Date.now(),
      columnField: "",
      operatorValue: "",
      value: "",
    };
    setFilters([...filters, newFilter]);
  };

  const handleRemoveFilter = (id) => {
    setFilters(filters.filter((filter) => filter.id !== id));
    setFilterRemoved(true);
  };

  useEffect(() => {
    if (filterRemoved) {
      applyFilters();
      setFilterRemoved(false);
    }
  }, [filterRemoved, applyFilters]);

  const handleUpdateFilter = (id, part, value) => {
    setFilters(
      filters.map((filter) => {
        if (filter.id === id) {
          return { ...filter, [part]: value };
        }
        return filter;
      })
    );
  };

  return (
    <Box>
      <Button
        aria-controls="filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="secondary"
        variant="outlined"
      >
        Filters
      </Button>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Box padding={2}>
          {filters.map((filter, index) => (
            <Box key={filter.id} sx={{ mb: 2 }}>
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ mb: 2 }}
                width="40rem"
              >
                <Select
                  value={filter.columnField}
                  size="small"
                  onChange={(e) =>
                    handleUpdateFilter(filter.id, "columnField", e.target.value)
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select Column
                  </MenuItem>
                  {columns.map((column) => (
                    <MenuItem key={column.field} value={column.field}>
                      {column.headerName}
                    </MenuItem>
                  ))}
                </Select>
                {filter.columnField && filter.columnField === "rates" ? (
                  <>
                    <Select
                      value={filter.rateCategory || ""}
                      size="small"
                      onChange={(e) =>
                        handleUpdateFilter(
                          filter.id,
                          "rateCategory",
                          e.target.value
                        )
                      }
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select Category
                      </MenuItem>
                      <MenuItem value="translation">Translation</MenuItem>
                      <MenuItem value="proofread">Proofread</MenuItem>
                      <MenuItem value="formatting">Formatting</MenuItem>
                      <MenuItem value="transcription">Transcription</MenuItem>
                      <MenuItem value="interpreting">Interpreting</MenuItem>
                      <MenuItem value="editing">Editing</MenuItem>
                    </Select>
                    <Select
                      value={filter.operatorValue}
                      size="small"
                      onChange={(e) =>
                        handleUpdateFilter(
                          filter.id,
                          "operatorValue",
                          e.target.value
                        )
                      }
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select Operator
                      </MenuItem>
                      <MenuItem value="greater_than">Greater Than</MenuItem>
                      <MenuItem value="less_than">Less Than</MenuItem>
                      <MenuItem value="equals">Equals</MenuItem>
                    </Select>
                  </>
                ) : (
                  <Select
                    value={filter.operatorValue}
                    size="small"
                    onChange={(e) =>
                      handleUpdateFilter(
                        filter.id,
                        "operatorValue",
                        e.target.value
                      )
                    }
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Select Operator
                    </MenuItem>
                    <MenuItem value="contains">Contains</MenuItem>
                    <MenuItem value="equals">Equals</MenuItem>
                  </Select>
                )}
                <TextField
                  size="small"
                  value={filter.value}
                  onChange={(e) =>
                    handleUpdateFilter(filter.id, "value", e.target.value)
                  }
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  fullWidth
                />
                {filter.columnField && filter.columnField === "rates" && (
                  <Select
                    value={filter.rateUnit}
                    size="small"
                    onChange={(e) =>
                      handleUpdateFilter(filter.id, "rateUnit", e.target.value)
                    }
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Select Rate
                    </MenuItem>
                    <MenuItem value="word">per word</MenuItem>
                    <MenuItem value="hourly">Hourly</MenuItem>
                    <MenuItem value="half day">Half day</MenuItem>
                    <MenuItem value="full day">Full day</MenuItem>
                    <MenuItem value="minimum charge">Minimum charge</MenuItem>
                  </Select>
                )}
                <IconButton
                  color="error"
                  onClick={() => handleRemoveFilter(filter.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Divider sx={{}} />
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "40rem",
            }}
          >
            <Button onClick={handleAddFilter} variant="contained" size="small">
              Add Filter
            </Button>
            <Button
              onClick={() => {
                applyFilters();
                handleClose();
              }}
              color="secondary"
              variant="contained"
              size="small"
            >
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default DataGridFilterPanel;
