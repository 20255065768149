import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetJobQuery,
  usePutJobMutation,
  useDeleteJobMutation,
  useCreateTaskMutation,
  useSetAllJobTasksToCompleteMutation,
  useCreateQuoteMutation,
} from "state/api";
import { useAuth } from "components/auth";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
} from "@mui/material";
import Header from "components/Header";
import LanguagePanel from "components/job-page/LanguagePanel";
import PlanningPanel from "components/job-page/PlanningPanel";
import DetailsPanel from "components/job-page/DetailsPanel";
import TaskPanel from "components/job-page/TaskPanel";
import ClientDetailsPanel from "components/job-page/ClientDetailsPanel";
import ActionsMenu from "components/ActionsMenu";
import HelpSidebar from "components/job-page/HelpSidebar";
import FilesPanel from "components/job-page/FilesPanel";
import NotesPanel from "components/NotesPanel";
import Spinner from "components/Spinner";
import UnsavedChanges from "components/UnsavedChanges";
import { useSnackbar } from "components/Snackbar";
import FinanceDetailsPanel from "components/job-page/FinanceDetailsPanel";
import QuotesPanel from "components/job-page/QuotesPanel";

const Job = () => {
  const auth = useAuth();
  const userId = auth.user;
  const navigate = useNavigate();
  const [putJobMutation] = usePutJobMutation();
  const [createTaskMutation] = useCreateTaskMutation();
  const [createQuoteMutation] = useCreateQuoteMutation();
  const [deleteJobMutation] = useDeleteJobMutation();
  const [setAllJobTasksToComplete] = useSetAllJobTasksToCompleteMutation();
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetJobQuery(id);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCompleteJobDialogOpen, setIsCompleteJobDialogOpen] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    if (data) {
      setEditedData({ ...data });
    }
  }, [data]);

  useEffect(() => {
    refetch(); // eslint-disable-next-line
  }, [id]);

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const openCompleteJobDialog = () => {
    setIsCompleteJobDialogOpen(true);
  };

  const closeCompleteJobDialog = () => {
    setIsCompleteJobDialogOpen(false);
  };

  const completeAllTasks = async () => {
    try {
      handleSaveChanges();
      await setAllJobTasksToComplete({ jobId: id }).then((response) => {
        if (response.data.success) {
          closeCompleteJobDialog();
          refetch();
          showSnackbar("All tasks completed successfully", "success");
        } else {
          showSnackbar("Error completing all tasks", "error");
        }
      });
    } catch (error) {
      showSnackbar("Error completing all tasks", "error");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteJobMutation(id).then((res) => {
        navigate("/jobs");
      });
      // Handle success or any other actions after successful delete
    } catch (error) {
      showSnackbar("Error deleting job", "error");
    }
  };

  const actions = [
    {
      label: "Add New Task",
      onClick: async () => {
        await createTaskMutation({ jobId: id }).then((res) => {
          if (res.data.taskId) {
            navigate("/tasks/" + res.data.taskId);
          }
        });
      },
    },
    {
      label: "Create New Quote",
      onClick: async () => {
        const data = { job: id };
        await createQuoteMutation({ data }).then((res) => {
          if (res.data.quoteId) {
            navigate("/quotes/" + res.data.quoteId);
          }
        });
      },
    },
    {
      label: "Delete Job",
      onClick: () => {
        openDeleteDialog();
      },
    },
  ];

  const handleSaveChanges = async () => {
    try {
      await putJobMutation({
        id: id,
        updatedData: editedData,
        userId: userId,
      }).then((res) => {
        if (res.data._id) {
          showSnackbar("Job updated successfully", "success");
        }
      });

      setIsDataChanged(false);
    } catch (error) {
      showSnackbar("Error updating job", "error");
    }
  };

  const handleEditChange = (field, value) => {
    if (!isDataChanged) {
      setIsDataChanged(true);
    }

    // If the status is changed to completed or delivered, check if all tasks are completed or cancelled
    if (
      field === "status" &&
      (value === "closed" || value === "invoiced" || value === "delivered") &&
      editedData.tasks?.length > 0 &&
      editedData.tasks?.some(
        (task) => task.status !== "completed" && task.status !== "cancelled"
      )
    ) {
      openCompleteJobDialog();
    }
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  if (!editedData || isLoading) {
    return <Spinner />;
  }

  return (
    <Box>
      <UnsavedChanges isDataChanged={isDataChanged} />
      <SnackbarComponent />
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Box m="1rem 0rem 0rem 2.5rem">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px="2rem"
            >
              <Header title={data?.name ?? "Job"} subtitle="Job page" />
              <Box>
                <ActionsMenu actions={actions} />
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  sx={{ ml: "2rem" }}
                  onClick={handleSaveChanges}
                  disabled={!isDataChanged}
                >
                  Save
                </Button>
              </Box>
            </Box>
            <Box sx={{ m: "2rem" }}>
              <Grid container spacing={4} sx={{ mb: "2rem" }}>
                <Grid item xs={12} md={6}>
                  <DetailsPanel
                    data={data}
                    editedData={editedData}
                    handleEditChange={handleEditChange}
                    changesMade={() => setIsDataChanged(true)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <ClientDetailsPanel
                      data={data}
                      editedData={editedData}
                      handleEditChange={handleEditChange}
                    />
                  </Box>
                  <Box sx={{ mt: "2rem" }}>
                    <FinanceDetailsPanel
                      editedData={editedData}
                      handleEditChange={handleEditChange}
                      changesMade={() => setIsDataChanged(true)}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mb: "2rem" }}>
                <LanguagePanel
                  data={data}
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                  changesMade={() => setIsDataChanged(true)}
                />
              </Box>
              <Box sx={{ mb: "2rem" }}>
                <FilesPanel
                  data={data}
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                  changesMade={() => setIsDataChanged(true)}
                />
              </Box>
              <Box sx={{ mb: "2rem" }}>
                <QuotesPanel data={data} editedData={editedData} />
              </Box>
              <Box sx={{ mb: "2rem" }}>
                <PlanningPanel
                  data={data}
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                  changesMade={() => setIsDataChanged(true)}
                />
              </Box>
              <Box sx={{ mb: "2rem" }}>
                <TaskPanel
                  data={data}
                  editedData={editedData}
                  refetch={refetch}
                  save={handleSaveChanges}
                />
              </Box>
              <Box sx={{ mb: "2rem" }}>
                <NotesPanel
                  data={data}
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                  changesMade={() => setIsDataChanged(true)}
                />
              </Box>
            </Box>
            <Dialog
              open={isCompleteJobDialogOpen}
              onClose={closeCompleteJobDialog}
              aria-labelledby="complete-job-title"
              aria-describedby="complete-job-description"
            >
              <DialogTitle id="complete-job-title">
                Complete All Tasks
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="complete-job-description">
                  It looks like not all tasks are completed or cancelled. Would
                  you like to set all tasks to completed?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeCompleteJobDialog} color="secondary">
                  No
                </Button>
                <Button
                  onClick={() => {
                    completeAllTasks();
                  }}
                  color="warning"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={isDeleteDialogOpen}
              onClose={closeDeleteDialog}
              aria-labelledby="delete-dialog-title"
              aria-describedby="delete-dialog-description"
            >
              <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText id="delete-dialog-description">
                  Are you sure you want to delete this job? This action cannot
                  be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDeleteDialog} color="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    closeDeleteDialog();
                    handleDelete();
                  }}
                  color="warning"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <HelpSidebar editedData={editedData} data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Job;
