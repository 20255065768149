import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Divider,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import EditableContainer from "components/EditableContainer";
import { useAuth } from "components/auth";
import { useGetUserQuery } from "state/api";

const NotesPanel = ({ data, editedData, handleEditChange, changesMade }) => {
  const auth = useAuth();
  const userId = auth.user;
  const { data: userData } = useGetUserQuery(userId);
  const [isEditMode, setIsEditMode] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedNote, setEditedNote] = useState("");

  const handleAddNote = () => {
    if (newNote.trim() !== "") {
      const timestamp = new Date().toISOString();
      const updatedNotes = [
        ...(editedData.notes || []),
        {
          note: newNote,
          user: {
            name: userData.name,
            id: userId,
          },
          timestamp,
        },
      ];
      handleEditChange("notes", updatedNotes);
      setNewNote("");
    }
  };

  const handleDeleteNote = (index) => {
    const updatedNotes = [...editedData.notes];
    updatedNotes.splice(index, 1);
    handleEditChange("notes", updatedNotes);
  };

  const handleEditNote = (index) => {
    setEditingIndex(index);
    setEditedNote(editedData.notes[index].note);
  };

  const handleSaveEditedNote = (index) => {
    let updatedNotes = [...editedData.notes];
    updatedNotes[index].note = editedNote;
    handleEditChange("notes", updatedNotes);
    setEditingIndex(-1);
  };

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  return (
    <EditableContainer
      title="Notes"
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <List>
        <Divider />
        {editedData &&
          editedData.notes &&
          editedData.notes.length > 0 &&
          (editedData.notes || data.notes).map((note, index) => (
            <React.Fragment key={index}>
              <ListItem>
                {editingIndex === index ? (
                  <TextField
                    fullWidth
                    multiline
                    value={editedNote}
                    onChange={(e) => setEditedNote(e.target.value)}
                  />
                ) : (
                  <ListItemText>
                    <Stack>
                      <Typography variant="caption">
                        {new Intl.DateTimeFormat("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                          hour: "numeric",
                          minute: "numeric",
                        }).format(new Date(note.timestamp))}
                      </Typography>
                      {note.note}
                      <Typography variant="caption">
                        {note.user?.name}
                      </Typography>
                    </Stack>
                  </ListItemText>
                )}
                {isEditMode && (
                  <>
                    {isEditMode && note.user?.id === userId && (
                      <>
                        {editingIndex === index ? (
                          <IconButton
                            color="secondary"
                            onClick={() => handleSaveEditedNote(index)}
                          >
                            <SaveIcon />
                          </IconButton>
                        ) : (
                          <>
                            <IconButton
                              color="secondary"
                              onClick={() => handleEditNote(index)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => handleDeleteNote(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </ListItem>
              {index !== (editedData.notes || data.notes).length - 1 && (
                <Divider />
              )}
            </React.Fragment>
          ))}
        {isEditMode && (
          <ListItem>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Add note"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
              />
              <IconButton
                color="secondary"
                aria-label="add"
                onClick={handleAddNote}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </ListItem>
        )}
      </List>
    </EditableContainer>
  );
};

export default NotesPanel;
