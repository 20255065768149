import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useTheme,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Box,
  Typography,
  Button,
  List,
  TextField,
  Autocomplete,
  Stack,
  ListItemSecondaryAction,
  IconButton,
  Link,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AssignClientsDialog from "../dialogs/AssignClientsDialog";

const ClientDetailsPanel = ({ data, editedData, handleEditChange }) => {
  const [isClientsDialog, setIsClientsDialog] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClientClick = (clientId) => {
    navigate(`/clients/${clientId}`);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "1rem",
        bgcolor: theme.palette.background.alt,
        marginBottom: "1rem",
        height: "100%",
      }}
    >
      <Box
        display="flex"
        sx={{ padding: "1rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Client Contact Details</Typography>
        <Button
          variant="outlined"
          disabled={!editedData.clientId?._id}
          sx={{
            color: theme.palette.secondary[300],
            borderColor: theme.palette.secondary[300],
          }}
          onClick={() =>
            navigate(
              `/clients/${editedData?.clientId?._id ?? data.clientId?._id}`
            )
          }
        >
          View Client
        </Button>
      </Box>
      <List>
        <ListItem key="businessName">
          <ListItemText primary="Client Business Name" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {editedData?.clientId?.businessName ??
              data.clientId?.businessName ??
              ""}
          </ListItemText>
        </ListItem>
        {/*
        <Divider />
        <ListItem key="referenceNumber">
          <ListItemText
            primary="Client Reference Number"
            sx={{ width: "50%" }}
          />
          <ListItemText sx={{ width: "50%" }}>
            {editedData?.clientId?.referenceNumber ??
              data.clientId?.referenceNumber ??
              ""}
          </ListItemText>
        </ListItem>
        */}
        <Divider />
        <ListItem key="preferredContact">
          <ListItemText primary="Preferred Contact" sx={{ width: "50%" }} />
          <Autocomplete
            options={editedData.clientId?.contacts || []}
            getOptionLabel={(option) => option.name}
            value={editedData.preferredContact || null}
            onChange={(_, newValue) => {
              handleEditChange("preferredContact", newValue);
            }}
            size="small"
            sx={{ width: "50%" }}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </ListItem>
        <Divider />
        <ListItem key="email">
          <ListItemText
            primary="Preferred Contact Email"
            sx={{ width: "50%" }}
          />
          <ListItemText sx={{ width: "50%" }}>
            <a
              href={`mailto:${
                editedData?.preferredContact?.email ?? ""
              }?subject=Job%20Reference%20${editedData?.name ?? ""}`}
            >
              {editedData?.preferredContact?.email ?? ""}
            </a>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="phone">
          <ListItemText
            primary="Preferred Contact Phone"
            sx={{ width: "50%" }}
          />
          <ListItemText sx={{ width: "50%" }}>
            {editedData?.preferredContact?.phoneNumber ?? ""}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="otherClients">
          <ListItemText primary="Associated Clients" sx={{ width: "50%" }} />
          <Stack sx={{ width: "50%" }}>
            <ListItemText>
              {(editedData.otherClients || data.otherClients || []).map(
                (client, index, array) => (
                  <React.Fragment key={client._id}>
                    <span
                      onClick={() => handleClientClick(client._id)}
                      style={{
                        cursor: "pointer",
                        color: theme.palette.secondary[100],
                        textDecoration: "underline",
                      }}
                    >
                      {client.businessName}
                    </span>
                    {index < array.length - 1 ? ", " : ""}
                  </React.Fragment>
                )
              )}
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => setIsClientsDialog(true)}>
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </Stack>
        </ListItem>
      </List>
      <AssignClientsDialog
        data={data}
        editedData={editedData}
        open={isClientsDialog}
        onClose={() => setIsClientsDialog(false)}
        handleEditChange={handleEditChange}
      />
    </Paper>
  );
};

export default ClientDetailsPanel;
