import React, { useState } from "react";
import {
  Divider,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditableContainer from "../EditableContainer";
import { capitalizeFirstLetter } from "../usefulFunctions";

const PlanningPanel = ({ data, editedData, handleEditChange, changesMade }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  const handleStepChange = (index, field, value) => {
    const updatedPlan = [...(editedData.plan || [])];
    updatedPlan[index] = {
      ...updatedPlan[index],
      [field]: value,
    };
    handleEditChange("plan", updatedPlan);
  };

  const handleRemoveStep = (index) => {
    const updatedPlan = [...(editedData.plan || [])];
    updatedPlan.splice(index, 1);
    handleEditChange("plan", updatedPlan);
  };

  const planToDisplay = editedData.plan || data.plan;

  return (
    <EditableContainer
      title="Planning"
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <Divider />
      {isEditMode ? (
        // Edit Mode: Display and edit plan steps
        <>
          {editedData &&
            editedData.plan &&
            editedData.plan.map((step, index) => (
              <Box key={`step-box-${index}`}>
                <ListItem key={`step-${index}`}>
                  <ListItemText sx={{ width: "50%" }}>
                    <FormControl sx={{ width: "60%" }}>
                      <Select
                        value={step.name || ""}
                        onChange={(e) =>
                          handleStepChange(index, "name", e.target.value)
                        }
                      >
                        <MenuItem value="translation">Translation</MenuItem>
                        <MenuItem value="proofread">Proofread</MenuItem>
                        <MenuItem value="formatting">Formatting</MenuItem>
                        <MenuItem value="transcription">Transcription</MenuItem>
                        <MenuItem value="interpreting">Interpreting</MenuItem>
                        <MenuItem value="editing">Editing</MenuItem>
                        <MenuItem value="certification">Certification</MenuItem>
                        <MenuItem value="notarisation">Notarisation</MenuItem>
                        <MenuItem value="legalisation">Legalisation</MenuItem>
                        <MenuItem value="wet Signature">Wet Signature</MenuItem>
                        <MenuItem value="hard Copy Delivery">
                          Hard Copy Delivery
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </ListItemText>
                  <ListItemText sx={{ width: "50%" }}>
                    {/* Start Date/Time */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <DatePicker
                          size="small"
                          label="Start Date"
                          value={step.startDate ? dayjs(step.startDate) : null}
                          onChange={(newDate) =>
                            handleStepChange(
                              index,
                              "startDate",
                              newDate ? newDate.toISOString() : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <TimePicker
                          size="small"
                          label="Start Time"
                          value={step.startDate ? dayjs(step.startDate) : null}
                          onChange={(newTime) =>
                            handleStepChange(
                              index,
                              "startDate",
                              newTime
                                ? dayjs(step.startDate)
                                    .set("hour", newTime.hour())
                                    .set("minute", newTime.minute())
                                    .toISOString()
                                : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        mt={2}
                      >
                        <DatePicker
                          size="small"
                          label="Due Date"
                          value={step.dueDate ? dayjs(step.dueDate) : null}
                          onChange={(newDate) =>
                            handleStepChange(
                              index,
                              "dueDate",
                              newDate ? newDate.toISOString() : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <TimePicker
                          size="small"
                          label="Due Time"
                          value={step.dueDate ? dayjs(step.dueDate) : null}
                          onChange={(newTime) =>
                            handleStepChange(
                              index,
                              "dueDate",
                              newTime
                                ? dayjs(step.dueDate)
                                    .set("hour", newTime.hour())
                                    .set("minute", newTime.minute())
                                    .toISOString()
                                : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </ListItemText>
                  <ListItemText sx={{ ml: "1rem" }}>
                    <IconButton
                      onClick={() => handleRemoveStep(index)}
                      color="error"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemText>
                </ListItem>
                <Divider />
              </Box>
            ))}
          <ListItem sx={{ justifyContent: "center" }}>
            <Stack>
              {editedData.plan.length < 1 && (
                <Typography variant="caption">
                  Add your first step using the button below
                </Typography>
              )}
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "0.5rem" }}
              >
                <IconButton
                  aria-label="add"
                  onClick={() =>
                    handleEditChange("plan", [
                      ...(editedData.plan || []),
                      { name: "", dueDate: null, startDate: null },
                    ])
                  }
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Stack>
          </ListItem>
        </>
      ) : (
        // Display Mode: Display plan steps as text
        <>
          {planToDisplay && planToDisplay.length > 0 ? (
            planToDisplay.map((step, index) => (
              <Box key={`step-box-${index}`}>
                <ListItem key={`step-${index}`}>
                  <ListItemText sx={{ width: "20%" }}>
                    <Typography variant="body1">
                      {capitalizeFirstLetter(step.name)}
                    </Typography>
                  </ListItemText>
                  <ListItemText sx={{ width: "15%" }}>
                    <Typography variant="body1">Start Date :</Typography>
                    <Typography variant="body1" sx={{ mt: ".5rem" }}>
                      Due Date :
                    </Typography>
                  </ListItemText>
                  <ListItemText sx={{ width: "15%" }}>
                    {/* Start Date/Time */}
                    <Typography variant="body1">
                      {step.startDate
                        ? new Intl.DateTimeFormat("en-UK", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(step.startDate))
                        : "-"}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: ".5rem" }}>
                      {step.dueDate
                        ? new Intl.DateTimeFormat("en-UK", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(step.dueDate))
                        : "-"}
                    </Typography>
                  </ListItemText>
                  <ListItemText
                    primary="Estimated Tasks Required: "
                    sx={{ width: "10%" }}
                  />
                  <ListItemText sx={{ width: "5%" }}>
                    {editedData?.targetLanguages?.length ??
                      data?.targetLanguages?.length ??
                      "-"}
                  </ListItemText>
                </ListItem>

                <Divider />
              </Box>
            ))
          ) : (
            <ListItem>
              <ListItemText>No steps added</ListItemText>
            </ListItem>
          )}
        </>
      )}
    </EditableContainer>
  );
};

export default PlanningPanel;
