import React, { useState, useEffect } from "react";
import MyCalendar from "components/MyCalendar";
import Header from "components/Header";
import { useGetRecentJobsQuery } from "state/api";
import { Box, useTheme } from "@mui/material";

const Calendar = () => {
  const [upcomingJobs, setUpcomingJobs] = useState([]);
  const { data, isLoading, refetch } = useGetRecentJobsQuery();
  const theme = useTheme();

  useEffect(() => {
    refetch(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && !isLoading) {
      const combinedData = [];
      Object.keys(data.jobs).forEach((jobKey) => {
        const job = data.jobs[jobKey];
        combinedData.push({
          title: job.name,
          start: job.dueDate,
          id: job._id,
          description: job.description,
          assignedTo: job.userIds,
          type: "job",
        });
      });
      Object.keys(data.tasks).forEach((taskKey) => {
        const task = data.tasks[taskKey];
        combinedData.push({
          title: task.name,
          start: task.dueDate,
          id: task._id,
          description: task.description,
          assignedTo: task.vendorIds,
          type: "task",
          job: task.jobId,
        });
      });
      setUpcomingJobs(combinedData);
    } // eslint-disable-next-line
  }, [data]);

  return (
    data &&
    !isLoading && (
      <Box m="1rem 2.5rem 0rem 2.5rem">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px="2rem"
        >
          <Header title="Calendar" subtitle="View your upcoming due dates." />
        </Box>
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <MyCalendar events={upcomingJobs} />
        </Box>
      </Box>
    )
  );
};

export default Calendar;
