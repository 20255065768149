import React from "react";
import ReactRouterPrompt from "react-router-prompt";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

const UnsavedChanges = ({ isDataChanged }) => {
  return (
    <ReactRouterPrompt when={isDataChanged}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <Dialog
            open={isActive}
            onClose={onCancel}
            aria-labelledby="leave-dialog-title"
            aria-describedby="leave-dialog-description"
          >
            <DialogTitle id="leave-dialog-title">Unsaved Changes</DialogTitle>
            <DialogContent>
              <DialogContentText id="leave-dialog-description">
                You have unsaved changes. Are you sure you want to leave this
                page?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="secondary">
                Go Back
              </Button>
              <Button
                onClick={() => {
                  onConfirm();
                }}
                color="warning"
              >
                Leave
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </ReactRouterPrompt>
  );
};

export default UnsavedChanges;
