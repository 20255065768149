import React from "react";
import { Paper, Box, Typography, Button, useTheme } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";

const EditableContainer = ({
  title,
  handleEditClick,
  isEditMode,
  children,
}) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "1rem",
        bgcolor: theme.palette.background.alt,
      }}
    >
      <Box
        display="flex"
        sx={{ padding: "1rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">{title}</Typography>
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.secondary[300],
            borderColor: theme.palette.secondary[300],
          }}
          onClick={handleEditClick}
        >
          {isEditMode ? <CheckIcon /> : <EditIcon />}
        </Button>
      </Box>
      {children}
    </Paper>
  );
};

export default EditableContainer;
