import React from "react";
import { Grid, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useGetPublicFileMutation } from "state/api";
import Spinner from "components/Spinner";

const TermsSection = ({
  organisation,
  agreeToReferees,
  setAgreeToReferees,
  agreeToTerms,
  setAgreeToTerms,
  agreeToPrivacyPolicy,
  setAgreeToPrivacyPolicy,
  errors,
}) => {
  const [downloadVendorFile] = useGetPublicFileMutation();

  const handleFileDownload = async (route, filename) => {
    try {
      const {
        data: { signedUrl },
      } = await downloadVendorFile({ route });

      // Use the signed URL to initiate the file download
      const link = document.createElement("a");
      link.href = signedUrl;
      link.target = "_blank";
      link.download = filename;
      link.click();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  if (!organisation) {
    return <Spinner />;
  }

  return (
    <>
      <Grid item xs={12} sm={12} sx={{ mt: "3rem" }}>
        {(errors.agreeToTerms ||
          errors.agreeToPrivacyPolicy ||
          errors.agreeToReferees) && (
          <Typography
            variant="body2"
            color="#df0000"
            textAlign="center"
            mb="1rem"
          >
            You must agree to all of {organisation.name}'s Terms & Conditions
          </Typography>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeToReferees}
              onChange={(e) => {
                errors.agreeToReferees = false;
                setAgreeToReferees(e.target.checked);
              }}
              color="primary"
            />
          }
          label={`By ticking this box you agree to give ${organisation.name} permission to contact the relevant referees and institutions to check your references and qualifications.`}
          sx={{ mt: 1 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeToTerms}
              onChange={(e) => {
                errors.agreeToTerms = false;
                setAgreeToTerms(e.target.checked);
              }}
              color="primary"
            />
          }
          label={
            <span>
              By ticking this box, I agree to{" "}
              <a
                href={`${process.env.REACT_APP_PUBLIC_GCLOUD_URL}public/${organisation.externalExt}/termsAndConditions/termsAndConditions.pdf`}
                target="_blank"
              >
                {organisation.name}'s Terms & Conditions
              </a>
              .
            </span>
          }
          sx={{ mt: 1 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeToPrivacyPolicy}
              onChange={(e) => {
                errors.agreeToPrivacyPolicy = false;
                setAgreeToPrivacyPolicy(e.target.checked);
              }}
              color="primary"
            />
          }
          label={
            <span>
              By ticking this box, I agree to{" "}
              <a
                href={`${process.env.REACT_APP_PUBLIC_GCLOUD_URL}public/${organisation.externalExt}/privacyPolicy/privacyPolicy.pdf`}
                target="_blank"
              >
                {organisation.name}'s Privacy Policy
              </a>
              .
            </span>
          }
          sx={{ mt: 1 }}
        />
      </Grid>
    </>
  );
};

export default TermsSection;
