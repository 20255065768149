import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import {
  useGetTaskQuery,
  usePutTaskMutation,
  useDeleteTaskMutation,
  useGetJobsQuery,
  useGetLanguagesQuery,
} from "state/api";
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  TextField,
  Divider,
  ListItem,
  List,
  ListItemText,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemSecondaryAction,
  IconButton,
  DialogContentText,
  Tabs,
  Tab,
} from "@mui/material";
import { useTheme } from "@mui/material";
import Header from "components/Header";
import ActionsMenu from "components/ActionsMenu";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import UnsavedChanges from "components/UnsavedChanges";
import AssignTranslatorDialog from "components/dialogs/AssignTranslatorDialog";
import { useSnackbar } from "components/Snackbar";
import Spinner from "components/Spinner";
import FinanceDetailsPanel from "components/task-page/FinanceDetailsPanel";
import TimeTrackingPanel from "components/task-page/TimeTrackingPanel";

const Task = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: languagesData } = useGetLanguagesQuery();
  const [putTaskMutation] = usePutTaskMutation();
  const [deleteTaskMutation] = useDeleteTaskMutation();
  const getJobsQuery = useGetJobsQuery();
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetTaskQuery(id);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isMoveDialogOpen, setIsMoveDialogOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isVendorsModalOpen, setIsVendorsModalOpen] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    if (data) {
      setEditedData({ ...data });
    }
  }, [data]);

  useEffect(() => {
    refetch(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getJobsQuery.data) {
      setJobs(getJobsQuery.data);
    }
  }, [data, getJobsQuery.data]);

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const openMoveDialog = () => {
    setIsMoveDialogOpen(true);
  };

  const closeMoveDialog = () => {
    setIsMoveDialogOpen(false);
  };

  const handleJobChange = (event, newValue) => {
    setSelectedJob(newValue);
  };

  const handleSaveJob = () => {
    handleEditChange("jobId", selectedJob);
    setIsDataChanged(true);
    setIsMoveDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteTaskMutation(id).then((res) => {
        navigate(`/jobs/${editedData.jobId._id}`);
      });
      // Handle success or any other actions after successful delete
    } catch (error) {
      showSnackbar("Error deleting task", "error");
    }
  };

  const handleEditNotes = () => {
    if (isEditingNotes) {
      setIsDataChanged(true);
    }
    setIsEditingNotes(!isEditingNotes);
  };

  const handleAddNote = () => {
    if (newNote.trim() !== "") {
      const timestamp = new Date().toISOString();
      const updatedNotes = [
        ...(editedData.notes || []),
        { note: newNote, timestamp },
      ];
      handleEditChange("notes", updatedNotes);
      setNewNote("");
    }
  };

  const handleDeleteNote = (index) => {
    const updatedNotes = [...editedData.notes];
    updatedNotes.splice(index, 1);
    handleEditChange("notes", updatedNotes);
  };

  const actions = [
    {
      label: "Move Task",
      onClick: () => {
        openMoveDialog();
      },
    },
    {
      label: "Delete Task",
      onClick: () => {
        openDeleteDialog();
      },
    },
  ];

  const handleSaveChanges = async () => {
    try {
      await putTaskMutation({
        id: id,
        updatedData: editedData,
      }).then((res) => {
        showSnackbar("Task updated successfully", "success");
        setIsDataChanged(false);
      });
    } catch (error) {
      showSnackbar("Error updating task", "error");
    }
  };

  const handleEditChange = (field, value) => {
    if (!isDataChanged) {
      setIsDataChanged(true);
    }
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleEditClick = () => {
    if (isEditMode) {
      setIsDataChanged(true);
    }
    setIsEditMode(!isEditMode);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <AssignTranslatorDialog
        data={editedData}
        editedData={editedData}
        open={isVendorsModalOpen}
        onClose={() => setIsVendorsModalOpen(false)}
        handleEditChange={handleEditChange}
      />
      <UnsavedChanges isDataChanged={isDataChanged} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title={data?.name} subtitle="Task details" />
        <Box>
          <ActionsMenu actions={actions} />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ ml: "2rem" }}
            onClick={handleSaveChanges}
            disabled={!isDataChanged}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ mb: "3rem", mt: "10px", width: "100%" }}
      >
        <Grid item xs={6}>
          <Paper
            elevation={3}
            sx={{ padding: "1rem", bgcolor: theme.palette.background.alt }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Details</Typography>
              <Button
                variant="outlined"
                sx={{
                  color: theme.palette.secondary[300],
                  borderColor: theme.palette.secondary[300],
                }}
                onClick={handleEditClick}
              >
                {isEditMode ? <CheckIcon /> : <EditIcon />}
              </Button>
            </Box>
            <List>
              <ListItem key="name">
                <ListItemText primary="Name" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      sx={{ width: "100%" }}
                      value={editedData.name || ""}
                      onChange={(e) => handleEditChange("name", e.target.value)}
                    />
                  ) : (
                    editedData.name ?? data.name
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="status">
                <ListItemText primary="Status" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <FormControl size="small" fullWidth>
                      <Select
                        value={editedData.status || ""}
                        onChange={(e) =>
                          handleEditChange("status", e.target.value)
                        }
                      >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="cancelled">Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    capitalizeFirstLetter(
                      editedData.status ?? data.status ?? ""
                    )
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="type">
                <ListItemText primary="Type" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <FormControl size="small" fullWidth>
                      <Select
                        value={editedData.type || ""}
                        onChange={(e) =>
                          handleEditChange("type", e.target.value)
                        }
                      >
                        <MenuItem value="translation">Translation</MenuItem>
                        <MenuItem value="proofread">Proofread</MenuItem>
                        <MenuItem value="formatting">Formatting</MenuItem>
                        <MenuItem value="transcription">Transcription</MenuItem>
                        <MenuItem value="interpreting">Interpreting</MenuItem>
                        <MenuItem value="editing">Editing</MenuItem>
                        <MenuItem value="certification">Certification</MenuItem>
                        <MenuItem value="notarisation">Notarisation</MenuItem>
                        <MenuItem value="legalisation">Legalisation</MenuItem>
                        <MenuItem value="wet Signature">Wet Signature</MenuItem>
                        <MenuItem value="hard Copy Delivery">
                          Hard Copy Delivery
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    capitalizeFirstLetter(editedData.type ?? data.type ?? "")
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="sourceLanguage">
                <ListItemText primary="Source Language" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <Autocomplete
                      options={languagesData}
                      size="small"
                      getOptionLabel={(language) => language.name}
                      value={editedData.sourceLanguage || null}
                      onChange={(e, newValue) =>
                        handleEditChange("sourceLanguage", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a source language"
                        />
                      )}
                    />
                  ) : (
                    editedData.sourceLanguage?.name ??
                    data.sourceLanguage?.name ??
                    ""
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="targetLanguage">
                <ListItemText primary="Target Language" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <Autocomplete
                      options={languagesData}
                      size="small"
                      getOptionLabel={(language) => language.name}
                      value={editedData.targetLanguage || null}
                      onChange={(e, newValue) =>
                        handleEditChange("targetLanguage", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a target language"
                        />
                      )}
                    />
                  ) : (
                    editedData.targetLanguage?.name ??
                    data.targetLanguage?.name ??
                    ""
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="sourceWordCount">
                <ListItemText
                  primary="Source Word Count"
                  sx={{ width: "50%" }}
                />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      sx={{
                        width: "100%",
                      }}
                      type="number"
                      value={editedData.sourceWordCount || ""}
                      onChange={(e) =>
                        handleEditChange("sourceWordCount", e.target.value)
                      }
                    />
                  ) : (
                    editedData.sourceWordCount ?? ""
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="targetWordCount">
                <ListItemText
                  primary="Target Word Count"
                  sx={{ width: "50%" }}
                />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <TextField
                      size="small"
                      sx={{
                        width: "100%",
                      }}
                      type="number"
                      value={editedData.targetWordCount || ""}
                      onChange={(e) =>
                        handleEditChange("targetWordCount", e.target.value)
                      }
                    />
                  ) : (
                    editedData.targetWordCount ?? ""
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="startDate">
                <ListItemText primary="Start Date" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <DatePicker
                          size="small"
                          value={
                            editedData.startDate
                              ? dayjs(editedData.startDate)
                              : null
                          }
                          onChange={(newDate) =>
                            handleEditChange(
                              "startDate",
                              newDate ? newDate.toISOString() : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <TimePicker
                          size="small"
                          value={
                            editedData.startDate
                              ? dayjs(editedData.startDate)
                              : null
                          }
                          onChange={(newTime) =>
                            handleEditChange(
                              "startDate",
                              newTime
                                ? dayjs(editedData.startDate)
                                    .set("hour", newTime.hour())
                                    .set("minute", newTime.minute())
                                    .toISOString()
                                : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  ) : (
                    (editedData.startDate || data.startDate) &&
                    new Intl.DateTimeFormat("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      timeZoneName: "short",
                    }).format(new Date(editedData.startDate || data.startDate))
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="dueDate">
                <ListItemText primary="Due Date" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {isEditMode ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <DatePicker
                          size="small"
                          value={
                            editedData.dueDate
                              ? dayjs(editedData.dueDate)
                              : null
                          }
                          onChange={(newDate) =>
                            handleEditChange(
                              "dueDate",
                              newDate ? newDate.toISOString() : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <TimePicker
                          size="small"
                          value={
                            editedData.dueDate
                              ? dayjs(editedData.dueDate)
                              : null
                          }
                          onChange={(newTime) =>
                            handleEditChange(
                              "dueDate",
                              newTime
                                ? dayjs(editedData.dueDate)
                                    .set("hour", newTime.hour())
                                    .set("minute", newTime.minute())
                                    .toISOString()
                                : null
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  ) : (
                    (editedData.dueDate || data.dueDate) &&
                    new Intl.DateTimeFormat("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      timeZoneName: "short",
                    }).format(new Date(editedData.dueDate || data.dueDate))
                  )}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="assignedVendor">
                <ListItemText
                  primary="Assigned Translator(s)"
                  sx={{ width: "50%" }}
                />
                <Stack sx={{ width: "50%" }}>
                  <ListItemText>
                    {(editedData.vendorIds || data.vendorIds || [])
                      .map((vendor) => vendor.name)
                      .join(", ") ?? "No assigned translator assigned"}
                  </ListItemText>
                  {isEditMode && (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setIsVendorsModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </Stack>
              </ListItem>
              <Divider />
              <ListItem key="createdAt">
                <ListItemText primary="Created At" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {new Intl.DateTimeFormat("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZoneName: "short",
                  }).format(new Date(data.createdAt))}
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Translator Contact Details</Typography>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
              >
                {(editedData ?? data).vendorIds?.map((vendor, index) => (
                  <Tab key={vendor._id} label={vendor.name} value={index} />
                ))}
              </Tabs>
            </Box>
            <List>
              <ListItem key="name">
                <ListItemText primary="Name" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {((editedData ?? data).vendorIds &&
                    (editedData ?? data).vendorIds[tabValue]?.name) ??
                    ""}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="email">
                <ListItemText primary="Email" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {((editedData ?? data).vendorIds &&
                    (editedData ?? data).vendorIds[tabValue]?.email) ??
                    ""}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="phone">
                <ListItemText primary="Phone" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {((editedData ?? data).vendorIds &&
                    (editedData ?? data).vendorIds[tabValue]?.phoneNumber) ??
                    ""}
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              marginBottom: "1rem",
            }}
          >
            <Box
              display="flex"
              sx={{ padding: "1rem" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Job Details</Typography>
              <Button
                variant="outlined"
                sx={{
                  color: theme.palette.secondary[300],
                  borderColor: theme.palette.secondary[300],
                }}
                onClick={() =>
                  navigate(`/jobs/${(editedData ?? data).jobId._id}`)
                }
              >
                View
              </Button>
            </Box>
            <List>
              <ListItem key="name">
                <ListItemText primary="Name" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {(editedData ?? data).jobId?.name ?? "N/A"}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="status">
                <ListItemText primary="Status" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {(editedData ?? data).jobId?.status
                    ? capitalizeFirstLetter((editedData ?? data).jobId.status)
                    : "N/A"}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="dueDate">
                <ListItemText primary="Due Date" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {(editedData ?? data).jobId?.dueDate
                    ? new Intl.DateTimeFormat("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZoneName: "short",
                      }).format(new Date((editedData ?? data).jobId.dueDate))
                    : "N/A"}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem key="urgency">
                <ListItemText primary="Urgency" sx={{ width: "50%" }} />
                <ListItemText sx={{ width: "50%" }}>
                  {(editedData ?? data).jobId?.urgency
                    ? capitalizeFirstLetter((editedData ?? data).jobId.urgency)
                    : "N/A"}
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
          <Box sx={{ mb: "1rem" }}>
            <TimeTrackingPanel
              editedData={editedData}
              handleEditChange={handleEditChange}
              changesMade={() => setIsDataChanged(true)}
            />
          </Box>
          <FinanceDetailsPanel
            editedData={editedData}
            handleEditChange={handleEditChange}
            changesMade={() => setIsDataChanged(true)}
          />
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              bgcolor: theme.palette.background.alt,
              marginTop: "1rem",
            }}
          >
            <Box display="flex" flexDirection="column" sx={{ padding: "1rem" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h4">Notes</Typography>
                <Button
                  variant="outlined"
                  sx={{
                    color: theme.palette.secondary[300],
                    borderColor: theme.palette.secondary[300],
                  }}
                  onClick={handleEditNotes}
                >
                  {isEditingNotes ? <CheckIcon /> : <EditIcon />}
                </Button>
              </Box>
              <List>
                <Divider />
                {(editedData.notes || data.notes).map((note, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <ListItemText>
                        <Stack>
                          <Typography variant="caption">
                            {new Intl.DateTimeFormat("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                            }).format(new Date(note.timestamp))}
                          </Typography>
                          {note.note}
                        </Stack>
                      </ListItemText>
                      {isEditingNotes && (
                        <IconButton
                          color="error"
                          aria-label="delete"
                          onClick={() => handleDeleteNote(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                    {index !== (editedData.notes || data.notes).length - 1 && (
                      <Divider />
                    )}
                  </React.Fragment>
                ))}
                {isEditingNotes && (
                  <ListItem>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        placeholder="Add note"
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                      />
                      <IconButton
                        color="secondary"
                        aria-label="add"
                        onClick={handleAddNote}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                )}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this task? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              closeDeleteDialog();
              handleDelete();
            }}
            color="warning"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isMoveDialogOpen}
        onClose={closeMoveDialog}
        aria-labelledby="edit-job-dialog-title"
        aria-describedby="edit-job-dialog-description"
      >
        <DialogTitle id="edit-job-dialog-title">Edit Job</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="edit-job-dialog-description"
            sx={{
              mb: "2rem",
            }}
          >
            To move this task to another job, select a job from the list below -
            remember to save your changes!
          </DialogContentText>
          <Autocomplete
            options={jobs}
            getOptionLabel={(job) => job.name}
            value={selectedJob}
            onChange={handleJobChange}
            renderInput={(params) => (
              <TextField {...params} label="Select a job" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMoveDialog} color="warning">
            Cancel
          </Button>
          <Button onClick={handleSaveJob} color="success">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Task;
