import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useGetClientsQuery, useCreateClientMutation } from "state/api";
import Header from "components/Header";
import ActionsMenu from "components/ActionsMenu";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Spinner from "components/Spinner";

const Clients = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filterModel, setFilterModel] = useState({ items: [] });
  const { data, isLoading, refetch } = useGetClientsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    filter: JSON.stringify(filterModel.items),
  });
  const [createClientMutation] = useCreateClientMutation();
  const [columnsVisibility, setColumnsVisibility] = useState({
    businessName: true,
    referenceNumber: true,
    promotion: true,
    email: true,
    phoneNumber: true,
  });

  // Fetch jobs when component mounts and whenever page, pageSize, sort, or search changes
  useEffect(() => {
    refetch();
  }, [page, pageSize, sort, search, refetch]);

  // Refetch jobs when the page becomes visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refetch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [refetch]);

  const createNewClient = async () => {
    try {
      await createClientMutation().then((res) => {
        if (res.data.clientId) {
          navigate("/clients/" + res.data.clientId);
        }
      });
    } catch (error) {
      console.error("Error creating client:", error);
    }
  };

  const actions = [
    {
      label: "Create New Client",
      onClick: () => createNewClient(),
    },
  ];

  const columns = [
    {
      field: "businessName",
      headerName: "Business Name",
      flex: 0.3,
    },
    {
      field: "promotion",
      headerName: "Promotion",
      flex: 0.2,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 0.3,
    },
  ];

  const toggleColumnVisibility = (field) => {
    setColumnsVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const getColumns = () => {
    return columns.map((column) => ({
      ...column,
      hide: !columnsVisibility[column.field],
    }));
  };

  // Function to update filterModel, passed down to DataGridCustomToolbar
  const handleFilterModelChange = (newModel) => {
    setFilterModel(newModel);
  };

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title="Clients" subtitle="List of clients" />
        <ActionsMenu actions={actions} />
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data.clients || []}
          sx={{
            padding: "1rem",
            backgroundColor: theme.palette.background.alt,
            ".MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              borderTop: "2px solid rgba(224, 224, 224, 1)",
              overflowX: "hidden",
            },
          }}
          columns={getColumns()}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setSearch,
              columns,
              columnsVisibility,
              toggleColumnVisibility,
              onFilterModelChange: handleFilterModelChange,
            },
          }}
          onRowClick={(e) => {
            navigate("/clients/" + e.id);
          }}
        />
      </Box>
    </Box>
  );
};

export default Clients;
