import React from "react";
import { Typography, Container, Paper, Button } from "@mui/material";

const ErrorPage = () => {
  const errorContainerStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };

  const paperStyles = {
    padding: "2rem",
    textAlign: "center",
  };

  const buttonStyles = {
    marginTop: "1rem",
  };

  return (
    <Container maxWidth="sm" sx={errorContainerStyles}>
      <Paper elevation={3} sx={paperStyles}>
        <Typography variant="h5">Oh no! This page doesn't exist! :(</Typography>
        <Typography>
          Please make a note of how you got here and forward it to{" "}
          <a href="mailto:jacksanders891@gmail.com">jacksanders891@gmail.com</a>
        </Typography>
        <Typography>
          For now, please head back to the login page and attempt to log back in
          - this should fix the issue
        </Typography>
        <Typography>Apologies for the inconvenience</Typography>
        <Button
          variant="contained"
          color="primary"
          href="/login" // Replace with the actual login page URL
          sx={buttonStyles}
        >
          Go to Login
        </Button>
      </Paper>
    </Container>
  );
};

export default ErrorPage;
