import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const GenerateTaskPopup = ({
  isOpen,
  handleClose,
  generate,
  proposedTasks,
}) => {
  const columns = [
    { field: "name", headerName: "Task Name", flex: 1 },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 0.5,
      valueGetter: (params) => {
        const date = params.value;
        if (!date || !Number.isFinite(new Date(date).getTime())) {
          return "N/A";
        }
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(date));
      },
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 0.5,
      valueGetter: (params) => {
        const date = params.value;
        if (!date || !Number.isFinite(new Date(date).getTime())) {
          return "N/A";
        }
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(date));
      },
    },
    { field: "type", headerName: "Type", flex: 0.5 },
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="generate-dialog-title"
      aria-describedby="generate-dialog-description"
      sx={{
        width: "100%",
        "& .MuiDialog-paper": {
          maxWidth: "none",
        },
      }} // Adjust maxWidth as needed
    >
      <Box
        sx={{
          margin: "1rem",
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1.5rem",
            paddingBottom: "2rem",
          }}
        >
          Generate Tasks
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confrim-dialog-description"
            sx={{
              marginBottom: "1rem",
            }}
          >
            This will generate tasks for each step of the plan for each target
            language.
          </DialogContentText>
          <div style={{ height: 400, width: "50rem" }}>
            <DataGrid
              rows={proposedTasks}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 20]}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              generate();
              handleClose();
            }}
            color="success"
          >
            Generate
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default GenerateTaskPopup;
