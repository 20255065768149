import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const DeleteVendorDialog = ({ isOpen, close, confirm }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          Are you sure you want to delete this translator? This action cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            close();
            confirm();
          }}
          color="warning"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteVendorDialog;
