import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import EditableContainer from "../EditableContainer";
import { useGetLanguagesQuery } from "state/api";

const LanguagePanel = ({ data, editedData, handleEditChange, changesMade }) => {
  const { data: languagesData } = useGetLanguagesQuery();
  const [isEditMode, setIsEditMode] = useState(false);
  const specialisations = [
    "Accounting",
    "Art",
    "Automotive",
    "Aviation",
    "Biology",
    "Chemistry",
    "Computer Software",
    "Computer Hardware",
    "Construction",
    "Cosmetics",
    "Energy",
    "Engineering",
    "Entertainment / Film",
    "Environment",
    "Family Law",
    "Financial",
    "Food & Drink",
    "Games – Video – Casino",
    "Geology",
    "Health",
    "Human rights",
    "Insurance",
    "Journalism",
    "Legal",
    "Life sciences",
    "Marine",
    "Maritime",
    "Marketing / market research",
    "Media",
    "Medical",
    "Metallurgy",
    "Military / Defence",
    "Mining",
    "Music Patents",
    "Pharmaceuticals",
    "Physics",
    "Poetry",
    "Literature",
    "Property / Real Estate",
    "Railways",
    "Shipping",
    "Sports",
    "Tax",
    "Tourism / Travel",
    "Transport",
  ];

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  return (
    <EditableContainer
      title="Languages"
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <Divider />
      <ListItem key="sourceLanguages">
        <ListItemText primary="Source Languages" sx={{ width: "50%" }} />
        <ListItemText sx={{ width: "50%" }}>
          {isEditMode ? (
            <Autocomplete
              multiple
              options={languagesData.filter((language) => {
                return (
                  !editedData.sourceLanguages.some(
                    (selectedLang) => selectedLang._id === language._id
                  ) ||
                  !data.sourceLanguages.some(
                    (selectedLang) => selectedLang._id === language._id
                  )
                );
              })}
              size="small"
              getOptionLabel={(language) => language.name}
              value={editedData.sourceLanguages || data.sourceLanguages || []}
              onChange={(e, newValue) =>
                handleEditChange("sourceLanguages", [...new Set(newValue)])
              }
              renderInput={(params) => (
                <TextField {...params} label="Select languages" />
              )}
            />
          ) : editedData.sourceLanguages?.length > 0 ||
            data.sourceLanguages?.length > 0 ? (
            (editedData.sourceLanguages || data.sourceLanguages).map(
              (language, index) => (
                <span key={language.id}>
                  {language.name}
                  {index <
                  (editedData.sourceLanguages || data.sourceLanguages).length -
                    1
                    ? ", "
                    : ""}
                </span>
              )
            )
          ) : (
            ""
          )}
        </ListItemText>
      </ListItem>
      <Divider />
      <ListItem key="targetLanguages">
        <ListItemText primary="Target Languages" sx={{ width: "50%" }} />
        <ListItemText sx={{ width: "50%" }}>
          {isEditMode ? (
            <Autocomplete
              multiple
              options={languagesData.filter((language) => {
                return (
                  !editedData.targetLanguages.some(
                    (selectedLang) => selectedLang._id === language._id
                  ) ||
                  !data.targetLanguages.some(
                    (selectedLang) => selectedLang._id === language._id
                  )
                );
              })}
              size="small"
              getOptionLabel={(language) => language.name}
              value={editedData.targetLanguages || data.targetLanguages || []}
              onChange={(e, newValue) =>
                handleEditChange("targetLanguages", [...new Set(newValue)])
              }
              renderInput={(params) => (
                <TextField {...params} label="Select languages" />
              )}
            />
          ) : editedData.targetLanguages?.length > 0 ||
            data.targetLanguages?.length > 0 ? (
            (editedData.targetLanguages || data.targetLanguages).map(
              (language, index) => (
                <span key={language.id}>
                  {language.name}
                  {index <
                  (editedData.targetLanguages || data.targetLanguages).length -
                    1
                    ? ", "
                    : ""}
                </span>
              )
            )
          ) : (
            ""
          )}
        </ListItemText>
      </ListItem>
      <Divider />
      <ListItem key="specialisation">
        <ListItemText primary="Specialisation" sx={{ width: "50%" }} />
        <ListItemText sx={{ width: "50%" }}>
          {isEditMode ? (
            <Autocomplete
              multiple
              options={specialisations.filter((specialisation) => {
                return (
                  !editedData.specialisations?.some(
                    (selectedLang) => selectedLang === specialisation
                  ) ||
                  !data.specialisations?.some(
                    (selectedLang) => selectedLang === specialisation
                  )
                );
              })}
              size="small"
              value={editedData.specialisations || data.specialisations || []}
              onChange={(e, newValue) =>
                handleEditChange("specialisations", [...new Set(newValue)])
              }
              renderInput={(params) => (
                <TextField {...params} label="Select specialisations" />
              )}
            />
          ) : editedData.specialisations?.length > 0 ||
            data.specialisations?.length > 0 ? (
            (editedData.specialisations || data.specialisations)?.map(
              (specialisation, index) => (
                <span key={specialisation.id}>
                  {specialisation}
                  {index <
                  (editedData.specialisations || data.specialisations).length -
                    1
                    ? ", "
                    : ""}
                </span>
              )
            )
          ) : (
            ""
          )}
        </ListItemText>
      </ListItem>
    </EditableContainer>
  );
};

export default LanguagePanel;
