import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { capitalizeFirstLetter } from "components/usefulFunctions";
import AddIcon from "@mui/icons-material/Add";

const QualificationsSection = ({
  formData,
  handleChange,
  errors,
  qualificationsRef,
}) => {
  const [newQualification, setNewQualification] = useState({
    type: "",
    link: "",
  });

  const handleAddQualification = () => {
    if (
      newQualification.type.trim() !== "" &&
      newQualification.link.trim() !== ""
    ) {
      const updatedQualifications = [
        ...(formData.qualificationsList || []),
        { ...newQualification },
      ];
      handleChange(updatedQualifications, "qualificationsList");
      setNewQualification({ type: "", link: "" });
    }
  };

  const handleRemoveQualification = (index) => {
    const newQualifications = [...(formData.qualificationsList || [])];
    newQualifications.splice(index, 1);
    handleChange(newQualifications, "qualificationsList");
  };

  return (
    <>
      <Grid ref={qualificationsRef} item xs={12} sm={12} sx={{ mt: "3rem" }}>
        <Divider sx={{ my: 2, mb: "3rem" }} />
        <Typography variant="h6" gutterBottom>
          Qualifications
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please list your qualifications and certifications.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <List>
          {formData.qualificationsList.length > 0 &&
            formData.qualificationsList
              ?.slice(0, -1)
              .map((qualification, index) => (
                <Box>
                  <ListItem key={`qualification-${index}`} sx={{ px: 0 }}>
                    <ListItemText sx={{ width: "80%" }}>
                      {capitalizeFirstLetter(qualification.type)} -{" "}
                      <a
                        href={qualification.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {qualification.link}
                      </a>
                    </ListItemText>
                    <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() => handleRemoveQualification(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                  <Divider sx={{ mb: "1rem" }} />
                </Box>
              ))}
          <ListItem sx={{ px: 0 }}>
            <FormControl size="small" sx={{ width: "50%", mr: 2 }}>
              <InputLabel htmlFor="qualifications">
                Qualification Type
              </InputLabel>
              <Select
                label="Qualification Type"
                value={newQualification.type}
                onChange={(e) => {
                  setNewQualification({
                    ...newQualification,
                    type: e.target.value,
                  });
                  handleChange(
                    [
                      ...formData.qualificationsList.slice(0, -1),
                      { type: e.target.value, link: newQualification.link },
                    ],
                    "qualificationsList"
                  );
                }}
              >
                <MenuItem value="translation Degree">
                  Translation Degree
                </MenuItem>
                <MenuItem value="interpreting Degree">
                  Interpreting Degree
                </MenuItem>
                <MenuItem value="language Degree">Language Degree</MenuItem>
                <MenuItem value="nRSPI Listing">NRSPI Listing</MenuItem>
                <MenuItem value="professional Linguist Membership">
                  Professional Linguist Membership
                </MenuItem>
                <MenuItem value="sworn Translator Status">
                  Sworn Translator Status
                </MenuItem>
                <MenuItem value="official Government Translator Status">
                  Official Government Translator Status
                </MenuItem>
                <MenuItem value="official Government Interpreter Status">
                  Official Government Interpreter Status
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "50%" }}
              label="Qualification Link"
              size="small"
              value={newQualification.link}
              onChange={(e) => {
                setNewQualification({
                  ...newQualification,
                  link: e.target.value,
                });
                handleChange(
                  [
                    ...formData.qualificationsList.slice(0, -1),
                    { type: newQualification.type, link: e.target.value },
                  ],
                  "qualificationsList"
                );
              }}
              error={errors.qualifications}
            />
          </ListItem>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "1rem",
            }}
          >
            <IconButton
              color="secondary"
              aria-label="add"
              onClick={handleAddQualification}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </List>
      </Grid>
    </>
  );
};

export default QualificationsSection;
