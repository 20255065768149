import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useGetTranslatorsQuery, useCreateTranslatorMutation } from "state/api";
import Header from "components/Header";
import ActionsMenu from "components/ActionsMenu";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Spinner from "components/Spinner";
import { useSnackbar } from "components/Snackbar";
import { capitalizeFirstLetter } from "components/usefulFunctions";

const Translators = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [translatorExists, setTranslatorExists] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [filterModel, setFilterModel] = useState({ items: [] });
  const { data, isLoading, refetch } = useGetTranslatorsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    pending: false,
    filter: JSON.stringify(filterModel.items),
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [createTranslatorMutation] = useCreateTranslatorMutation();
  const [newTranslatorData, setNewTranslatorData] = useState({
    name: "",
    email: "",
  });
  const [columnsVisibility, setColumnsVisibility] = useState({
    name: true,
    email: true,
    languages: true,
    targetLanguages: true,
    specialty: true,
    country: true,
    services: true,
    trusted: true,
  });

  // Fetch jobs when component mounts and whenever page, pageSize, sort, or search changes
  useEffect(() => {
    refetch();
  }, [page, pageSize, sort, search, refetch]);

  // Refetch jobs when the page becomes visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refetch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [refetch]);

  const handleDialogConfirm = async () => {
    try {
      if (!translatorExists) {
        // Check if name exists already
        setSearch(newTranslatorData.name);
        const refetchResult = await refetch();

        if (
          refetchResult.data.vendors.length > 0 &&
          refetchResult.data.vendors.some(
            (vendor) =>
              vendor.name.toLowerCase() === newTranslatorData.name.toLowerCase()
          )
        ) {
          // Translator already exists, show warning and confirm again
          setTranslatorExists(true);
          return;
        }
      }

      // If translator does not exist or user confirms again, create the translator
      const createResult = await createTranslatorMutation({
        name: newTranslatorData.name,
        email: newTranslatorData.email,
      });

      if (createResult.data.translatorId) {
        navigate("/translators/" + createResult.data.translatorId);
      }

      setOpenDialog(false);
    } catch (error) {
      showSnackbar(error.message, "error");
    }
  };

  const handleDialogClose = () => {
    setTranslatorExists(false);
    setOpenDialog(false);
  };

  const actions = [
    {
      label: "Create New Translator",
      onClick: () => setOpenDialog(true),
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.8,
    },
    {
      field: "languages",
      headerName: "Source Languages",
      flex: 1,
      renderCell: (params) => {
        const allLanguages = params.row.languages;
        if (allLanguages) {
          const sourceLanguages = allLanguages
            .map((language) =>
              language.sourceLanguages
                .map((sourceLanguage) => sourceLanguage.name)
                .join(", ")
            )
            .join(", ");

          return <Box>{sourceLanguages}</Box>;
        }
      },
    },
    {
      field: "targetLanguages",
      headerName: "Target Languages",
      flex: 1,
      renderCell: (params) => {
        const allLanguages = [...params.row.languages];
        if (allLanguages) {
          const targetLanguages = allLanguages
            .map((language) => language?.targetLanguage?.name)
            .join(", ");

          return <Box>{targetLanguages}</Box>;
        }
      },
    },
    {
      field: "services",
      headerName: "Services",
      flex: 0.7,
      renderCell: (params) => {
        const specialties = params.value || [];
        const servicesList = specialties.join(", ");

        return <Box>{capitalizeFirstLetter(servicesList)}</Box>;
      },
    },
    {
      field: "specialty",
      headerName: "Specialty",
      flex: 0.7,
      renderCell: (params) => {
        const specialties = params.value || [];
        const specialtyList = specialties.join(", ");

        return <Box>{capitalizeFirstLetter(specialtyList)}</Box>;
      },
    },
    {
      field: "rates",
      headerName: "Rates",
      flex: 0.7,
      renderCell: (params) => {
        const rates = params.row.rates || [];
        const rateList = rates
          .map((rate) => `${rate.category}: ${rate.cost} ${rate.unit}`)
          .join(", ");

        return <Box>{capitalizeFirstLetter(rateList)}</Box>;
      },
    },
    {
      field: "qualifications",
      headerName: "Qualifications",
      flex: 0.7,
      renderCell: (params) => {
        const qualifications = params.row.qualifications || [];
        const qualificationList = qualifications
          .map((qualification) => qualification.type)
          .join(", ");

        return <Box>{capitalizeFirstLetter(qualificationList)}</Box>;
      },
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.5,
    },
    {
      field: "trusted",
      headerName: "Trusted",
      flex: 0.2,
      renderCell: (params) => {
        const trusted = params.value || false;
        return <Box>{trusted ? "Yes" : "No"}</Box>;
      },
    },
  ];

  const toggleColumnVisibility = (field) => {
    setColumnsVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const getColumns = () => {
    return columns.map((column) => ({
      ...column,
      hide: !columnsVisibility[column.field],
    }));
  };

  // Function to update filterModel, passed down to DataGridCustomToolbar
  const handleFilterModelChange = (newModel) => {
    setFilterModel(newModel);
  };

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title="Translators" subtitle="List of translators" />
        <ActionsMenu actions={actions} />
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="create-dialog-title"
        aria-describedby="create-dialog-description"
      >
        <Box
          sx={{
            margin: "1rem",
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "1.5rem",
              paddingBottom: "2rem",
            }}
          >
            Create New Translator
          </DialogTitle>
          <DialogContent sx={{ width: "500px" }}>
            <DialogContentText
              id="confrim-dialog-description"
              sx={{
                marginBottom: "1rem",
              }}
            >
              Enter the translator's name and email address.
            </DialogContentText>
            {translatorExists ? (
              <DialogContentText
                color="error"
                sx={{
                  marginBottom: "2rem",
                }}
              >
                A vendor with this name already exists. Continue?
              </DialogContentText>
            ) : null}
            <TextField
              label="Name"
              sx={{
                marginBottom: "1rem",
              }}
              fullWidth
              value={newTranslatorData.name}
              onChange={(e) =>
                setNewTranslatorData({
                  ...newTranslatorData,
                  name: e.target.value,
                })
              }
            />
            <TextField
              label="Email"
              sx={{
                marginBottom: "1rem",
              }}
              fullWidth
              value={newTranslatorData.email}
              onChange={(e) =>
                setNewTranslatorData({
                  ...newTranslatorData,
                  email: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDialogConfirm();
              }}
              color="success"
            >
              {translatorExists ? "Continue" : "Create"}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            padding: "1rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data.vendors || []}
          sx={{
            backgroundColor: theme.palette.background.alt,
            ".MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              borderTop: "2px solid rgba(224, 224, 224, 1)",
              overflowX: "hidden",
            },
          }}
          columns={getColumns()}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setSearch,
              columns,
              columnsVisibility,
              toggleColumnVisibility,
              onFilterModelChange: handleFilterModelChange,
            },
          }}
          onRowClick={(e) => {
            navigate("/translators/" + e.id);
          }}
        />
      </Box>
    </Box>
  );
};

export default Translators;
