import React, { useState } from "react";
import {
  useTheme,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import EditableContainer from "../EditableContainer";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const AvailabilityPanel = ({ data, editedData, handleEditChange }) => {
  const theme = useTheme();
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleFieldChange = (day, value, property) => {
    const dayIndex = editedData.availability.findIndex(
      (item) => item.day === day
    );

    if (dayIndex !== -1) {
      if (property === "enabled" && value === false) {
        // Remove the item from the array if 'enabled' is false
        const updatedAvailability = editedData.availability.filter(
          (item) => item.day !== day
        );
        handleEditChange("availability", updatedAvailability);
      } else {
        const updatedAvailability = editedData.availability.map((item) => {
          if (item.day === day) {
            return {
              ...item,
              [property]:
                property !== "enabled" ? value.format("HH:mm") : value,
            };
          }
          return item;
        });
        handleEditChange("availability", updatedAvailability);
      }
    } else {
      const newDay = {
        day: day,
        startTime: "09:00",
        endTime: "17:00",
        enabled: true,
      };
      const updatedAvailability = [...editedData.availability, newDay];
      handleEditChange("availability", updatedAvailability);
    }
  };

  return (
    <EditableContainer
      title="Availability"
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <List>
        <Box>
          {isEditMode && (
            <>
              <ListItem>
                <ListItemText primary="Day" sx={{ width: "25%" }} />
                <ListItemText
                  primary="Start Time - End Time"
                  sx={{ width: "60%", textAlign: "center" }}
                />
                <ListItemText
                  primary="Enabled"
                  sx={{ textAlign: "center", width: "10%" }}
                />
              </ListItem>
              <Divider />
            </>
          )}
          {daysOfWeek.map((day) => (
            <div key={day}>
              <ListItem>
                {isEditMode ? (
                  <>
                    <ListItemText primary={day} sx={{ width: "30%" }} />
                    <ListItemText sx={{ width: "35%", ml: "1rem" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          size="small"
                          disabled={
                            !editedData.availability.find(
                              (item) => item.day === day
                            )?.enabled
                          }
                          value={
                            dayjs(
                              editedData.availability.find(
                                (item) => item.day === day
                              )?.startTime,
                              "HH:mm"
                            ) || null
                          }
                          onChange={(newTime) =>
                            handleFieldChange(day, newTime, "startTime")
                          }
                          renderInput={(params) => (
                            <>
                              <TextField {...params} label="Start Time" />
                            </>
                          )}
                        />
                      </LocalizationProvider>
                    </ListItemText>
                    <ListItemText sx={{ width: "35%", ml: "1rem" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          size="small"
                          disabled={
                            !editedData.availability.find(
                              (item) => item.day === day
                            )?.enabled
                          }
                          value={
                            dayjs(
                              editedData.availability.find(
                                (item) => item.day === day
                              )?.endTime,
                              "HH:mm"
                            ) || null
                          }
                          onChange={(newTime) =>
                            handleFieldChange(day, newTime, "endTime")
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </ListItemText>
                    <ListItemText
                      sx={{
                        width: "10%",
                        ml: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              editedData.availability.find(
                                (item) => item.day === day
                              )?.enabled || false
                            }
                            sx={{
                              "& .MuiSvgIcon-root": {
                                color: theme.palette.secondary.main,
                              },
                            }}
                            onChange={() => {
                              handleFieldChange(
                                day,
                                !editedData.availability.find(
                                  (item) => item.day === day
                                )?.enabled,
                                "enabled"
                              );
                            }}
                          />
                        }
                        sx={{ ml: ".2rem" }}
                      />
                    </ListItemText>
                  </>
                ) : (
                  <>
                    <ListItemText primary={day} sx={{ width: "20%" }} />
                    <ListItemText>
                      {editedData.availability?.find((item) => item.day === day)
                        ?.enabled
                        ? `${
                            editedData.availability?.find(
                              (item) => item.day === day
                            )?.startTime
                          } - ${
                            editedData.availability?.find(
                              (item) => item.day === day
                            )?.endTime
                          }`
                        : "Unavailable"}
                    </ListItemText>
                  </>
                )}
              </ListItem>
              <Divider />
            </div>
          ))}
        </Box>
      </List>
    </EditableContainer>
  );
};

export default AvailabilityPanel;
