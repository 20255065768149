import React, { useState, useEffect } from "react";
import { useGetTranslatorsQuery } from "state/api";
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Chip,
  TextField,
  Autocomplete,
  Stack,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

const AssignTranslatorDialog = ({
  data,
  editedData,
  open,
  onClose,
  handleEditChange,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    data: translatorsData,
    isFetching,
    refetch,
  } = useGetTranslatorsQuery({
    pending: false,
    search: searchTerm,
  });
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [recommendedVendors, setRecommendedVendors] = useState([]);
  const [showRecommendedOnly, setShowRecommendedOnly] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const timerId = setTimeout(() => {
      refetch();
    }, 300); // 300ms delay

    return () => clearTimeout(timerId); // Clear the timeout if the effect runs again before the delay is over
  }, [searchTerm, refetch]);

  useEffect(() => {
    if (translatorsData?.vendors) {
      setVendors(translatorsData.vendors);

      if (data?.vendorIds && !selectedVendors.length) {
        const alreadySelectedVendorIds = data?.vendorIds.map(
          (vendor) => vendor._id
        );
        const alreadySelectedVendors = translatorsData.vendors.filter((vendor) => {
          return alreadySelectedVendorIds?.includes(vendor._id);
        });
        setSelectedVendors(alreadySelectedVendors);
      }
    }
  }, [translatorsData, data]);

  useEffect(() => {
    if (vendors && editedData.sourceLanguage && editedData.targetLanguage) {
      const recommendedVendors = vendors.filter((vendor) =>
        vendor.languages?.some(
          (language) =>
            language.sourceLanguages.some(
              (sourceLang) => sourceLang._id === editedData.sourceLanguage._id
            ) && language.targetLanguage?._id === editedData.targetLanguage._id
        )
      );

      setRecommendedVendors(recommendedVendors);
    }
  }, [vendors, editedData.sourceLanguage, editedData.targetLanguage]);

  const handleAddVendor = (event, vendor) => {
    if (vendor) {
      setSelectedVendors((prevSelectedVendors) => [
        ...prevSelectedVendors,
        vendor,
      ]);
    }
  };

  const handleRemoveVendor = (vendorToRemove) => {
    setSelectedVendors((prevSelectedVendors) =>
      prevSelectedVendors.filter((vendor) => vendor._id !== vendorToRemove._id)
    );
  };

  const handleSaveVendors = () => {
    handleEditChange("vendorIds", selectedVendors);
    onClose();
  };

  // Filter vendors based on the checkbox value
  const filteredVendors = showRecommendedOnly ? recommendedVendors : vendors;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-client-dialog-title"
      aria-describedby="edit-client-dialog-description"
    >
      <DialogTitle id="edit-client-dialog-title">
        Edit Assigned Translators
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="edit-client-dialog-description"
          sx={{
            mb: "2rem",
          }}
        >
          To add or remove assigned translators from this task, use the below
          fields
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={showRecommendedOnly}
              onChange={() => setShowRecommendedOnly(!showRecommendedOnly)}
              sx={{
                "& .MuiSvgIcon-root": {
                  color: theme.palette.secondary.main,
                },
              }}
            />
          }
          sx={{ ml: ".2rem", mb: "1rem" }}
          label={
            <Typography variant="body2">
              Only show translators who have the required language pair
            </Typography>
          }
        />
        <Box sx={{ mb: "2rem" }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {selectedVendors.map((vendor) => (
              <Chip
                key={vendor._id}
                onClick={() =>
                  window.open(`/translators/${vendor._id}`, "_blank")
                }
                label={vendor.name}
                onDelete={() => handleRemoveVendor(vendor)}
                variant="outlined"
                size="small"
              />
            ))}
          </Stack>
        </Box>
        <Autocomplete
          options={filteredVendors.filter(
            (vendor) =>
              !selectedVendors.some((selected) => selected._id === vendor._id)
          )}
          getOptionLabel={(vendor) => vendor.name || ""}
          value={null}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          onChange={handleAddVendor}
          renderInput={(params) => (
            <TextField {...params} label="Search and assign translator" />
          )}
          isLoading={isFetching}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="warning">
          Cancel
        </Button>
        <Button onClick={handleSaveVendors} color="success">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignTranslatorDialog;
