import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useSnackbar } from "./Snackbar";

const OnlineStatusSnackbar = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(!navigator.onLine);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    }; // eslint-disable-next-line
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
    setIsSnackbarOpen(false);
    showSnackbar("You are back online", "success");
  };

  const handleOffline = () => {
    setIsOnline(false);
    setIsSnackbarOpen(true);
  };

  return (
    <>
      <SnackbarComponent />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={null}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert severity="error" onClose={() => setIsSnackbarOpen(false)}>
          {isOnline
            ? "You are back online."
            : "You are currently offline. Please check your internet connection."}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OnlineStatusSnackbar;
