import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  TextField,
  Divider,
  List,
  Stack,
  FormControl,
  Select,
  InputLabel,
  Autocomplete,
  useTheme,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import EditableContainer from "../EditableContainer";
import CurrencySelector from "../CurrencySelector";
import IncludeTax from "../IncludeTax";

function calculateROI(data) {
  const value = data.cost || 0;
  const tasks = data.tasks || [];

  // Calculate the total cost of all tasks
  const totalTaskCost = tasks.reduce(
    (total, task) => total + (task.cost || 0),
    0
  );

  // Calculate ROI as (totalTaskCost - cost) / cost
  const roi =
    value && totalTaskCost
      ? ((value - totalTaskCost) / totalTaskCost).toFixed(2)
      : "N/A";

  return roi;
}

const FinanceDetailsPanel = ({ editedData, handleEditChange, changesMade }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const theme = useTheme();

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  return (
    <EditableContainer
      title="Finance Details"
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <List>
        <ListItem key="cost">
          <ListItemText primary={"Value"} sx={{ width: "40%" }} />
          <ListItemText sx={{ width: "60%" }}>
            {isEditMode ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  size="small"
                  sx={{
                    width: "40%",
                  }}
                  value={editedData.cost || ""}
                  onChange={(e) => handleEditChange("cost", e.target.value)}
                />
                <Box sx={{ width: "30%" }}>
                  <CurrencySelector
                    selectedCurrency={editedData.currency}
                    onSelectCurrency={(currency) =>
                      handleEditChange("currency", currency)
                    }
                  />
                </Box>
                <IncludeTax
                  editedData={editedData}
                  handleEditChange={handleEditChange}
                />
              </Stack>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.cost ?? ""}
                  </Typography>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {editedData.currency ?? ""}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {editedData.plusVat ? "plus VAT" : ""}
                </Typography>
              </Box>
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="invoiceNumber">
          <ListItemText primary="Invoice Number" sx={{ width: "40%" }} />
          <ListItemText sx={{ width: "60%" }}>
            {isEditMode ? (
              <TextField
                size="small"
                sx={{ width: "100%" }}
                value={editedData.invoiceNumber || ""}
                onChange={(e) =>
                  handleEditChange("invoiceNumber", e.target.value)
                }
              />
            ) : (
              editedData.invoiceNumber
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="invoiceDate">
          <ListItemText primary="Invoice Date" sx={{ width: "40%" }} />
          <ListItemText sx={{ width: "60%" }}>
            {isEditMode ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <DatePicker
                    value={
                      editedData.invoiceDate
                        ? dayjs(editedData.invoiceDate)
                        : null
                    }
                    onChange={(newDate) =>
                      handleEditChange(
                        "invoiceDate",
                        newDate ? newDate.toISOString() : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TimePicker
                    size="small"
                    value={
                      editedData.invoiceDate
                        ? dayjs(editedData.invoiceDate)
                        : null
                    }
                    onChange={(newTime) =>
                      handleEditChange(
                        "invoiceDate",
                        newTime
                          ? dayjs(editedData.invoiceDate)
                              .set("hour", newTime.hour())
                              .set("minute", newTime.minute())
                              .toISOString()
                          : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            ) : (
              editedData.invoiceDate &&
              new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZoneName: "short",
              }).format(new Date(editedData.invoiceDate))
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="ROI">
          <ListItemText primary="ROI" sx={{ width: "40%" }} />
          <ListItemText sx={{ width: "60%" }}>
            {calculateROI(editedData)}
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
    </EditableContainer>
  );
};

export default FinanceDetailsPanel;
