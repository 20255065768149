import React, { useState } from "react";
import {
  useTheme,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import EditableContainer from "../EditableContainer";
import { useDownloadVendorFileMutation } from "state/api";

const FilesPanel = ({ data, editedData, handleEditChange }) => {
  const theme = useTheme();
  const [isEditMode, setIsEditMode] = useState(false);
  const [downloadVendorFile] = useDownloadVendorFileMutation();

  const handleFileDownload = async (route, filename) => {
    try {
      const {
        data: { signedUrl },
      } = await downloadVendorFile({ route });

      // Use the signed URL to initiate the file download
      const link = document.createElement("a");
      link.href = signedUrl;
      link.target = "_blank";
      link.download = filename;
      link.click();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <EditableContainer
      title={`Files`}
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <Divider />
      <List>
        {editedData.files?.map((file, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={file.filename}
              secondary={new Date(file.uploadedAt).toLocaleDateString("en-GB")}
            />
            <Button
              variant="outlined"
              sx={{
                color: theme.palette.secondary[300],
                borderColor: theme.palette.secondary[300],
              }}
              onClick={() => handleFileDownload(file.route, file.filename)}
            >
              View
            </Button>
          </ListItem>
        ))}
      </List>
    </EditableContainer>
  );
};

export default FilesPanel;
