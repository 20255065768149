import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  userId: "",
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state, action) => {
      if (action.payload === "dark" || action.payload === "light") {
        state.mode = action.payload;
      }
    },
    setThemeModeFromUserData: (state, action) => {
      state.mode = action.payload; // Set the theme mode from user data
    },
  },
});

export const { setMode, setThemeModeFromUserData } = globalSlice.actions;

export default globalSlice.reducer;
