import React, { useEffect, useState } from "react";
import {
  ListItem,
  ListItemText,
  TextField,
  Divider,
  FormControl,
  Select,
  MenuItem,
  List,
  ListItemSecondaryAction,
  IconButton,
  Stack,
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import AssignClientDialog from "../dialogs/AssignClientDialog";
import { useGetUsersQuery } from "state/api";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { capitalizeFirstLetter } from "../usefulFunctions";
import EditableContainer from "../EditableContainer";
import EditIcon from "@mui/icons-material/Edit";

const DetailsPanel = ({ data, editedData, handleEditChange, changesMade }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const getUsersQuery = useGetUsersQuery();
  const [users, setUsers] = useState([]);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const details = {
    sworn: "Sworn",
    notary: "Notarisation",
    certificate: "Certification",
    legalisation: "Legalisation",
  };

  useEffect(() => {
    if (getUsersQuery.data) {
      setUsers(getUsersQuery.data);

      const alreadySelectedUserIds = data?.userIds.map((user) => user._id);
      const alreadySelectedUsers = getUsersQuery.data.filter((user) => {
        return alreadySelectedUserIds?.includes(user._id);
      });
      setSelectedUsers(alreadySelectedUsers);
    }
  }, [getUsersQuery.data, data]);

  const handleSaveUsers = () => {
    handleEditChange("userIds", selectedUsers);
    setSelectedUsers([]);
    setIsUsersModalOpen(false);
  };

  const handleCloseUsersModal = () => {
    setIsUsersModalOpen(false);
  };

  const handleAddUser = (event, user) => {
    if (user) {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, user]);
    }
  };

  const handleRemoveUser = (userToRemove) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.filter((user) => user._id !== userToRemove._id)
    );
  };

  const handleEditClick = () => {
    if (isEditMode) {
      changesMade();
    }
    setIsEditMode(!isEditMode);
  };

  const handleEditClientClick = () => {
    setIsClientModalOpen(true);
  };

  const handleEditUsersClick = () => {
    getUsersQuery.refetch();
    setSelectedUsers(editedData.userIds || []);
    setIsUsersModalOpen(true);
  };

  return (
    <EditableContainer
      title={"Job Details"}
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <List>
        <ListItem key="id">
          <ListItemText primary={"Job ID"} sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                size="small"
                sx={{ width: "100%" }}
                value={editedData.name || ""}
                onChange={(e) => handleEditChange("name", e.target.value)}
              />
            ) : (
              editedData.name
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="status">
          <ListItemText primary="Status" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <FormControl size="small" fullWidth>
                <Select
                  value={editedData.status || ""}
                  onChange={(e) => handleEditChange("status", e.target.value)}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="delivered">Delivered</MenuItem>
                  <MenuItem value="invoiced">Invoiced</MenuItem>
                  <MenuItem value="closed">Complete</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
            ) : (
              capitalizeFirstLetter(editedData.status ?? "")
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="type">
          <ListItemText primary="Type" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <FormControl size="small" fullWidth>
                <Select
                  value={editedData.type || ""}
                  onChange={(e) => handleEditChange("type", e.target.value)}
                >
                  <MenuItem value="basic translation">
                    Basic Translation
                  </MenuItem>
                  <MenuItem value="advanced translation">
                    Advanced Translation
                  </MenuItem>
                  <MenuItem value="machine translation">
                    Machine Translation
                  </MenuItem>
                  <MenuItem value="transcription">Transcription</MenuItem>
                  <MenuItem value="interpreting">Interpreting</MenuItem>
                  <MenuItem value="editing">Editing</MenuItem>
                </Select>
              </FormControl>
            ) : (
              capitalizeFirstLetter(editedData.type ?? "")
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="urgency">
          <ListItemText primary="Urgency" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <FormControl size="small" fullWidth>
                <Select
                  value={editedData.urgency || ""}
                  onChange={(e) => handleEditChange("urgency", e.target.value)}
                >
                  <MenuItem value="standard">Standard</MenuItem>
                  <MenuItem value="faster">Faster</MenuItem>
                  <MenuItem value="urgent">Urgent</MenuItem>
                </Select>
              </FormControl>
            ) : (
              capitalizeFirstLetter(editedData.urgency ?? "")
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="wordCount">
          <ListItemText primary="Word Count" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                size="small"
                type="number"
                value={editedData.wordCount || ""}
                onChange={(e) => handleEditChange("wordCount", e.target.value)}
              />
            ) : (
              editedData.wordCount ?? ""
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="documentCount">
          <ListItemText primary="Document Count" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                size="small"
                type="number"
                value={editedData.documentCount || ""}
                onChange={(e) =>
                  handleEditChange("documentCount", e.target.value)
                }
              />
            ) : (
              editedData.documentCount ?? ""
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="details">
          <ListItemText primary="Details" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <Autocomplete
                multiple
                options={Object.values(details)}
                size="small"
                value={editedData.details || []}
                onChange={(_, newValue) =>
                  handleEditChange("details", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select details" />
                )}
              />
            ) : editedData.details?.length > 0 || data.details?.length > 0 ? (
              (editedData.details || data.details)?.map((detail, index) => (
                <span key={detail}>
                  {Object.values(detail)}
                  {index < (editedData.details || data.details).length - 1
                    ? ", "
                    : ""}
                </span>
              ))
            ) : (
              ""
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="dueDate">
          <ListItemText primary="Due Date" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <DatePicker
                    value={
                      editedData.dueDate ? dayjs(editedData.dueDate) : null
                    }
                    onChange={(newDate) =>
                      handleEditChange(
                        "dueDate",
                        newDate ? newDate.toISOString() : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TimePicker
                    size="small"
                    value={
                      editedData.dueDate ? dayjs(editedData.dueDate) : null
                    }
                    onChange={(newTime) =>
                      handleEditChange(
                        "dueDate",
                        newTime
                          ? dayjs(editedData.dueDate)
                              .set("hour", newTime.hour())
                              .set("minute", newTime.minute())
                              .toISOString()
                          : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            ) : (
              editedData.dueDate &&
              new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZoneName: "short",
              }).format(new Date(editedData.dueDate))
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="managingUser">
          <ListItemText primary="Managing Agent(s)" sx={{ width: "50%" }} />
          <Stack sx={{ width: "50%" }}>
            <ListItemText>
              {(editedData.userIds || []).map((user) => user.name).join(", ") ??
                "No managing agent assigned"}
            </ListItemText>
            {isEditMode && (
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={handleEditUsersClick}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </Stack>
        </ListItem>
        <Divider />
        <ListItem key="clientId">
          <ListItemText primary="Client" sx={{ width: "50%" }} />
          <Stack sx={{ width: "50%" }}>
            <ListItemText>
              {editedData.clientId?.businessName ?? ""}
            </ListItemText>
            {isEditMode && (
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={handleEditClientClick}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </Stack>
        </ListItem>
        <Divider />
        <ListItem key="clientReferenceNumber">
          <ListItemText primary="Client Job Reference" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <TextField
                size="small"
                sx={{ width: "100%" }}
                value={editedData.clientReferenceNumber || ""}
                onChange={(e) =>
                  handleEditChange("clientReferenceNumber", e.target.value)
                }
              />
            ) : (
              editedData.clientReferenceNumber
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="startDate">
          <ListItemText primary="Start Date" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <DatePicker
                    value={
                      editedData.startDate ? dayjs(editedData.startDate) : null
                    }
                    onChange={(newDate) =>
                      handleEditChange(
                        "startDate",
                        newDate ? newDate.toISOString() : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TimePicker
                    size="small"
                    value={
                      editedData.startDate ? dayjs(editedData.startDate) : null
                    }
                    onChange={(newTime) =>
                      handleEditChange(
                        "startDate",
                        newTime
                          ? dayjs(editedData.startDate)
                              .set("hour", newTime.hour())
                              .set("minute", newTime.minute())
                              .toISOString()
                          : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            ) : (
              editedData.startDate &&
              new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZoneName: "short",
              }).format(new Date(editedData.startDate))
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="deliveryDate">
          <ListItemText primary="Delivery Date" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {isEditMode ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <DatePicker
                    value={
                      editedData.deliveryDate
                        ? dayjs(editedData.deliveryDate)
                        : null
                    }
                    onChange={(newDate) =>
                      handleEditChange(
                        "deliveryDate",
                        newDate ? newDate.toISOString() : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TimePicker
                    size="small"
                    value={
                      editedData.deliveryDate
                        ? dayjs(editedData.deliveryDate)
                        : null
                    }
                    onChange={(newTime) =>
                      handleEditChange(
                        "deliveryDate",
                        newTime
                          ? dayjs(editedData.deliveryDate)
                              .set("hour", newTime.hour())
                              .set("minute", newTime.minute())
                              .toISOString()
                          : null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            ) : (
              editedData.deliveryDate &&
              new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZoneName: "short",
              }).format(new Date(editedData.deliveryDate))
            )}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem key="createdAt">
          <ListItemText primary="Created At" sx={{ width: "50%" }} />
          <ListItemText sx={{ width: "50%" }}>
            {new Intl.DateTimeFormat("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZoneName: "short",
            }).format(new Date(data.createdAt))}
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
      <AssignClientDialog
        data={editedData}
        editedData={editedData}
        open={isClientModalOpen}
        onClose={() => setIsClientModalOpen(false)}
        handleEditChange={handleEditChange}
      />
      <Dialog
        open={isUsersModalOpen}
        onClose={handleCloseUsersModal}
        aria-labelledby="edit-client-dialog-title"
        aria-describedby="edit-client-dialog-description"
      >
        <DialogTitle id="edit-client-dialog-title">
          Edit Managing Agents
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="edit-client-dialog-description"
            sx={{
              mb: "2rem",
            }}
          >
            To add or remove managing agents from this job, use the below fields
          </DialogContentText>
          <Box sx={{ mb: "2rem" }}>
            {selectedUsers.map((user) => (
              <Chip
                key={user._id}
                label={user.name}
                onDelete={() => handleRemoveUser(user)}
                variant="outlined"
              />
            ))}
          </Box>
          <Autocomplete
            options={users.filter(
              (user) =>
                !selectedUsers.some((selected) => selected._id === user._id)
            )}
            getOptionLabel={(user) => user.name}
            value={null}
            onChange={handleAddUser}
            renderInput={(params) => (
              <TextField {...params} label="Select managing agents" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUsersModal} color="warning">
            Cancel
          </Button>
          <Button onClick={handleSaveUsers} color="success">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </EditableContainer>
  );
};

export default DetailsPanel;
