import React, { useEffect, useState } from "react";
import { Box, useTheme, IconButton } from "@mui/material";
import { useGetJobsQuery, useCreateJobMutation } from "state/api";
import Header from "components/Header";
import ActionsMenu from "components/ActionsMenu";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Spinner from "components/Spinner";
import { capitalizeFirstLetter } from "components/usefulFunctions";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Jobs = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [statusVisibility, setStatussVisibility] = useState({
    open: true,
    pending: false,
    delivered: false,
    invoiced: false,
    closed: false,
    cancelled: false,
  });
  const { data, isLoading, refetch } = useGetJobsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    quote: false,
    status: JSON.stringify(
      Object.keys(statusVisibility).filter((status) => statusVisibility[status])
    ),
    filter: JSON.stringify(filterModel.items),
  });
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const [createJobMutation] = useCreateJobMutation();
  const [columnsVisibility, setColumnsVisibility] = useState({
    name: true,
    clientId: true,
    status: true,
    sourceLanguages: true,
    targetLanguages: true,
    dueDate: true,
    managingAgent: true,
    assignedVendors: true,
    urgency: true,
    tasks: false,
    type: true,
    cost: false,
    totalTaskCost: false,
    roi: false,
    openInNewTab: true,
  });

  // Fetch jobs when component mounts and whenever page, pageSize, sort, or search changes
  useEffect(() => {
    refetch();
  }, [page, search, refetch]);

  // Refetch jobs when the page becomes visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refetch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [refetch]);

  const actions = [
    {
      label: "Create New Job",
      onClick: async () => {
        try {
          await createJobMutation().then((res) => {
            if (res.data.jobId) {
              navigate("/jobs/" + res.data.jobId);
            }
          });
        } catch (error) {
          console.error("Error creating Job:", error);
        }
      },
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Job ID",
      flex: 0.2,
      filterable: false,
    },
    {
      field: "clientId",
      headerName: "Client",
      flex: 0.4,
      filterable: false,
      valueGetter: (params) => params.row.clientId?.businessName ?? "N/A",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.2,
      filterable: false,
      renderCell: (params) => {
        return capitalizeFirstLetter(params.value);
      },
    },
    {
      field: "sourceLanguages",
      headerName: "Source Language(s)",
      flex: 0.3,
      filterable: false,
      valueGetter: (params) => {
        return params.row.sourceLanguages
          .map((language) => language.name)
          .join(", ");
      },
      renderCell: (params) => {
        const allLanguages = [...params.row.sourceLanguages];
        const languageNames = allLanguages
          .map((language) => language.name)
          .join(", ");

        return <Box>{languageNames}</Box>;
      },
    },
    {
      field: "targetLanguages",
      headerName: "Target Language(s)",
      flex: 0.3,
      filterable: false,
      valueGetter: (params) => {
        return params.row.targetLanguages
          .map((language) => language.name)
          .join(", ");
      },
      renderCell: (params) => {
        const allLanguages = [...params.row.targetLanguages];
        const languageNames = allLanguages
          .map((language) => language.name)
          .join(", ");

        return <Box>{languageNames}</Box>;
      },
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 0.3,
      filterable: false,
      valueGetter: (params) => {
        const date = params.value;
        if (!date || !Number.isFinite(new Date(date).getTime())) {
          return "N/A";
        }
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(date));
      },
    },
    {
      field: "managingAgent",
      headerName: "Managing Agent",
      flex: 0.3,
      filterable: false,
      valueGetter: (params) => {
        return params.row.userIds.map((user) => user.name).join(", ");
      },
      renderCell: (params) => {
        const allUsers = [...params.row.userIds];
        const userNames = allUsers.map((user) => user.name).join(", ");

        return <Box>{userNames}</Box>;
      },
    },
    {
      field: "assignedVendors",
      headerName: "Assigned Vendor(s)",
      flex: 0.3,
      filterable: false,
      valueGetter: (params) => {
        const vendorNames = params.row.tasks.flatMap((task) =>
          task.vendorIds.map((vendor) => vendor.name)
        );
        return vendorNames.join(", ");
      },
      renderCell: (params) => {
        const allVendors = [
          ...params.row.tasks.map((task) =>
            task.vendorIds.map((vendor) => vendor.name)
          ),
        ];
        const vendorNames = allVendors.join(", ");

        return <Box>{vendorNames}</Box>;
      },
    },
    {
      field: "urgency",
      headerName: "Urgency",
      flex: 0.2,
      filterable: false,
      renderCell: (params) => {
        const urgency = params.value;
        return capitalizeFirstLetter(urgency);
      },
    },
    {
      field: "tasks",
      headerName: "Tasks",
      flex: 0.1,
      filterable: false,
      renderCell: (params) => {
        const tasks = params.value;
        // if tasks object is empty and the vendorIds array is empty, return N/A
        if (!tasks?.length) {
          return "N/A";
        }
        const closedTasks = tasks?.filter(
          (task) => task.status === "completed"
        ).length;
        const totalTasks = tasks?.length;

        return `${closedTasks}/${totalTasks}`;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.3,
      filterable: false,
      renderCell: (params) => {
        const type = params.value;
        return capitalizeFirstLetter(type);
      },
    },
    {
      field: "cost",
      headerName: "Value",
      flex: 0.2,
      filterable: false,
      renderCell: (params) => {
        const cost = params.value;
        const currency = params.row.currency;

        return `${cost ?? ""} ${currency ?? ""}`;
      },
    },
    {
      field: "totalTaskCost",
      headerName: "Cost",
      flex: 0.2,
      filterable: false,
      valueGetter: (params) => {
        const tasks = params.row.tasks || [];

        // Calculate the total cost of all tasks
        const totalTaskCost = tasks.reduce(
          (total, task) => total + (task.cost || 0),
          0
        );

        return totalTaskCost;
      },
    },
    {
      field: "roi",
      headerName: "ROI",
      flex: 0.1,
      filterable: false,
      valueGetter: (params) => {
        const value = params.row.cost || 0;
        const tasks = params.row.tasks || [];

        // Calculate the total cost of all tasks
        const totalTaskCost = tasks.reduce(
          (total, task) => total + (task.cost || 0),
          0
        );

        // Calculate ROI as (totalTaskCost - cost) / cost
        const roi =
          value && totalTaskCost
            ? ((value - totalTaskCost) / totalTaskCost).toFixed(2)
            : "N/A";

        return roi;
      },
    },
    {
      field: "openInNewTab",
      headerName: "Open",
      sortable: false,
      filterable: false,
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            window.open("/jobs/" + params.row._id, "_blank");
          }}
          color="secondary"
        >
          <OpenInNewIcon />
        </IconButton>
      ),
    },
  ];

  const toggleColumnVisibility = (field) => {
    setColumnsVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const toggleStatusVisibility = (field) => {
    setStatussVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const getColumns = () => {
    return columns.map((column) => ({
      ...column,
      hide: !columnsVisibility[column.field],
    }));
  };

  // Function to update filterModel, passed down to DataGridCustomToolbar
  const handleFilterModelChange = (newModel) => {
    setFilterModel(newModel);
  };

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title="Jobs" subtitle="List of jobs" />
        <ActionsMenu actions={actions} />
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.jobs) || []}
          sx={{
            padding: "1rem",
            backgroundColor: theme.palette.background.alt,
            ".MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              borderTop: "2px solid rgba(224, 224, 224, 1)",
              overflowX: "hidden",
            },
          }}
          columns={getColumns()}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{
            Toolbar: DataGridCustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setSearch,
              statuses: [
                { field: "open", headerName: "Open" },
                { field: "pending", headerName: "Pending" },
                { field: "delivered", headerName: "Delivered" },
                { field: "invoiced", headerName: "Invoiced" },
                { field: "closed", headerName: "Completed" },
                { field: "cancelled", headerName: "Cancelled" },
              ],
              statusVisibility,
              toggleStatusVisibility,
              columns,
              columnsVisibility,
              toggleColumnVisibility,
              onFilterModelChange: handleFilterModelChange,
            },
          }}
          onRowClick={(e) => {
            if (e.row) {
              navigate("/jobs/" + e.row._id);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default Jobs;
