import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  CssBaseline,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSnackbar } from "../../components/Snackbar";
import {
  usePostVendorSignupMutation,
  useExternalGetOrganisationQuery,
} from "state/api";
import DetailsSection from "components/vendor-signup/DetailsSection";
import LanguagesSection from "components/vendor-signup/LanguagesSection";
import RatesSection from "components/vendor-signup/RatesSection";
import QualificationsSection from "components/vendor-signup/QualificationsSection";
import UploadsSection from "components/vendor-signup/UploadsSection";
import AvailabilitySection from "components/vendor-signup/AvailabilitySection";
import TermsSection from "components/vendor-signup/TermsSection";
import Password from "components/vendor-signup/Password";
import NdaForm from "components/vendor-signup/NdaForm";
import Spinner from "components/Spinner";

const theme = createTheme();

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const initialFormData = {
  name: "",
  email: "",
  phoneNumber: "",
  address: "",
  country: "",
  languages: [
    {
      sourceLanguages: [],
      targetLanguage: null,
    },
  ],
  preferredCurrency: "",
  rates: [
    {
      category: null,
      cost: null,
      unit: null,
      sourceLanguage: null,
      targetLanguage: null,
    },
  ],
  qualificationsList: [
    {
      type: null,
      link: null,
    },
  ],
  specialty: [],
  services: [],
  contactPreference: "",
  certifiedCountry: "",
  ndaName1: "",
  ndaName2: "",
  availability: daysOfWeek.map((day) => ({
    day,
    startTime: "09:00",
    endTime: "17:00",
    enabled: true,
  })),
};

const initialErrors = {
  name: false,
  email: false,
  phoneNumber: false,
  address: false,
  country: false,
  languages: false,
  contactPreference: false,
  specialty: false,
};

export default function VendorSignup() {
  const { orgExtension } = useParams();
  const [postVendorSignup] = usePostVendorSignupMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isDetailsSubmitted, setIsDetailsSubmitted] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const { data: organisation } = useExternalGetOrganisationQuery(orgExtension);
  const cvInputRef = useRef();
  const qualificationsInputRef = useRef();
  const signatureRef = useRef(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false);
  const [agreeToReferees, setAgreeToReferees] = useState(false);
  const detailsRef = useRef();
  const languagesRef = useRef();
  const ratesRef = useRef();
  const qualificationsRef = useRef();

  const validateForm = () => {
    const newErrors = {
      name: formData.name === "",
      email: formData.email === "",
      phoneNumber: formData.phoneNumber === "",
      country: formData.country === "",
      contactPreference: formData.contactPreference === "",
      languages:
        formData.languages[0].sourceLanguages.length === 0 &&
        !formData.languages[0].targetLanguage,
      preferredCurrency: formData.preferredCurrency === "",
      rates:
        !formData.rates[0].category &&
        !formData.rates[0].cost &&
        !formData.rates[0].unit &&
        !formData.rates[0].sourceLanguage &&
        !formData.rates[0].targetLanguage,
      qualificationsList:
        !formData.qualificationsList[0].type &&
        !formData.qualificationsList[0].link,
      services: formData.services.length === 0,
      specialty: formData.specialty.length === 0,
      certifiedCountry: formData.certifiedCountry === "",
      agreeToPrivacyPolicy: !agreeToPrivacyPolicy,
      agreeToTerms: !agreeToTerms,
      agreeToReferees: !agreeToReferees,
      ...(isDetailsSubmitted && {
        ndaName1: formData.ndaName1 === "",
        ndaName2: formData.ndaName2 === "",
        signature:
          signatureRef &&
          signatureRef?.current &&
          signatureRef?.current?.isEmpty(),
      }),
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (
      validateForm() &&
      agreeToTerms &&
      agreeToPrivacyPolicy &&
      agreeToReferees
    ) {
      try {
        const form = new FormData();

        for (const key in formData) {
          if (Object.hasOwnProperty.call(formData, key)) {
            const value = formData[key];
            if (Array.isArray(value)) {
              if (key === "qualifications") {
                // Append each file separately for "qualifications"
                value.forEach((file, index) => {
                  form.append("qualifications", file);
                });
              } else {
                // Handle other array fields or non-array fields
                form.append(key, JSON.stringify(value));
              }
            } else {
              form.append(key, value);
            }
          }
        }

        // Add the organization extension to the form data
        form.append("orgExtension", orgExtension);

        // Capture the signature and convert it to a Blob
        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob(async (blob) => {
          if (blob) {
            // Append the signature Blob to the form data
            form.append("signature", blob, "NDA-Signature.png");

            // Now you can send the form data to the server
            const response = await postVendorSignup(form);

            if (response.data.message === "success") {
              setIsSubmitted(true);
              showSnackbar("Vendor registered successfully", "success");
            } else {
              showSnackbar("Something went wrong", "error");
              setIsSubmitting(false);
            }
          } else {
            showSnackbar("Error capturing the signature", "error");
            setIsSubmitting(false);
          }
        });
      } catch (error) {
        console.error(error);
        showSnackbar("An error occurred", "error");
        setIsSubmitting(false);
      }
    } else {
      showSnackbar(
        "Please fill out all required fields and agree to terms and privacy policy.",
        "warning"
      );
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const scrollToFirstError = () => {
      const errorOrder = [
        { key: "name", ref: detailsRef },
        { key: "languages", ref: languagesRef },
        { key: "rates", ref: ratesRef },
        { key: "qualificationsList", ref: qualificationsRef },
      ];

      // Find the first error that is true, and scroll to its ref
      const firstError = errorOrder.find((error) => errors[error.key]);
      console.log(firstError);
      if (firstError && firstError.ref.current) {
        console.log(firstError);
        firstError.ref.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    };

    // Call scrollToFirstError if there are any errors
    if (Object.values(errors).some((error) => error)) {
      scrollToFirstError();
    }
  }, [errors]);

  useEffect(() => {
    if (orgExtension) {
      setLogoUrl(
        process.env.REACT_APP_PUBLIC_GCLOUD_URL +
          "public/" +
          orgExtension +
          "/logo/logo.png"
      );
    }
  }, [orgExtension]);

  const handleChange = (e, field) => {
    let newValue;
    if (e.target) {
      newValue = e.target.value;
    } else {
      newValue = e;
    }

    if (field === "cv") {
      const selectedFile = cvInputRef.current.files[0];
      setFormData({ ...formData, cv: selectedFile });
    } else if (field === "qualifications") {
      const selectedFiles = Array.from(qualificationsInputRef.current.files);
      setFormData({ ...formData, qualifications: selectedFiles });
    } else {
      setFormData({ ...formData, [field]: newValue });
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: false,
    }));
  };

  if (!organisation) {
    return <Spinner />;
  }

  if (isSubmitted) {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarComponent />
        <Container component="main" className="vendor-signup-container">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 8,
            }}
          >
            <img
              crossorigin="anonymous"
              src={logoUrl}
              alt={orgExtension + " logo"}
              style={{ width: "30%" }}
            />
            <Typography textAlign="center" variant="h4">
              Vendor Registration
            </Typography>
            <Box
              sx={{
                mt: "2rem",
                border: 1,
                borderColor: "success.main",
                borderRadius: 1,
                padding: 2,
                textAlign: "center",
              }}
            >
              Thank you for your submission. Someone from our team will reach
              out shortly to collect further details.
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }

  if (!isPasswordCorrect) {
    return (
      <Password
        logoUrl={logoUrl}
        setIsPasswordCorrect={setIsPasswordCorrect}
        orgExtension={orgExtension}
      />
    );
  }

  if (isDetailsSubmitted) {
    return (
      <NdaForm
        organisation={organisation}
        logoUrl={logoUrl}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        signatureRef={signatureRef}
        isSubmitted={isSubmitted}
        isSubmitting={isSubmitting}
        errors={errors}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarComponent />
      <Container component="main" className="vendor-signup-container">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <img
            src={logoUrl}
            alt={orgExtension + " logo"}
            style={{ width: "30%" }}
            crossorigin="anonymous"
          />
          <Typography textAlign="center" variant="h4">
            Vendor Registration
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (
                validateForm() &&
                agreeToTerms &&
                agreeToPrivacyPolicy &&
                agreeToReferees
              ) {
                setIsDetailsSubmitted(true);
              }
            }}
            noValidate
          >
            <Grid
              container
              spacing={2}
              sx={{
                mt: "2rem",
              }}
            >
              <DetailsSection
                formData={formData}
                errors={errors}
                handleChange={handleChange}
                detailsRef={detailsRef}
              />
              <LanguagesSection
                formData={formData}
                errors={errors}
                handleChange={handleChange}
                languagesRef={languagesRef}
              />
              <RatesSection
                formData={formData}
                errors={errors}
                handleChange={handleChange}
                ratesRef={ratesRef}
              />
              <QualificationsSection
                formData={formData}
                errors={errors}
                handleChange={handleChange}
                qualificationsRef={qualificationsRef}
              />
              <UploadsSection
                formData={formData}
                handleChange={handleChange}
                qualificationsInputRef={qualificationsInputRef}
                cvInputRef={cvInputRef}
              />
              {/**
                <AvailabilitySection
                  formData={formData}
                  setFormData={setFormData}
                />
              */}
              <TermsSection
                organisation={organisation}
                agreeToTerms={agreeToTerms}
                setAgreeToTerms={setAgreeToTerms}
                agreeToPrivacyPolicy={agreeToPrivacyPolicy}
                setAgreeToPrivacyPolicy={setAgreeToPrivacyPolicy}
                agreeToReferees={agreeToReferees}
                setAgreeToReferees={setAgreeToReferees}
                errors={errors}
              />
            </Grid>
            <Button
              type="submit"
              disabled={isSubmitted || isSubmitting}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Next
            </Button>
            {errors && (
              <Typography
                sx={{
                  color: "#df0000",
                  variant: "body2",
                  mt: 1,
                  textAlign: "center",
                }}
              >
                Please fill out all required fields and agree to terms and
                privacy policy.
              </Typography>
            )}
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
