import React from "react";
import { Grid, Typography, Divider, Button } from "@mui/material";

const UploadsSection = ({
  formData,
  handleChange,
  qualificationsInputRef,
  cvInputRef,
}) => {
  return (
    <>
      <Grid item xs={12} sm={12} sx={{ mt: "3rem" }}>
        <Divider sx={{ my: 2, mb: "3rem" }} />
        <Typography variant="h6" gutterBottom>
          Supporting Documents
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <label htmlFor="cv" style={{ display: "block" }}>
          <Button
            variant="outlined"
            component="span"
            fullWidth
            sx={{ height: "100%" }}
          >
            Upload CV
          </Button>
        </label>
        <input
          type="file"
          id="cv"
          name="cv"
          ref={cvInputRef}
          onChange={(e) => handleChange(e, "cv")}
          style={{ display: "none" }}
        />
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            textAlign: "center",
          }}
        >
          {formData.cv?.name}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <label htmlFor="qualifications" style={{ display: "block" }}>
          <Button
            variant="outlined"
            component="span"
            fullWidth
            sx={{ height: "100%" }}
          >
            Upload Qualifications
          </Button>
        </label>
        <input
          type="file"
          multiple
          id="qualifications"
          name="qualifications"
          ref={qualificationsInputRef}
          onChange={(e) => handleChange(e, "qualifications")}
          style={{ display: "none" }}
        />
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            textAlign: "center",
          }}
        >
          {formData.qualifications?.length > 0 &&
            `${formData.qualifications?.length} files selected`}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            textAlign: "center",
          }}
        >
          {formData.qualifications?.map((file) => (
            <Typography
              key={file.name}
              variant="body2"
              sx={{
                color: "text.secondary",
                textAlign: "center",
              }}
            >
              {file.name}
            </Typography>
          ))}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>
    </>
  );
};

export default UploadsSection;
