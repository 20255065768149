import { useAuth } from "./auth";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const CheckAuth = ({ children }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const herokuUrls = process.env.REACT_APP_BASE_URL.split(",");

  const authenticateToken = async (token) => {
    await fetch(
      `${
        process.env.REACT_APP_NODE_ENV === "development"
          ? process.env.REACT_APP_DEV_URL
          : herokuUrls[0]
      }/api/isAuth`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then((data) => {
        if (data) {
          if (data.auth) {
            auth.login(data.user, data.role); // Log in with user sent from JWT decoder
            // Go to dashboard if at login page
            if (location.pathname === "/login") {
              navigate("/dashboard");
            }
          } else {
            navigate("/login", { replace: true });
          }
        } else {
          console.log("An error occurred");
          return false;
        }
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      authenticateToken(token);
    } else {
      console.log("No token found");
      navigate("/login", { replace: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return children;
};
