import React, { useState, useEffect } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  ArrowDropDownOutlined,
  NotificationsOutlined,
  Notifications,
} from "@mui/icons-material";
import {
  useGetAllNotificationsQuery,
  usePatchMarkOneNotificationAsReadMutation,
  usePatchMarkAllNotificationsAsReadMutation,
} from "state/api"; // Import the mutation hook
import { useAuth } from "./auth";
import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state";
import {
  AppBar,
  IconButton,
  Button,
  MenuItem,
  Menu,
  InputBase,
  Box,
  Typography,
  Toolbar,
  useTheme,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const navigate = useNavigate();
  const { notificationsLength } = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data: notificationData, refetch } = useGetAllNotificationsQuery({
    id: user._id,
    page: 1,
    limit: 5,
  });
  const [markNotificationAsRead] = usePatchMarkOneNotificationAsReadMutation();
  const [markAllNotificationsAsRead] =
    usePatchMarkAllNotificationsAsReadMutation();

  useEffect(() => {
    if (notificationsLength > 0) {
      refetch();
    } //eslint-disable-next-line
  }, [notificationsLength]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const herokuUrls = process.env.REACT_APP_BASE_URL.split(",");

  const handleNotificationsClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleClose = () => {
    // Log out
    fetch(
      `${
        process.env.REACT_APP_NODE_ENV === "development"
          ? process.env.REACT_APP_DEV_URL
          : herokuUrls[0]
      }/api/logout`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      // Delete localStorage jwt
      if (localStorage.getItem("jwt")) {
        localStorage.removeItem("jwt");
      }
      // Close menu
      setAnchorEl(null);
      window.location.replace("/login");
    });
  };

  const handleMarkAsRead = (notificationId) => {
    markNotificationAsRead({ id: notificationId });
    refetch();
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead({ id: user._id });
    refetch();
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <InputBase placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          <IconButton
            onClick={() =>
              dispatch(
                setMode(theme.palette.mode === "dark" ? "light" : "dark")
              )
            }
          >
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
          <IconButton
            onClick={handleNotificationsClick}
            sx={{ position: "relative" }}
          >
            {notificationData?.notifications.some(
              (notification) => !notification.read
            ) ? (
              <Notifications sx={{ fontSize: "25px" }} color="error" />
            ) : (
              <NotificationsOutlined sx={{ fontSize: "25px" }} />
            )}
            {showNotifications && (
              <Box
                sx={{
                  cursor: "default",
                  position: "absolute",
                  top: "2.5rem",
                  width: "25rem",
                  right: 0,
                  backgroundColor: theme.palette.background.alt,
                  boxShadow: theme.shadows[3],
                  padding: "1rem",
                  zIndex: 1000,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <MenuItem
                  sx={{
                    cursor: "pointer",
                    padding: "0.5rem",
                    marginLeft: "auto",
                    width: "35%",
                    "&:hover": {
                      padding: "0.5rem",
                      borderRadius: "4px", // Add some border radius for a rounded box
                    },
                  }}
                  onClick={() => handleMarkAllAsRead()}
                >
                  <Typography
                    sx={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    component="span"
                    variant="body2"
                    color={theme.palette.secondary[100]}
                  >
                    Mark All Read
                  </Typography>
                </MenuItem>
                <Divider />
                {notificationData.notifications.map((notification, index) => (
                  <Box key={notification._id} sx={{ wordWrap: "break-word" }}>
                    <MenuItem
                      onClick={() => {
                        if (notification.reference?.type === "job") {
                          navigate(`/jobs/${notification.reference?.id}`);
                          setShowNotifications(false);
                          handleMarkAsRead(notification._id);
                        }
                      }}
                      sx={{
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          fontWeight="bold"
                          fontSize="0.85rem"
                          sx={{
                            color: theme.palette.primary[100],
                            textAlign: "left",
                          }}
                        >
                          {notification.title}
                        </Typography>
                        <Typography
                          fontSize="0.65rem"
                          sx={{
                            color: theme.palette.primary[300],
                            textAlign: "right",
                          }}
                        >
                          {new Date(notification.createdAt).toLocaleString(
                            "en-GB",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            }
                          )}
                        </Typography>
                      </Box>
                      <Typography
                        fontSize="0.75rem"
                        sx={{
                          color: theme.palette.primary[200],
                          textAlign: "left",
                        }}
                      >
                        {notification.text}
                      </Typography>
                      {!notification.read && ( // Render the button only if the notification is not read
                        <Button
                          component="span"
                          sx={{
                            cursor: "pointer",
                            marginLeft: "auto",
                            padding: "0.5rem",
                            marginTop: "0.5rem",
                            "&:hover": {
                              backgroundColor: theme.palette.background.alt,
                              padding: "0.5rem",
                              borderRadius: "4px", // Add some border radius for a rounded button
                            },
                          }}
                          onClick={() => handleMarkAsRead(notification._id)}
                        >
                          <Typography
                            component="span"
                            variant="body2"
                            color={theme.palette.secondary[100]}
                          >
                            Mark Read
                          </Typography>
                        </Button>
                      )}
                    </MenuItem>
                    {notificationData.notifications.length - 1 > 0 &&
                      notificationData.notifications.length - 1 !== index && (
                        <Divider
                          sx={{
                            marginBottom: "0px !important",
                            marginTop: "0px !important",
                          }}
                        />
                      )}
                  </Box>
                ))}
                {notificationData.notifications.length === 0 && (
                  <MenuItem
                    sx={{
                      padding: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between", // Add space between text and button
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      fontSize="0.85rem"
                      sx={{ color: theme.palette.secondary[100] }}
                    >
                      No Notifications
                    </Typography>
                    <Typography
                      fontSize="0.75rem"
                      sx={{ color: theme.palette.secondary[200] }}
                    >
                      You have no notifications
                    </Typography>
                  </MenuItem>
                )}
                <Divider />
                <MenuItem
                  sx={{
                    cursor: "pointer",
                    padding: "0.5rem",
                    marginLeft: "auto",
                    marginTop: "1rem",
                    width: "40%",
                    "&:hover": {
                      padding: "0.5rem",
                      borderRadius: "4px",
                    },
                  }}
                  onClick={() => {
                    setShowNotifications(false);
                    navigate("/notifications");
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    component="span"
                    variant="body2"
                    color={theme.palette.secondary[100]}
                  >
                    View All Notifications
                  </Typography>
                </MenuItem>
              </Box>
            )}
          </IconButton>
          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.role}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={() => setAnchorEl(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem
                onClick={() => {
                  navigate(`/admins/${user._id}`);
                  setAnchorEl(null);
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(`/settings`);
                  setAnchorEl(null);
                }}
              >
                Settings
              </MenuItem>
              <MenuItem onClick={handleClose}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
