import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetTranslatorQuery,
  usePutTranslatorMutation,
  useDeleteTranslatorMutation,
} from "state/api";
import { Box, Button, Grid } from "@mui/material";
import Header from "components/Header";
import UnsavedChanges from "components/UnsavedChanges";
import { useSnackbar } from "components/Snackbar";
import Spinner from "components/Spinner";
import DeleteVendorDialog from "components/dialogs/DeleteVendorDialog";
import ActionsMenu from "components/ActionsMenu";
import DetailsPanel from "components/vendor-page/DetailsPanel";
import NotesPanel from "components/NotesPanel";
import QualificationsPanel from "components/vendor-page/QualificationsPanel";
import TasksPanel from "components/vendor-page/TasksPanel";
import LanguagesPanel from "components/vendor-page/LanguagesPanel";
import RatesPanel from "components/vendor-page/RatesPanel";
import AvailabilityPanel from "components/vendor-page/AvailablilityPanel";
import FilesPanel from "components/vendor-page/FilesPanel";

const Translator = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useGetTranslatorQuery(id);
  const [putTranslatorMutation] = usePutTranslatorMutation();
  const [deleteTranslatorMutation] = useDeleteTranslatorMutation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [editedData, setEditedData] = useState({});

  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    if (data) {
      if (data.pending) {
        navigate("/pending-vendors/" + id);
      }
      setEditedData({ ...data });
    } // eslint-disable-next-line
  }, [data]);

  const handleSaveChanges = async () => {
    try {
      await putTranslatorMutation({
        id: id,
        updatedData: editedData,
      }).then((res) => {
        showSnackbar("Translator updated successfully", "success");
        setIsDataChanged(false);
      });
    } catch (error) {
      showSnackbar("Something went wrong", "error");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteTranslatorMutation(id).then((res) => {
        navigate("/translators");
      });
      // Handle success or any other actions after successful delete
    } catch (error) {
      showSnackbar("Error deleting translator", "error");
    }
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditChange = (field, value) => {
    if (!isDataChanged) {
      setIsDataChanged(true);
    }
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const actions = [
    {
      label: "Delete Translator",
      onClick: () => setIsDeleteDialogOpen(true),
    },
  ];

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <UnsavedChanges isDataChanged={isDataChanged} />
      <DeleteVendorDialog
        isOpen={isDeleteDialogOpen}
        close={closeDeleteDialog}
        confirm={handleDelete}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title={data?.name} subtitle="Translator Profile" />
        <Box>
          <ActionsMenu actions={actions} />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ ml: "2rem" }}
            onClick={handleSaveChanges}
            disabled={!isDataChanged}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: "10px", width: "100%" }}>
        <Grid item xs={6}>
          <DetailsPanel
            data={data}
            editedData={editedData}
            handleEditChange={handleEditChange}
          />
          <Box
            sx={{
              my: "1rem",
            }}
          >
            <RatesPanel
              data={data}
              editedData={editedData}
              handleEditChange={handleEditChange}
              changesMade={() => setIsDataChanged(true)}
            />
          </Box>
          <Box
            sx={{
              mt: "1rem",
            }}
          >
            <QualificationsPanel
              data={data}
              editedData={editedData}
              handleEditChange={handleEditChange}
            />
          </Box>
          <Box
            sx={{
              my: "1rem",
            }}
          >
            <NotesPanel
              data={data}
              editedData={editedData}
              handleEditChange={handleEditChange}
              changesMade={() => setIsDataChanged(true)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TasksPanel data={data} />
          <Box
            sx={{
              mt: "1rem",
            }}
          >
            <LanguagesPanel
              data={data}
              editedData={editedData}
              handleEditChange={handleEditChange}
            />
          </Box>
          <Box
            sx={{
              my: "1rem",
            }}
          >
            <AvailabilityPanel
              data={data}
              editedData={editedData}
              handleEditChange={handleEditChange}
            />
          </Box>
          <Box
            sx={{
              my: "1rem",
            }}
          >
            <FilesPanel
              data={data}
              editedData={editedData}
              handleEditChange={handleEditChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Translator;
