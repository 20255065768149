import React from "react";
import { TextField, Autocomplete } from "@mui/material";

const CurrencySelector = ({ selectedCurrency, onSelectCurrency }) => {
  const currencyOptions = ["GBP", "EUR", "USD"];

  return (
    <Autocomplete
      options={currencyOptions}
      size="small"
      value={selectedCurrency}
      onChange={(event, newValue) => {
        onSelectCurrency(newValue);
      }}
      renderInput={(params) => <TextField {...params} label="Currency" />}
    />
  );
};

export default CurrencySelector;
