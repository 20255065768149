import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  CssBaseline,
  Container,
  Button,
} from "@mui/material";
import { useSnackbar } from "../../components/Snackbar";
import { useOrgSignupPasswordMutation } from "state/api";

const Password = ({ logoUrl, setIsPasswordCorrect, orgExtension }) => {
  const [password, setPassword] = useState("");
  const [orgSignupPassword, { isSuccess, isError }] =
    useOrgSignupPasswordMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("orgExtension", orgExtension, "password", password);
      const response = await orgSignupPassword({
        password,
        orgExtension,
      }).unwrap();
      // Assuming your backend sends a response indicating success/failure
      if (response?.isPasswordCorrect) {
        setIsPasswordCorrect(true);
        showSnackbar("Password verified", "success");
      } else {
        showSnackbar("Incorrect password", "error");
      }
    } catch (error) {
      showSnackbar("Error during password verification", "error");
    }
  };

  return (
    <Container component="main" className="vendor-signup-container">
      <SnackbarComponent />
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          crossorigin="anonymous"
          src={logoUrl}
          alt="organisation-logo"
          style={{ width: "30%" }}
        />
        <Typography variant="h4">Vendor Registration</Typography>
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2} sx={{ mt: "2rem" }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="password"
                label="Password"
                name="password"
                autoComplete="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Password;
