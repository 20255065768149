import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetQuoteQuery,
  usePutQuoteMutation,
  useDeleteQuoteMutation,
  usePostGenerateQuoteMutation,
  useCreateQuoteMutation,
} from "state/api";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
} from "@mui/material";
import Header from "components/Header";
import ActionsMenu from "components/ActionsMenu";
import Spinner from "components/Spinner";
import UnsavedChanges from "components/UnsavedChanges";
import { useAuth } from "components/auth";
import { useSnackbar } from "components/Snackbar";
import JobDetailsPanel from "components/quote-page/JobDetailsPanel";
import QuoteDetailPanel from "components/quote-page/QuoteDetailPanel";
import NotesPanel from "components/NotesPanel";
import QuoteEstimatesPanel from "components/quote-page/QuoteEstimatesPanel";
import ClientDetailsPanel from "components/quote-page/ClientDetailsPanel";

const Quote = () => {
  const auth = useAuth();
  const userId = auth.user;
  const navigate = useNavigate();
  const [createQuoteMutation] = useCreateQuoteMutation();
  const [putQuoteMutation] = usePutQuoteMutation();
  const [deleteQuoteMutation] = useDeleteQuoteMutation();
  const [postGenerateQuoteMutation] = usePostGenerateQuoteMutation();
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetQuoteQuery(id);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    if (data) {
      setEditedData({ ...data });
    }
  }, [data]);

  useEffect(() => {
    refetch(); // eslint-disable-next-line
  }, [id]);

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteQuoteMutation(id).then((res) => {
        navigate("/quotes");
      });
    } catch (error) {
      showSnackbar("Error deleting quote", "error");
    }
  };

  const actions = [
    {
      label: "Generate Quote PDF",
      onClick: async () => {
        await postGenerateQuoteMutation({
          clientId: data.job.clientId,
          quoteId: id,
          jobId: data.job._id,
          userId: userId,
          template: "default",
        }).then((res) => {
          if (res.data.signedURL) {
            window.open(res.data.signedURL, "_blank");
            showSnackbar("Quote generated successfully", "success");
            refetch();
          } else {
            showSnackbar("Error generating quote", "error");
          }
        });
      },
    },
    {
      label: "Generate Grid Quote PDF",
      onClick: async () => {
        await postGenerateQuoteMutation({
          clientId: data.job.clientId,
          quoteId: id,
          jobId: data.job._id,
          userId: userId,
          template: "grid",
        }).then((res) => {
          if (res.data.signedURL) {
            window.open(res.data.signedURL, "_blank");
            showSnackbar("Quote generated successfully", "success");
            refetch();
          } else {
            showSnackbar("Error generating quote", "error");
          }
        });
      },
    },
    {
      label: "Duplicate Quote",
      onClick: async () => {
        const data = {
          ...editedData,
          quoteNumber: `${editedData.quoteNumber} copy`,
        };
        await createQuoteMutation({ data }).then((res) => {
          if (res.data.quoteId) {
            navigate("/quotes/" + res.data.quoteId);
            showSnackbar("Quote duplicated successfully", "success");
          }
        });
      },
    },
    {
      label: "Delete Quote",
      onClick: () => {
        openDeleteDialog();
      },
    },
  ];

  const handleSaveChanges = async () => {
    try {
      await putQuoteMutation({
        id: id,
        updatedData: editedData,
        userId: userId,
      }).then((res) => {
        console.log(res);
        if (res.data.quote._id) {
          showSnackbar("Quote updated successfully", "success");
        }
      });

      setIsDataChanged(false);
    } catch (error) {
      showSnackbar("Error updating quote", "error");
    }
  };

  const handleEditChange = (field, value) => {
    if (!isDataChanged) {
      setIsDataChanged(true);
    }
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  if (!editedData || isLoading) {
    return <Spinner />;
  }

  return (
    <Box>
      <SnackbarComponent />
      <UnsavedChanges isDataChanged={isDataChanged} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box m="1rem 0rem 0rem 2.5rem">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px="2rem"
            >
              <Header
                title={data?.job?.name + "-" + editedData?.quoteNumber}
                subtitle="Quote page"
              />
              <Box>
                <ActionsMenu actions={actions} />
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  sx={{ ml: "2rem" }}
                  onClick={handleSaveChanges}
                  disabled={!isDataChanged}
                >
                  Save
                </Button>
              </Box>
            </Box>
            <Box sx={{ m: "2rem" }}>
              <Grid container spacing={4} sx={{ mb: "2rem" }}>
                <Grid item xs={12} md={6}>
                  <QuoteDetailPanel
                    data={data}
                    editedData={editedData}
                    handleEditChange={handleEditChange}
                    changesMade={() => setIsDataChanged(true)}
                  />
                  <Box sx={{ mt: "2rem" }}>
                    <QuoteEstimatesPanel
                      editedData={editedData}
                      handleEditChange={handleEditChange}
                    />
                  </Box>
                  <Box sx={{ mt: "2rem" }}>
                    <NotesPanel
                      data={data}
                      editedData={editedData}
                      handleEditChange={handleEditChange}
                      changesMade={() => setIsDataChanged(true)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <JobDetailsPanel data={data} />
                  <Box sx={{ mt: "2rem" }}>
                    <ClientDetailsPanel data={data} editedData={editedData} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Dialog
              open={isDeleteDialogOpen}
              onClose={closeDeleteDialog}
              aria-labelledby="delete-dialog-title"
              aria-describedby="delete-dialog-description"
            >
              <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText id="delete-dialog-description">
                  Are you sure you want to delete this quote? This action cannot
                  be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDeleteDialog} color="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    closeDeleteDialog();
                    handleDelete();
                  }}
                  color="warning"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Quote;
