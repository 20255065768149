import React, { useState } from "react";
import {
  useTheme,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Link,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import EditableContainer from "../EditableContainer";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { capitalizeFirstLetter } from "components/usefulFunctions";

const QualificationsPanel = ({ data, editedData, handleEditChange }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const theme = useTheme();
  const [newQualification, setNewQualification] = useState({
    type: "",
    link: "",
  });

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  // Separate handlers for type and link
  const handleQualificationTypeChange = (e) => {
    setNewQualification({ ...newQualification, type: e.target.value });
  };

  const handleQualificationLinkChange = (e) => {
    setNewQualification({ ...newQualification, link: e.target.value });
  };

  const handleAddQualification = () => {
    if (newQualification.link.trim() !== "") {
      const updatedQualifications = [
        ...(editedData.qualifications || []),
        { ...newQualification },
      ];
      handleEditChange("qualifications", updatedQualifications);
      setNewQualification({ type: "", link: "" });
    }
  };

  const handleRemoveQualification = (index) => {
    const newQualifications = [...(editedData.qualifications || [])];
    newQualifications.splice(index, 1);
    handleEditChange("qualifications", newQualifications);
  };

  return (
    <EditableContainer
      title={`Qualifications`}
      isEditMode={isEditMode}
      handleEditClick={handleEditClick}
    >
      <Divider />
      <List>
        {editedData &&
          (editedData.qualifications || data.qualifications).map(
            (qualification, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={capitalizeFirstLetter(qualification.type)}
                    secondary={
                      <Link
                        href={qualification.link}
                        color={theme.palette.secondary[100]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {qualification.link}
                      </Link>
                    }
                    sx={{ width: "80%" }}
                  />
                  {isEditMode && (
                    <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() => handleRemoveQualification(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItem>
                {index !==
                  (editedData.qualifications || data.qualifications).length -
                    1 && <Divider />}
              </React.Fragment>
            )
          )}
        {isEditMode && (
          <ListItem>
            <ListItemText sx={{ width: "40%" }}>
              <FormControl size="small" fullWidth>
                <InputLabel htmlFor="qualifications">
                  Qualification Type
                </InputLabel>
                <Select
                  label="Qualification Type"
                  value={newQualification.type}
                  onChange={handleQualificationTypeChange}
                  inputProps={{
                    name: "qualifications",
                    id: "qualifications",
                  }}
                >
                  <MenuItem value="translation Degree">
                    Translation Degree
                  </MenuItem>
                  <MenuItem value="interpreting Degree">
                    Interpreting Degree
                  </MenuItem>
                  <MenuItem value="language Degree">Language Degree</MenuItem>
                  <MenuItem value="nRSPI Listing">NRSPI Listing</MenuItem>
                  <MenuItem value="professional Linguist Membership">
                    Professional Linguist Membership
                  </MenuItem>
                  <MenuItem value="sworn Translator Status">
                    Sworn Translator Status
                  </MenuItem>
                  <MenuItem value="official Government Translator Status">
                    Official Government Translator Status
                  </MenuItem>
                  <MenuItem value="official Government Interpreter Status">
                    Official Government Interpreter Status
                  </MenuItem>
                </Select>
              </FormControl>
            </ListItemText>
            <ListItemText sx={{ width: "50%", ml: "1rem" }}>
              <TextField
                fullWidth
                size="small"
                placeholder="Add link to qualification evidence"
                value={newQualification.link}
                onChange={handleQualificationLinkChange}
              />
            </ListItemText>
            <IconButton
              color="secondary"
              aria-label="add"
              onClick={() => handleAddQualification()}
            >
              <AddIcon />
            </IconButton>
          </ListItem>
        )}
      </List>
    </EditableContainer>
  );
};

export default QualificationsPanel;
