import React, { useState } from "react";
import { Box } from "@mui/material";
import {
  useCreateOrganisationMutation,
  useGetOrganisationsQuery,
} from "state/api";
import Header from "components/Header";
import Spinner from "components/Spinner";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "components/Snackbar";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ActionsMenu from "components/ActionsMenu";

const OrganisationAdminList = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { data, isLoading } = useGetOrganisationsQuery();
  const [createOrganisationMutation] = useCreateOrganisationMutation();

  const createNewOrganisation = async () => {
    try {
      await createOrganisationMutation().then((res) => {
        console.log("res", res);
        if (res.data._id) {
          navigate("/organisation-admins/" + res.data._id);
        }
      });
    } catch (error) {
      console.error("Error creating organisation:", error);
    }
  };

  const actions = [
    {
      label: "Create New Client",
      onClick: () => createNewOrganisation(),
    },
  ];

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "plan", headerName: "Plan", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    { field: "website", headerName: "Website", flex: 1 },
  ];

  if (!data || isLoading) return <Spinner />;

  return (
    <Box m="1rem 2.5rem 0rem 2.5rem">
      <SnackbarComponent />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <Header title="Organisations" subtitle="List of organisations" />
        <ActionsMenu actions={actions} />
      </Box>

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data || []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          onRowClick={(e) => navigate(`/organisation-admins/${e.row._id}`)}
          getRowId={(row) => row._id}
          sx={{
            padding: "1rem",
            backgroundColor: theme.palette.background.alt,
            ".MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              borderTop: "2px solid rgba(224, 224, 224, 1)",
              overflowX: "hidden",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default OrganisationAdminList;
