import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";

const specialisations = [
  "Accounting",
  "Art",
  "Automotive",
  "Aviation",
  "Biology",
  "Chemistry",
  "Computer Software",
  "Computer Hardware",
  "Construction",
  "Cosmetics",
  "Energy",
  "Engineering",
  "Entertainment / Film",
  "Environment",
  "Family Law",
  "Financial",
  "Food & Drink",
  "Games – Video – Casino",
  "Geology",
  "Health",
  "Human rights",
  "Insurance",
  "Journalism",
  "Legal",
  "Life sciences",
  "Marine",
  "Maritime",
  "Marketing / market research",
  "Media",
  "Medical",
  "Metallurgy",
  "Military / Defence",
  "Mining",
  "Music Patents",
  "Pharmaceuticals",
  "Physics",
  "Poetry",
  "Literature",
  "Property / Real Estate",
  "Railways",
  "Shipping",
  "Sports",
  "Tax",
  "Tourism / Travel",
  "Transport",
];

const servicesTypes = [
  "Access services",
  "Checking/Editing",
  "Copywriting",
  "Dubbing",
  "Interpreting",
  "Interpreting (Ad-hoc)",
  "Interpreting (Court)",
  "Interpreting (Consecutive)",
  "Interpreting (Simultaneous)",
  "Interpreting (Remote Telecom and Video)",
  "Interpreting (Whispering)",
  "Language Coaching",
  "Language Instruction",
  "Localisation (Audio Visual)",
  "Localisation (Software)",
  "Localisation (Graphics)",
  "Localisation (Website)",
  "Post-editing",
  "Proofreading",
  "Subtitling",
  "Summarisation",
  "Transcription",
  "Translation",
  "Voiceover",
];

const DetailsSection = ({ formData, errors, handleChange, detailsRef }) => {
  return (
    <>
      <Grid ref={detailsRef} item xs={12} sm={12}>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          Details
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="name"
          label="Full Name"
          name="name"
          autoComplete="name"
          value={formData.name}
          onChange={(e) => handleChange(e, "name")}
          autoFocus
          error={errors.name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={formData.email}
          onChange={(e) => handleChange(e, "email")}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="phoneNumber"
          label="Phone"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={(e) => handleChange(e, "phoneNumber")}
          error={errors.phoneNumber}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="country"
          label="Country"
          name="country"
          value={formData.country}
          onChange={(e) => handleChange(e, "country")}
          error={errors.country}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          multiple
          id="specialty"
          options={specialisations}
          value={formData.specialty}
          onChange={(e, newValue) => handleChange(newValue, "specialty")}
          renderInput={(params) => (
            <TextField {...params} label="Specialty" error={errors.specialty} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl size="large" fullWidth>
          <InputLabel
            htmlFor="contact-preference"
            error={errors.contactPreference}
          >
            Contact Preference
          </InputLabel>
          <Select
            label="Contact Preference"
            value={formData.contactPreference}
            onChange={(e) => handleChange(e, "contactPreference")}
            inputProps={{
              name: "contactPreference",
              id: "contact-preference",
            }}
            error={errors.contactPreference}
          >
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="phone">Phone</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Autocomplete
          multiple
          options={servicesTypes}
          getOptionLabel={(option) => option}
          value={formData.services}
          onChange={(e, newValue) => handleChange(newValue, "services")}
          renderInput={(params) => (
            <TextField {...params} label="Services" error={errors.services} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mt: "1rem" }}>
        <Typography variant="p" gutterBottom>
          What country are you an official, certified or sworn translator for?
        </Typography>
        <TextField
          fullWidth
          sx={{ mt: "1rem" }}
          id="certifiedCountry"
          label="Certified / Official / Sworn Country"
          name="certifiedCountry"
          value={formData.certifiedCountry}
          onChange={(e) => handleChange(e, "certifiedCountry")}
          error={errors.certifiedCountry}
        />
      </Grid>
    </>
  );
};

export default DetailsSection;
