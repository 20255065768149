import React, { useState, useRef } from "react";
import { Search } from "@mui/icons-material";
import { IconButton, TextField, InputAdornment, Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import ColumnEditor from "./ColumnEditor";
import DataGridFilterPanel from "./DataGridFilterPanel";
import StatusSelector from "./StatusSelector";

const DataGridCustomToolbar = ({
  searchInput,
  setSearchInput,
  setSearch,
  statuses,
  statusVisibility,
  toggleStatusVisibility,
  columns,
  columnsVisibility,
  toggleColumnVisibility,
  onFilterModelChange,
}) => {
  const getDefaultFilters = () => {
    return [
      {
        id: Date.now(),
        columnField: "",
        operatorValue: "",
        value: "",
      },
    ];
  };
  const [filters, setFilters] = useState(getDefaultFilters());
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearch(searchInput);
    }
  };

  // This function updates the filterModel state and notifies the parent component
  const updateFilterModel = (newFilters) => {
    setFilters(newFilters);
    const newFilterModel = {
      items: newFilters.map((filter) => ({
        columnField: filter.columnField,
        operatorValue: filter.operatorValue,
        value: filter.value,
        rateUnit: filter.rateUnit ?? null,
        rateCategory: filter.rateCategory ?? null,
      })),
    };
    setFilterModel(newFilterModel);
  };

  const applyFilters = () => {
    onFilterModelChange(filterModel);
  };

  const searchInputRef = useRef(null);

  const handleClearSearch = () => {
    setSearchInput("");

    // Focus the search input
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  return (
    <GridToolbarContainer>
      <FlexBetween width="100%" sx={{ margin: "0rem 1rem" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <TextField
            label="Search..."
            sx={{ mb: "0.5rem", width: "15rem" }}
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            variant="standard"
            onKeyPress={handleKeyPress}
            inputRef={searchInputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchInput && (
                    <IconButton onClick={handleClearSearch} edge="end">
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      setSearch(searchInput);
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ ml: "2rem" }}>
              <DataGridFilterPanel
                columns={columns}
                filters={filters}
                setFilters={updateFilterModel}
                applyFilters={applyFilters}
              />
            </Box>
            {window.location.pathname === "/jobs" && (
              <Box sx={{ ml: "2rem" }}>
                <StatusSelector
                  statuses={statuses}
                  statusVisibility={statusVisibility}
                  toggleStatusVisibility={toggleStatusVisibility}
                />
              </Box>
            )}
          </Box>
        </Box>
        <FlexBetween>
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <ColumnEditor
            columns={columns}
            columnsVisibility={columnsVisibility}
            toggleColumnVisibility={toggleColumnVisibility}
          />
        </FlexBetween>
      </FlexBetween>
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbar;
