import React, { useState, useEffect } from "react";
import {
  usePutLanguageMutation,
  useDeleteLanguageMutation,
  useCreateLanguageMutation,
} from "state/api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";

const EditLanguageDialog = ({
  data,
  refetch,
  isCreate,
  open,
  onClose,
  showSnackbar,
}) => {
  const [putLanguageMutation] = usePutLanguageMutation();
  const [deleteLanguageMutation] = useDeleteLanguageMutation();
  const [createLanguageMutation] = useCreateLanguageMutation();
  const [languageData, setLanguageData] = useState({
    name: "",
    code: "",
    toEnglish: {},
    fromEnglish: {},
  });

  useEffect(() => {
    if (data) {
      setLanguageData(data);
    }
    if (isCreate) {
      setLanguageData({
        name: "",
        code: "",
        toEnglish: {
          expansionPercent: "",
          advancedBooking: "",
        },
        fromEnglish: {
          expansionPercent: "",
          advancedBooking: "",
        },
      });
    }
  }, [data, isCreate]);

  const handleSaveLanguage = async () => {
    try {
      if (isCreate) {
        createLanguageMutation({
          name: languageData.name,
          code: languageData.code,
          toEnglish: languageData.toEnglish ?? {},
          fromEnglish: languageData.fromEnglish ?? {},
        });
      } else {
        putLanguageMutation({
          id: languageData._id,
          name: languageData.name,
          code: languageData.code,
          toEnglish: languageData.toEnglish ?? {},
          fromEnglish: languageData.fromEnglish ?? {},
        });
      }
      await refetch();
      showSnackbar("Language saved successfully", "success");
    } catch (error) {
      showSnackbar("Error saving language", "error");
    }
    onClose();
  };

  const handleDeleteLanguage = () => {
    try {
      deleteLanguageMutation({
        id: languageData._id,
      });
      showSnackbar("Language deleted successfully!", "success");
    } catch (error) {
      showSnackbar("Error deleting language", "error");
    }
    refetch();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-client-dialog-title"
      aria-describedby="edit-client-dialog-description"
    >
      <DialogTitle id="edit-client-dialog-title">
        {isCreate ? "Create new language" : "Edit language"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="edit-client-dialog-description">
          {(isCreate ? "Create" : "Edit") +
            " " +
            "the language's name, code and rates."}
        </DialogContentText>
        <DialogContentText sx={{ p: "1rem", mt: "2rem" }}>
          <TextField
            label="Name"
            sx={{
              marginBottom: "1rem",
            }}
            fullWidth
            value={languageData.name ?? ""}
            onChange={(e) =>
              setLanguageData({
                ...languageData,
                name: e.target.value,
              })
            }
          />
          <TextField
            label="Code"
            sx={{
              marginBottom: "1rem",
            }}
            fullWidth
            value={languageData.code ?? ""}
            onChange={(e) =>
              setLanguageData({
                ...languageData,
                code: e.target.value,
              })
            }
          />
          {languageData?.code !== "en" && (
            <Box>
              <Typography variant="h6" sx={{ mt: "1rem" }}>
                To English
              </Typography>
              <TextField
                label="Expansion Percent"
                sx={{
                  marginTop: "1rem",
                }}
                type="number"
                fullWidth
                value={languageData?.toEnglish?.expansionPercent ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!isNaN(value)) {
                    setLanguageData({
                      ...languageData,
                      toEnglish: {
                        ...languageData.toEnglish,
                        expansionPercent: parseFloat(value),
                      },
                    });
                  }
                }}
              />
              <TextField
                label="Advanced Booking"
                sx={{
                  marginTop: "1rem",
                }}
                type="number"
                fullWidth
                value={languageData?.toEnglish?.advancedBooking ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!isNaN(value)) {
                    setLanguageData({
                      ...languageData,
                      toEnglish: {
                        ...languageData.toEnglish,
                        advancedBooking: parseFloat(value),
                      },
                    });
                  }
                }}
              />
              <Typography variant="h6" sx={{ mt: "2rem" }}>
                From English
              </Typography>
              <TextField
                label="Expansion Percent"
                sx={{
                  marginTop: "1rem",
                }}
                type="number"
                fullWidth
                value={languageData?.fromEnglish?.expansionPercent ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!isNaN(value)) {
                    setLanguageData({
                      ...languageData,
                      fromEnglish: {
                        ...languageData.fromEnglish,
                        expansionPercent: parseFloat(value),
                      },
                    });
                  }
                }}
              />
              <TextField
                label="Advanced Booking"
                sx={{
                  marginTop: "1rem",
                }}
                type="number"
                fullWidth
                value={languageData?.fromEnglish?.advancedBooking ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!isNaN(value)) {
                    setLanguageData({
                      ...languageData,
                      fromEnglish: {
                        ...languageData.fromEnglish,
                        advancedBooking: parseFloat(value),
                      },
                    });
                  }
                }}
              />
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ width: "100%", justifyContent: "space-between" }}>
        <Button
          onClick={handleDeleteLanguage}
          color="error"
          sx={{ marginLeft: 0 }}
        >
          Delete
        </Button>
        <div>
          <Button onClick={onClose} color="warning">
            Cancel
          </Button>
          <Button onClick={handleSaveLanguage} color="success">
            {isCreate ? "Create" : "Save"}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EditLanguageDialog;
